import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
function Slider({ comps }) {

    const [components, setComponents] = useState(comps)


    const [inc, setInc] = useState(false)
    const [incAnim, setIncAnim] = useState(false)

    const [dec, setDec] = useState(false)
    const [decAnim, setDecAnim] = useState(false)

    const [pos, setPos] = useState(0)

    const [dir, setDir] = useState(-1)

    const [dragStarted, setDragStarted] = useState(false)

    const [dsPos, setDsPos] = useState(-1)

    const ref = useRef(null);

    const pref = useRef(null);

    const [pwidth, setPwidth] = useState(2000);

    const [lock, setLock] = useState(false)

    useEffect(() => {

        if (inc) {
            setIncAnim(true)

        }

    }, [inc])

    useEffect(() => {

        if (dec) {
            setDecAnim(true)

        }

    }, [dec])


    const calcDirection = (e) => {

        if (e.clientX > dsPos) {
            setDir(1)
        }
        else if (e.clientX < dsPos) {
            setDir(0)
        }
    }


    const calcDirectionTouc = (e) => {


        if (e.touches[0].clientX > dsPos) {
            setDir(1)
        }
        else if (e.touches[0].clientX < dsPos) {
            setDir(0)
        }
    }

    useEffect(() => {
        setPwidth(pref.current.getBoundingClientRect().width);
    }, [pref]);



    const onMoveTouch = (e) => {


        calcDirectionTouc(e);

        if (dragStarted) {
            // add this line
            if (dir === 0 && -(pos) < ref.current.getBoundingClientRect().width - pref.current.getBoundingClientRect().width)
                setPos(pos - 40)
            else if (dir === 1 && pos != 0)
                setPos(pos + 40)
        }
    }


    const onMove = (e) => {




        if (dragStarted && !lock) {
            calcDirection(e);
            // add this line
            if (dir === 0 && -(pos) < ref.current.getBoundingClientRect().width - pref.current.getBoundingClientRect().width) {
                setPos(pos - pwidth)
                setLock(true)

            }
            else if (dir === 1 && pos != 0) {
                setPos(pos + pwidth)
                setLock(true)

            }

        }
    }

    return (
        <div style={{ width: "100%", position: "relative",border:"1px solid #ccc",borderRadius:"10px",overflow:"hidden" }}>
            <div draggable={false} ref={pref} style={{ width: "100%", display: "flex", height: "auto", overflow: "hidden", borderRadius: "0px", backgroundColor: "white" }}

                onMouseMove={(e) => {

                    onMove(e)

                }}

                onTouchMove={(e) => {
                    onMoveTouch(e)
                }}

            >
                <div ref={ref} style={{ height: "auto", transform: `translateX(${pos}px)`, transition: "1s", display: "flex", width: "auto", alignItems: "center", cursor: dragStarted ? "grabbing" : "default", userSelect: "none", justifyContent: "start", overflow: "visible" }} draggable={false} onMouseUp={() => setDragStarted(false)} onMouseDown={(e) => { setDsPos(e.clientX); setDragStarted(true); }} onTransitionEnd={(e) => { setDragStarted(false); setLock(false); setDir(-1) }} onTouchEnd={() => setDragStarted(false)} onTouchStart={(e) => { setDsPos(e.touches[0].clientX); setDragStarted(true); }}>
                    {
                        components != undefined &&
                        components.map((comp, index) => (
                            <div style={{ width: `${pwidth}px`, position: "relative", height: "auto", display: "inline-block", backgroundColor: "transparent", boxSizing: "border-box" }} draggable={false}>
                                <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {comp}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>

    );
}

export default Slider;
