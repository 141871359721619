
import React, { useState, useEffect, useContext } from 'react';


function ChangeThemeButton({ onClick }) {

    const [isEntered, setIsEntered] = useState(false)

    return (
        <div style={{ width: "auto", display: "flex", height: "auto", cursor: "pointer" , marginLeft:"20px"}} onClick={onClick}  onMouseEnter={() => setIsEntered(true)} onMouseLeave={() => setIsEntered(false)}>
            <div style={{border:"3px solid white",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius:"50px", overflow:"hidden",width: "50px", height: "50px", transform: isEntered ? "rotate(1800deg)" : "rotate(0deg)", transitionDuration: "0.5s" }}>
                <div style={{backgroundColor:"red",height:"25px",width:"100%",display:"flex"}}>
                    <div style={{backgroundColor:"red",height:"30px",width:"20px"}}></div>
                    <div style={{backgroundColor:"yellow",height:"30px",width:"20px"}}></div>
                    <div style={{backgroundColor:"#9CFF2E",height:"30px",width:"20px"}}></div>
                </div>
                <div style={{backgroundColor:"green",height:"25px",width:"100%",display:"flex"}}>
                    <div style={{backgroundColor:"blue",height:"30px",width:"30px"}}></div>
                    <div style={{backgroundColor:"#E0144C",height:"30px",width:"30px"}}></div>
                    <div style={{backgroundColor:"#FF8E00",height:"30px",width:"30px"}}></div>
                </div>
            </div>
        </div>
    );
}

export default ChangeThemeButton;
