import './index.css';
import { Routes, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useContext } from 'react';
import Alert from '../Pages/Alert';
import Avatar from '../Pages/Avatar';
import Badge from '../Pages/Badge';
import Select from '../Pages/Select';
import Checkbox from '../Pages/Checkbox';
import Switch from '../Pages/Switch';
import Card from '../Pages/Card';
import Tabs from '../Pages/Tabs';
import Tooltip from '../Pages/Tooltip';
import Chip from '../Pages/Chip';
import File from '../Pages/File';
import Spinner from '../Pages/Spinner';
import Button from '../Pages/Button';
import Drawer from '../Pages/Drawer';
import Calendar from '../Pages/Calendar';
import Like from '../Pages/Like';
import Input from '../Pages/Input';
import { ThemeContext, ThemeSwitchContext } from '../Components/ThemeProvider';
import ButtonComponent from '../Components/Button';
import DrawerComponent from '../Components/Drawer';
import NotificationComponent from '../Components/Notification';
import Currency from '../Pages/Currency';
import PersonCard from '../Pages/PersonCard';
import Statistic from '../Pages/Statistic';
import Notification from '../Pages/Notification';
import Reports from '../Pages/Reports';
import Progress from '../Pages/Progress';
import Option from '../Pages/Option';
import Pagination from '../Pages/Pagination';
import TextArea from '../Pages/TextArea';
import PaletteButton from '../Shared/PaletteButton';
import Header from '../Shared/Header';
import ChangeThemeButton from '../Shared/ChangeThemeButton';
import Table from '../Pages/Table';
import IconPage from '../Pages/Icon';
import Icon from '../Components/Icon';
import ModalPage from '../Pages/Modal';
import AvatarComponent from '../Components/Avatar'
import BadgeComponent from '../Components/Badge';
import LeftMenu from '../Shared/LeftMenu';
import Dashboard from '../Menu/Dashboard';
import Products from '../Menu/Products';
import Orders from '../Menu/Orders';
import Cancelled from '../Menu/Cancelled';
import Returnings from '../Menu/Returnings';
import Invoices from '../Menu/Invoices';
import Customers from '../Menu/Customers';
import Files from '../Menu/Files';
import ProductDetail from '../Menu/ProductDetail';

function Layout({ Logout }) {

    const theme = useContext(ThemeContext);
    const themeSwitch = useContext(ThemeSwitchContext);

    const [opened, setOpened] = useState(false);

    const [themeDrawer, setThemeDrawer] = useState(false)
    const [menuDrawer, setMenuDrawer] = useState(false)

    const [expandComponents, setExpandComponents] = useState(true)

    const [totalNotification, setTotalNotification] = useState(100)

    const [isMobile, setIsMobile] = useState(false);

    const [openAvatarMenu, setOpenAvatarMenu] = useState(false);

    const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false)

    const handleButtonClick = () => {
        setOpened(!opened)
    }


    const handleClick = () => {
        if (opened)
            setOpened(!opened)
    }

    useEffect(() => {
        handleResize();
    });
    const handleResize = () => {
        if (window.innerWidth <= 1300) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    }, []);



    useEffect(() => {

        setTimeout(() => { setTotalNotification(totalNotification + 1) }, 5000)

    }, [])


    return (
        <div style={{ height: "100vh", overflow: "hidden" }}>

            <div style={{ display: "flex", height: "100%" }}>


                <LeftMenu open={opened} onClose={setOpened} />

                <div id="content" style={{ width: "100%", overflow: "auto", height: "auto", backgroundColor: theme["light"] }}>
                    <div style={{ height: "90px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid " + theme["gray"], backgroundColor: theme["topMenu"] }}>

                        <button onClick={() => setOpened(true)} style={{ border: "none", backgroundColor: "transparent", visibility: isMobile ? "visible" : "hidden" }}><Icon name='vo-hamburger' type='outlined' color='primary' /></button>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>


                            <BadgeComponent position={"TOP_RIGHT"} color={"alert"} content={totalNotification}><ButtonComponent onClick={() => setOpenNotificationDrawer(!openNotificationDrawer)} style={{ width: "40px", height: "40px" }} color="secondary" type={"lined"}><Icon name='vo-chat' type='outlined' color='secondary' /></ButtonComponent></BadgeComponent>
                            <BadgeComponent position={"TOP_RIGHT"} color={"alert"} content={totalNotification}><ButtonComponent onClick={() => setOpenNotificationDrawer(!openNotificationDrawer)} style={{ width: "40px", height: "40px" }} color="secondary" type={"lined"}><Icon name={"vo-notification"} type="outlined" color={"secondary"} /></ButtonComponent></BadgeComponent>
                            <div style={{ position: "relative" }}>
                                <AvatarComponent color="secondary" active={true} onClick={() => { setOpenAvatarMenu(!openAvatarMenu) }} src="avatar2.PNG" />
                                <div onMouseLeave={() => setOpenAvatarMenu(false)} style={{ visibility: openAvatarMenu ? "visible" : "hidden", position: "absolute", width: "150px", height: "auto", borderRadius: "10px 10px 10px 10px", right: "10px", top: openAvatarMenu ? "50px" : "70px", transitionDuration: "0.4s", padding: "20px", zIndex: "1000", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", backgroundColor: theme["topMenu"] }}>
                                    <div>
                                        <Link style={{ textDecoration: "none", color: theme["primary"], margin: "5px", fontSize: "14px" }} to="#" ><Icon name='vo-user' type='outlined' color='primary' />Profile</Link>
                                    </div>
                                    <div>
                                        <Link style={{ textDecoration: "none", color: theme["primary"], margin: "5px", fontSize: "14px" }} to="/#" ><Icon name='vo-computers' type='outlined' color='primary' />Settings</Link>
                                    </div>
                                    <hr />
                                    <div>
                                        <Link style={{ textDecoration: "none", color: theme["primary"], margin: "5px", fontSize: "14px" }} to="/login" ><Icon name='vo-lock' type='outlined' color='primary' /> Logout</Link>
                                    </div>
                                </div>
                            </div>

                            <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: "1000" }}>
                                <ChangeThemeButton onClick={() => { setThemeDrawer(!themeDrawer) }} />
                            </div>
                            <DrawerComponent position={"RIGHT"} open={openNotificationDrawer} onClose={setOpenNotificationDrawer} color="light" width={350}>

                                <div style={{ padding: "10px" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <h1 style={{ fontSize: "22px", textAlign: "start", color: theme["text"], margin: "10px" }}>Notifications</h1>

                                        <Link style={{ textDecoration: "none", color: theme["primary"], margin: "5px", fontSize: "14px" }} to="#" ><Icon name='vo-signal' type='outlined' color='primary' />All</Link>
                                    </div>
                                    <div>
                                        <NotificationComponent header={'Software Requirements Document'} />
                                        <NotificationComponent header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'primary'} />
                                        <NotificationComponent header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'warning'} />
                                        <NotificationComponent header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'success'} />
                                        <NotificationComponent header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'alert'} />
                                        <NotificationComponent header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} />
                                        <NotificationComponent header={'Software Requirements Document'} />
                                        <NotificationComponent header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'primary'} />
                                        <NotificationComponent header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'warning'} />
                                        <NotificationComponent header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'success'} />
                                        <NotificationComponent header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'alert'} />
                                        <NotificationComponent header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} />

                                    </div>
                                </div>

                            </DrawerComponent>
                            <DrawerComponent position={"RIGHT"} open={themeDrawer} onClose={setThemeDrawer} color="light" width={400}>
                                <div style={{ padding: "20px" }}>
                                    <Header>Themes</Header>
                                    <div>
                                        <PaletteButton palette={{
                                            "primary": "#405189",
                                            "secondary": "#7d8bba",
                                            "dark": "#222",
                                            "success": "#26D96C",
                                            "warning": "#FFDB00",
                                            "alert": "#E20D0D",
                                            "info": "#68E6FB",
                                            "light": "#fff",
                                            "gray": "#ccc",
                                            "darkgray": "#aaa",
                                            "leftMenu": "#405189",
                                            "topMenu": "#7d8bba",
                                            "content": "#7d8bba"
                                        }} onClick={() => { themeSwitch(0) }} />
                                        <PaletteButton palette={{
                                            "primary": "#405189",
                                            "secondary": "#7d8bba",
                                            "dark": "#222",
                                            "success": "#26D96C",
                                            "warning": "#FFDB00",
                                            "alert": "#E20D0D",
                                            "info": "#68E6FB",
                                            "light": "#fff",
                                            "gray": "#ccc",
                                            "darkgray": "#aaa",
                                            "leftMenu": "#405189",
                                            "topMenu": "#fff",
                                            "content": "#f3f3f9"
                                        }} onClick={() => { themeSwitch(1) }} />
                                        <PaletteButton palette={{
                                            "primary": "#007FFF",
                                            "secondary": "#89CFF0",
                                            "dark": "#222",
                                            "success": "#26D96C",
                                            "warning": "#FFDB00",
                                            "alert": "#E20D0D",
                                            "info": "#68E6FB",
                                            "light": "#fff",
                                            "gray": "#ccc",
                                            "darkgray": "#aaa",
                                            "leftMenu": "#00308F",
                                            "topMenu": "#fff",
                                            "content": "#f3f3f9",

                                        }} onClick={() => { themeSwitch(2) }} />
                                        <PaletteButton palette={{
                                            "primary": "#007FFF",
                                            "secondary": "#7CB9E8",
                                            "dark": "#222",
                                            "success": "#26D96C",
                                            "warning": "#FFDB00",
                                            "alert": "#E20D0D",
                                            "info": "#68E6FB",
                                            "light": "#fff",
                                            "gray": "#ccc",
                                            "darkgray": "#aaa",
                                            "leftMenu": "#1c1c1c",
                                            "topMenu": "#2e2e2e",
                                            "content": "#3c3c3c",

                                        }} onClick={() => { themeSwitch(3) }} />

                                    </div>
                                </div>
                            </DrawerComponent>

                        </div>
                    </div>
                    <div style={{ padding: "20px", height: "auto", minHeight: "100vh", backgroundColor: theme["content"] }}>
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/products" element={<Products />} />
                            <Route path="/orders" element={<Orders />} />
                            <Route path="/returnings" element={<Returnings />} />
                            <Route path="/cancelled" element={<Cancelled />} />
                            <Route path="/invoices" element={<Invoices />} />
                            <Route path="/customers" element={<Customers />} />
                            <Route path="/product-detail" element={<ProductDetail />} />
                            <Route path="/files" element={<Files />} />
                            <Route path="/alert" element={<Alert />} />
                            <Route path="/avatar" element={<Avatar />} />
                            <Route path="/badge" element={<Badge />} />
                            <Route path="/button" element={<Button />} />
                            <Route path="/card" element={<Card />} />
                            <Route path="/checkbox" element={<Checkbox />} />
                            <Route path="/chip" element={<Chip />} />
                            <Route path="/file" element={<File />} />
                            <Route path="/select" element={<Select />} />
                            <Route path="/spinner" element={<Spinner />} />
                            <Route path="/switch" element={<Switch />} />
                            <Route path="/tooltip" element={<Tooltip />} />
                            <Route path="/drawer" element={<Drawer />} />
                            <Route path="/calendar" element={<Calendar />} />
                            <Route path="/like" element={<Like />} />
                            <Route path="/input" element={<Input />} />
                            <Route path="/currency" element={<Currency />} />
                            <Route path="/personCard" element={<PersonCard />} />
                            <Route path="/statistic" element={<Statistic />} />
                            <Route path="/notification" element={<Notification />} />
                            <Route path="/reports" element={<Reports />} />
                            <Route path="/progress" element={<Progress />} />
                            <Route path="/option" element={<Option />} />
                            <Route path="/tabs" element={<Tabs />} />
                            <Route path="/pagination" element={<Pagination />} />
                            <Route path="/textarea" element={<TextArea />} />
                            <Route path="/table" element={<Table />} />
                            <Route path="/icons" element={<IconPage />} />
                            <Route path="/modal" element={<ModalPage />} />
                            <Route path="*" element={<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "70vh" }}><div><h1 style={{ color: theme["primary"], fontSize: "100px" }}>Oooooops...</h1><p style={{ color: theme["dark"], fontSize: "40px", textAlign: "center" }}>Page not found.</p></div></div>} />
                        </Routes>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default Layout;