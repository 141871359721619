/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************
text ---> file type which can be pdf, excel, word, powerpoint

unit ---> string which is the filename

increasing ---> callback function which is called on click

size ---> sm || md || lg

style   --->    style :{
    
                    color:"red", backgroundColor:"black", ...
                    arrowColor:"green"
    }

                    
        

*/
import React, { useContext, useEffect, useState } from "react";
import { chipSizes } from "../Theme";
import { ThemeContext } from "../ThemeProvider";
import { sizeTypes, styleTypes, textTypes, unitTypes, increasingTypes, themeProviderCheck } from "../ErrorHandler";
function Currency({ text, unit, increasing, size, style }) {

    const theme = useContext(ThemeContext);


    const [localSize, setLocalSize] = useState(chipSizes["sm"]);

    useEffect(()=>{
        sizeTypes.check(size)
        styleTypes.check(style)
        textTypes.check(text)
        increasingTypes.check(increasing)
        themeProviderCheck.check(theme)
        unitTypes.check(unit)
      },[text, unit, increasing, size, style])


    const arrows = {
        "up": <svg width="25" height="35" viewBox="0 0 25 25" fill={style === undefined ? (theme["success"]) : (style.arrowColor)} xmlns="http://www.w3.org/2000/svg">
            <path d="M16.7916 2.38817C17.0138 2.36873 17.2332 2.44859 17.3909 2.6063L24.9203 10.1357C25.3108 10.5262 25.3108 11.1594 24.9203 11.5499C24.5298 11.9404 23.8966 11.9404 23.5061 11.5499L16.305 4.34878C15.9586 4.00244 15.8664 3.47625 16.0742 3.03275L16.1741 2.81965C16.2883 2.57602 16.5236 2.41162 16.7916 2.38817Z" fill={style === undefined ? (theme["success"]) : (style.arrowColor)} />
            <path d="M17.7539 3.06959C17.8644 3.44847 17.7596 3.85742 17.4805 4.13648L10.1549 11.4621C9.76434 11.8527 9.13118 11.8527 8.74065 11.4621C8.35013 11.0716 8.35013 10.4385 8.74065 10.0479L16.223 2.56559C16.4105 2.37806 16.6714 2.2831 16.9356 2.30622C17.3042 2.33847 17.6152 2.59369 17.7188 2.94891L17.7539 3.06959Z" fill={style === undefined ? (theme["success"]) : (style.arrowColor)} />
            <rect x="17.6274" y="3" width="26" height="2" rx="1" transform="rotate(90 17.6274 3)" fill={style === undefined ? (theme["success"]) : (style.arrowColor)} />
        </svg>
        ,
        "down": <svg width="25" height="35" viewBox="0 0 25 25" fill={style === undefined ? (theme["alert"]) : (style.arrowColor)} xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4632 29.6118C16.241 29.6313 16.0216 29.5514 15.8639 29.3937L8.33452 21.8643C7.944 21.4738 7.944 20.8406 8.33452 20.4501C8.72504 20.0596 9.35821 20.0596 9.74873 20.4501L16.9498 27.6512C17.2962 27.9976 17.3884 28.5238 17.1806 28.9673L17.0807 29.1804C16.9665 29.424 16.7313 29.5884 16.4632 29.6118Z" fill={style === undefined ? (theme["alert"]) : (style.arrowColor)} />
            <path d="M15.5009 28.9304C15.3904 28.5515 15.4952 28.1426 15.7743 27.8635L23.1 20.5379C23.4905 20.1473 24.1236 20.1473 24.5142 20.5379C24.9047 20.9284 24.9047 21.5615 24.5142 21.9521L17.0318 29.4344C16.8443 29.6219 16.5834 29.7169 16.3192 29.6938C15.9506 29.6615 15.6396 29.4063 15.5361 29.0511L15.5009 28.9304Z" fill={style === undefined ? (theme["alert"]) : (style.arrowColor)} />
            <rect x="15.6274" y="29" width="26" height="2" rx="1" transform="rotate(-90 15.6274 29)" fill={style === undefined ? (theme["alert"]) : (style.arrowColor)} />
        </svg>
        ,
    }

    useEffect(() => {

        if(size !== undefined)
            setLocalSize(chipSizes[size])

    }, [size])

    return (
        <div style={{ margin: '10px', cursor: "default", display: "inline-block" }}>
            <div style={{ borderRadius: '5px', width: localSize.width + "px", height: localSize.height + "px", alignItems: "center", display: 'flex', fontSize: localSize.fontSize + "px", backgroundColor: theme["topMenu"], color: theme["dark"], border: "1px solid " + theme["gray"], justifyContent: 'center', ...style }}>
                <p style={{ position: 'relative', margin: "0px" }}>{unit}</p>
                <p style={{ position: 'relative', margin: "0px", marginLeft: "1px" }}>{text}</p>
                <p style={{ position: 'relative', margin: "0px" }}> {arrows[increasing ? "up" : "down"]}</p>
            </div>
        </div>
    )
}
export default Currency;