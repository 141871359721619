
import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import SpinnerComponent from '../../Components/Spinner';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Spinner() {

    return(

      <Grid>
            <Header>
                Spinner
            </Header>
            <Description>
            Progress indicators, known as spinners, show the unspecified waiting time and the length of the process.
            </Description>
            <Subheader>
                Color
            </Subheader>
            <Description>
            You should use "color" props to apply the colors in your theme palette to the component.It can take "primary", "secondary", "success", "warning", "info", "alert", "dark", "light", "gray" and "darkgray" values.
            </Description>
            <Showroom>
              <SpinnerComponent color="primary" type="blink"></SpinnerComponent>
              <SpinnerComponent color="secondary" type="blink"></SpinnerComponent>
              <SpinnerComponent color="success" type="blink"></SpinnerComponent>
              <SpinnerComponent color="info" type="blink"></SpinnerComponent>
            </Showroom>
            <Coderoom>
              {
                "export default function Example(){\n"+
                "\treturn(\n"+
                "\t\t<Spinner color='primary' type='blink'/>\n"+
                "\t\t<Spinner color='secondary' type='blink'/>\n"+
                "\t\t<Spinner color='success' type='blink'/>\n"+
                "\t\t<Spinner color='info' type='blink'/>\n"+
                "\t)\n"+
                "}"
              }
            </Coderoom>
            <Subheader>
                Type
            </Subheader>
            <Description>
            You can modify the spinner images using the type prop.
            </Description>
            <Showroom>
            <SpinnerComponent color="secondary" type="blink"></SpinnerComponent>
            <SpinnerComponent color="secondary" type="bubble"></SpinnerComponent>
            <SpinnerComponent color="secondary" type="waos"></SpinnerComponent>
            <SpinnerComponent color="secondary" type="spinner"></SpinnerComponent>
            </Showroom>
            <Coderoom>
              {
                "export default function Example(){\n"+
                "\treturn(\n"+
                "\t\t<Spinner color='secondary' type='blink'/>\n"+
                "\t\t<Spinner color='secondary' type='bubble'/>\n"+
                "\t\t<Spinner color='secondary' type='waos'/>\n"+
                "\t\t<Spinner color='secondary' type='spinner'/>\n"+
                "\t)\n"+
                "}"
              }
            </Coderoom>
            <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                          name: "type",
                          type: "blink\nbubble\nwaos\nspinner", 
                          default: " ", 
                          description: "You can specify the type of your component" 
                        } ,
                        {
                          name:"color",
                          type:"primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray",
                          default:" ",
                          description:"You should use 'color' props to apply the colors in your theme palette to the component."
                        }
                      ]}  
                        
                    />
                </div>
            </div>
            
      </Grid>

    );
   
}

export default Spinner;
