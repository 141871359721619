import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Components/ThemeProvider";
import { Link } from "react-router-dom";
import Button from "../../Components/Button";
import Icon from "../../Components/Icon";
import Drawer from "../../Components/Drawer";

function LeftMenu({open, onClose}) {

    const theme = useContext(ThemeContext);

    const [isSmall, setIsSmall] = useState(false)
    const [pos, setPos] = useState(140)
    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        handleResize();
    });
    const handleResize = () => {
        if (window.innerWidth <= 1300) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    }, []);

    return (
        <div style={{ width: !isMobile ? isSmall ? "4%" : "15%" : "0%", borderRight: "1px solid " + theme["leftMenu"], overflow: "auto", backgroundColor: theme["leftMenu"] }}>
            <div>
                {
                    !isMobile &&
                    <div>
                        {
                            isSmall &&
                            <div style={{ position: "relative", height: "100vh" }}>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
                                    <button style={{ border: "none", backgroundColor: "transparent" }} onClick={() => setIsSmall(!isSmall)}><Icon name='vo-hamburger' type='outlined' color='light' /></button>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%", marginTop: "100px" }}>
                                    <Link to="/"><Icon name='vo-home' type='outlined' color='light' /></Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
                                    <Link to="/products"><Icon name='vo-chart' type='outlined' color='light' /></Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
                                    <Link to="/product-detail"><Icon name='vo-web-app' type='outlined' color='light' /></Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
                                    <Link to="/orders"><Icon name='vo-truck' type='outlined' color='light' /></Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
                                    <Link to="/returnings"><Icon name='vo-refresh' type='outlined' color='light' /></Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
                                    <Link to="/cancelled"><Icon name='vo-cross' type='outlined' color='light' /></Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
                                    <Link to="/customers"><Icon name='vo-users' type='outlined' color='light' /></Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
                                    <Link to="/files"><Icon name='vo-folder' type='outlined' color='light' /></Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
                                    <Link to="/invoices"><Icon name='vo-statistic' type='outlined' color='light' /></Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
                                    <button style={{ border: "none", backgroundColor: "transparent" }} onClick={() => setIsSmall(false)}><Icon name='vo-web-app' type='outlined' color='light' /></button>
                                </div>

                                <div style={{ position: "absolute", bottom: "10px", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}><img src="logo.png" style={{ width: "30px" }} /></div>
                            </div>
                        }
                        {
                            !isSmall &&
                            <div style={{ position: "relative", height: "100vh" }}>
                                <div style={{ width: "100%", position: "absolute", backgroundColor: theme["secondary"], height: "50px", top: (pos + "px"), left: "0px", opacity: "0.4", transitionDuration: "0.5s", borderRadius: "10px" }}></div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "end", width: "100%" }}>
                                    <button style={{ border: "none", backgroundColor: "transparent" }} onClick={() => setIsSmall(!isSmall)}><Icon name='vo-left-arrow' type='outlined' color='light' /></button>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%", marginTop: "100px" }}>
                                    <Link to="/" onClick={() => setPos(140)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-home' type='outlined' color='light' />Dashboard</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/products" onClick={() => setPos(190)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-chart' type='outlined' color='light' />Products</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/product-detail" onClick={() => setPos(240)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-web-app' type='outlined' color='light' />Product Detail</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/orders" onClick={() => setPos(290)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-truck' type='outlined' color='light' />Orders</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/returnings" onClick={() => setPos(340)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-refresh' type='outlined' color='light' />Returnings</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/cancelled" onClick={() => setPos(390)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-cross' type='outlined' color='light' />Cancelled</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/invoices" onClick={() => setPos(440)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-statistic' type='outlined' color='light' />Invoices</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/customers" onClick={() => setPos(490)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-users' type='outlined' color='light' />Customers</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/files" onClick={() => setPos(540)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-folder' type='outlined' color='light' />Files</Link>
                                </div>
                                <div>
                                    <span onClick={() => setPos(590)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px", cursor: "pointer" }}><Icon name='vo-web-app' type='outlined' color='light' />UI Elements</span>
                                    <div style={{ paddingLeft: "20px" }}>
                                        <div style={{ margin: "5px" }}>
                                            <Link to="/alert" style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }}>Alert</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/avatar">Avatar</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/badge">Badge</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/button">Button</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/card">Card</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/checkbox">Checkbox</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/chip">Chip</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/file">File</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/select">Select</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/spinner">Spinner</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/switch">Switch</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/tooltip">Tooltip</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/drawer">Drawer</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/calendar" >Calendar</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/like">Like</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/input" >Input</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/currency"  >Currency</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/personCard"  >Person Card</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/option" >Option</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/notification" >Notification</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/reports">Reports</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/progress" >Progress</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/statistic" >Statistic</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/tabs"  >Tabs</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/pagination" >Pagination</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/textarea" >TextArea</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/table"  >Table</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/icons"  >Icons</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/modal" >Modal</Link>
                                        </div>
                                    </div>
                                </div>



                                <div style={{ position: "absolute", top: "40px", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column" }}>
                                    <img src="logo.png" style={{ width: "40px" }} />
                                    <p style={{ color: theme["light"] }}>GDASH v.1.0</p>
                                </div>

                            </div>
                        }
                    </div>
                }
                {
                    isMobile && 

                    <div>
                        <Drawer position={"LEFT"} open={open} onClose={()=>onClose(false)} width={400} color ='primary'>
                            <div style={{ position: "relative", height: "100vh" }}>
                                <div style={{ width: "100%", position: "absolute", backgroundColor: theme["secondary"], height: "50px", top: (pos + "px"), left: "0px", opacity: "0.4", transitionDuration: "0.5s", borderRadius: "10px" }}></div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "end", width: "100%" }}>
                                    <button style={{ border: "none", backgroundColor: "transparent" }} onClick={() => setIsSmall(!isSmall)}><Icon name='vo-left-arrow' type='outlined' color='light' /></button>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%", marginTop: "100px" }}>
                                    <Link to="/" onClick={() => setPos(140)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-home' type='outlined' color='light' />Dashboard</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/products" onClick={() => setPos(190)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-chart' type='outlined' color='light' />Products</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/product-detail" onClick={() => setPos(240)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-web-app' type='outlined' color='light' />Product Detail</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/orders" onClick={() => setPos(290)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-truck' type='outlined' color='light' />Orders</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/returnings" onClick={() => setPos(340)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-refresh' type='outlined' color='light' />Returnings</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/cancelled" onClick={() => setPos(390)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-cross' type='outlined' color='light' />Cancelled</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/invoices" onClick={() => setPos(440)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-statistic' type='outlined' color='light' />Invoices</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/customers" onClick={() => setPos(490)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-users' type='outlined' color='light' />Customers</Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "end", justifyContent: "start", width: "100%" }}>
                                    <Link to="/files" onClick={() => setPos(540)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px" }}><Icon name='vo-folder' type='outlined' color='light' />Files</Link>
                                </div>
                                <div>
                                    <span onClick={() => setPos(590)} style={{ textDecoration: "none", color: theme["gray"], margin: "5px", cursor: "pointer" }}><Icon name='vo-web-app' type='outlined' color='light' />UI Elements</span>
                                    <div style={{ paddingLeft: "20px" }}>
                                        <div style={{ margin: "5px" }}>
                                            <Link to="/alert" style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }}>Alert</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/avatar">Avatar</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/badge">Badge</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/button">Button</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/card">Card</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/checkbox">Checkbox</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/chip">Chip</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/file">File</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/select">Select</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/spinner">Spinner</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/switch">Switch</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/tooltip">Tooltip</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/drawer">Drawer</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/calendar" >Calendar</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/like">Like</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/input" >Input</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/currency"  >Currency</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/personCard"  >Person Card</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/option" >Option</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/notification" >Notification</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/reports">Reports</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/progress" >Progress</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/statistic" >Statistic</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/tabs"  >Tabs</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/pagination" >Pagination</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/textarea" >TextArea</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/table"  >Table</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/icons"  >Icons</Link>
                                        </div>
                                        <div style={{ margin: "5px" }}>
                                            <Link style={{ textDecoration: "none", color: theme["gray"], margin: "5px", fontSize: "14px" }} to="/modal" >Modal</Link>
                                        </div>
                                        
                                    </div>
                                </div>



                                <div style={{ position: "absolute", top: "40px", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column" }}>
                                    <img src="logo.png" style={{ width: "40px" }} />
                                    <p style={{ color: theme["light"] }}>GDASH v.1.0</p>
                                </div>

                            </div>
                        </Drawer>
                    </div>
                }
            </div>
        </div>
    )
}
export default LeftMenu;