
import React, { useState, useEffect, useContext } from 'react';


function PaletteButton({ palette, onClick }) {


    return (
        <div style={{ width: "auto", justifyContent: "center", display: "flex", height: "auto", cursor: "pointer", border: "1px solid #ccc", padding: "5px", borderRadius: "5px", marginBottom: "10px" }} onClick={onClick}>
            {Object.keys(palette).map((key, index) => {
                if (index < Object.keys(palette).length - 3) {
                    return (
                        <div>
                            <div key={index} style={{ borderRadius: "10px", width: "30px", backgroundColor: palette[key], height: "30px", margin: "2px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                            </div>
                        </div>

                    );
                }
            })}
        </div>
    );
}

export default PaletteButton;
