import React, { useContext} from "react";
import { ThemeContext } from "../../Components/ThemeProvider";

function Header(props) {
    const theme = useContext(ThemeContext);


    return (
        <h1 style={{color:theme["dark"]}}>
            {props.children}
        </h1>
    )
}
export default Header;