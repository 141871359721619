import React, { useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import SelectComponent from "../../Components/Select";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Option from "../../Components/Option";
import Table from "../../Shared/Table";

function Select() {
  const [value, setValue] = useState(0);
  return (
    <Grid>
      <Header>Select</Header>
      <Description>
        Select components are used to collect user-supplined informationin lists
        of options. It can contain any optional value. It provides support such
        as drop-down menu, multiple selection.
      </Description>

      <Subheader>Children</Subheader>
      <Description>
        The option elements to populate the select with. Can be some "option"
        elements.
      </Description>
      <Showroom>
        <SelectComponent
          value={value}
          onChange={setValue}
          color="secondary"
          size="sm"
          label="Car Models"
        >
          <Option>Volvo</Option>
          <Option>Volkswagen</Option>
          <Option>Opel</Option>
          <Option>Mercedes Benz</Option>
          <Option>Audi</Option>
        </SelectComponent>
      </Showroom>
      <Coderoom>
        {"export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Select color='secondary' label='Car Models'>\n" +
          "\t\t\t<Option> Volvo </Option>\n" +
          "\t\t\t<Option> Volkswagen </Option>\n" +
          "\t\t\t<Option> Opel </Option>\n" +
          "\t\t\t<Option> Mercedes Benz </Option>\n" +
          "\t\t\t<Option> Audi </Option>\n" +
          "\t\t</Select>\n" +
          "\t)\n" +
          "}"}
      </Coderoom>

      <Subheader>Color</Subheader>
      <Description>
        You can add the colors in the theme palette to your select component.
      </Description>
      <Showroom>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SelectComponent
            value={value}
            onChange={setValue}
            color="secondary"
            size="sm"
            label="Car Model"
            style={{ marginRight: "20px" }}
          >
            <Option>Volvo</Option>
            <Option>Volkswagen</Option>
            <Option>Opel</Option>
            <Option>Mercedes Benz</Option>
            <Option>Volvo</Option>
          </SelectComponent>
          <SelectComponent
            value={value}
            onChange={setValue}
            color="primary"
            size="sm"
            label="Car Model"
          >
            <Option>Volvo</Option>
            <Option>Volkswagen</Option>
            <Option>Opel</Option>
            <Option>Mercedes Benz</Option>
            <Option>Volvo</Option>
          </SelectComponent>
        </div>
      </Showroom>
      <Coderoom>
        {"export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Select color='secondary' label='Car Models'>\n" +
          "\t\t\t<Option> Volvo </Option>\n" +
          "\t\t\t<Option> Volkswagen </Option>\n" +
          "\t\t\t<Option> Opel </Option>\n" +
          "\t\t\t<Option> Mercedes Benz </Option>\n" +
          "\t\t\t<Option> Audi </Option>\n" +
          "\t\t</Select>\n" +
          "\t\t<Select color='primary' label='Car Models'>\n" +
          "\t\t\t<Option> Volvo </Option>\n" +
          "\t\t\t<Option> Volkswagen </Option>\n" +
          "\t\t\t<Option> Opel </Option>\n" +
          "\t\t\t<Option> Mercedes Benz </Option>\n" +
          "\t\t\t<Option> Audi </Option>\n" +
          "\t\t</Select>\n" +
          "\t)\n" +
          "}"}
      </Coderoom>

      <Subheader>Size</Subheader>
      <Description>
        You can size select components. It can take "sm", "md" and "lg" values.
      </Description>
      <Showroom>
        <SelectComponent
          color="secondary"
          size="sm"
          label="Select"
          style={{ marginBottom: "20px" }}
        ></SelectComponent>
        <SelectComponent
          color="secondary"
          size="md"
          label="Select"
          style={{ marginBottom: "20px" }}
        ></SelectComponent>
        <SelectComponent
          color="secondary"
          size="lg"
          label="Select"
        ></SelectComponent>
      </Showroom>
      <Coderoom>
        {"export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Select color='secondary' size='sm'/>\n" +
          "\t\t<Select color='secondary' size='md'/>\n" +
          "\t\t<Select color='secondary' size='lg'/>\n" +
          "\t)\n" +
          "}"}
      </Coderoom>

      <Subheader>Disabled</Subheader>
      <Description>
        It can take values "true" or "false". when it is "true", it turns of the
        click option.
      </Description>
      <Showroom>
        <SelectComponent
          color="secondary"
          size="sm"
          label="Age"
          disabled={true}
          value={value}
          style={{ marginBottom: "20px" }}
        >
          <Option>10</Option>
          <Option>20</Option>
          <Option>30</Option>
          <Option>40</Option>
          <Option>50</Option>
        </SelectComponent>
        <SelectComponent
          color="secondary"
          size="sm"
          label="Age"
          disabled={false}
          value={value}
        >
          <Option>10</Option>
          <Option>20</Option>
          <Option>30</Option>
          <Option>40</Option>
          <Option>50</Option>
        </SelectComponent>
      </Showroom>
      <Coderoom>
        {"export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Select color='secondary' size='sm' label='Age' disabled={true} value={value}>\n" +
          "\t\t\t<Option>10</Option>\n" +
          "\t\t\t<Option>20</Option>\n" +
          "\t\t\t<Option>30</Option>\n" +
          "\t\t\t<Option>40</Option>\n" +
          "\t\t\t<Option>50</Option>\n" +
          "\t\t</Select>\n" +
          "\t\t<Select color='secondary' size='sm' label='Age' disabled={false} value={value}>\n" +
          "\t\t\t<Option>10</Option>\n" +
          "\t\t\t<Option>20</Option>\n" +
          "\t\t\t<Option>30</Option>\n" +
          "\t\t\t<Option>40</Option>\n" +
          "\t\t\t<Option>50</Option>\n" +
          "\t\t</Select>\n" +
          "\t)\n" +
          "}"}
      </Coderoom>

      <Subheader>Label</Subheader>
      <Description>You can give a name to the select component.</Description>
      <Showroom>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SelectComponent
            color="secondary"
            size="sm"
            label="Age"
            value={value}
            style={{ marginRight: "20px" }}
          >
            <Option>10</Option>
            <Option>20</Option>
            <Option>30</Option>
            <Option>40</Option>
            <Option>50</Option>
          </SelectComponent>

          <SelectComponent
            color="secondary"
            size="sm"
            label="Number"
            value={value}
          >
            <Option>10</Option>
            <Option>20</Option>
            <Option>30</Option>
            <Option>40</Option>
            <Option>50</Option>
          </SelectComponent>
        </div>
      </Showroom>
      <Coderoom>
        {"export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Select color='secondary' size='sm' label='Age' >\n" +
          "\t\t\t<Option>10</Option>\n" +
          "\t\t\t<Option>20</Option>\n" +
          "\t\t\t<Option>30</Option>\n" +
          "\t\t\t<Option>40</Option>\n" +
          "\t\t\t<Option>50</Option>\n" +
          "\t\t</Select>\n" +
          "\t\t<Select color='secondary' size='sm' label='Number' >\n" +
          "\t\t\t<Option>10</Option>\n" +
          "\t\t\t<Option>20</Option>\n" +
          "\t\t\t<Option>30</Option>\n" +
          "\t\t\t<Option>40</Option>\n" +
          "\t\t\t<Option>50</Option>\n" +
          "\t\t</Select>\n" +
          "\t)\n" +
          "}"}
      </Coderoom>

      <Subheader>Value</Subheader>
      <Description>
        This state represents the value selected by the user, for instance when
        pressing
      </Description>
      <Showroom>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SelectComponent
            color="secondary"
            size="sm"
            label="Number"
            value={value}
            style={{ marginRight: "20px" }}
          >
            <Option>10</Option>
            <Option>20</Option>
            <Option>30</Option>
            <Option>40</Option>
            <Option>50</Option>
          </SelectComponent>
          <SelectComponent
            color="secondary"
            size="sm"
            label="Lessons"
            value={value}
          >
            <Option>Calculus</Option>
            <Option>Literature</Option>
            <Option>Physics</Option>
            <Option>Music</Option>
            <Option>Art</Option>
          </SelectComponent>
        </div>
      </Showroom>
      <Coderoom>
        {"export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Select color='secondary' size='sm' label='Number' value={value}>\n" +
          "\t\t\t<Option>10</Option>\n" +
          "\t\t\t<Option>20</Option>\n" +
          "\t\t\t<Option>30</Option>\n" +
          "\t\t\t<Option>40</Option>\n" +
          "\t\t\t<Option>50</Option>\n" +
          "\t\t</Select>\n" +
          "\t\t<Select color='secondary' size='sm' label='Lessons' value={value}>\n" +
          "\t\t\t<Option>Calculus</Option>\n" +
          "\t\t\t<Option>Literature</Option>\n" +
          "\t\t\t<Option>Physics</Option>\n" +
          "\t\t\t<Option>Music</Option>\n" +
          "\t\t\t<Option>Art</Option>\n" +
          "\t\t</Select>\n" +
          "\t)\n" +
          "}"}
      </Coderoom>
      <Subheader>Props</Subheader>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ width: "100%" }}>
          <Table
            columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
            rows={[
              {
                name: "color",
                type:
                  "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n",
                default: "",
                description:
                  "You should use 'color' props to apply the colors in your theme palette to the component.",
              },
              {
                name: "size",
                type: "sm \n md \n lg",
                default: "sm",
                description: "You can size select components.",
              },
              {
                name: "disabled",
                type: "{true}\n{false}",
                default: "{false}",
                description: "when it is 'true', it turns of the click option.",
              },
              {
                name: "value",
                type: "'string'",
                default: "undefined",
                description:
                  "Allows you to see the value clicked by the user in the input.",
              },
              {
                name: "label",
                type: "string",
                default: "undefined",
                description: "You can give a name to the select component.",
              },
              {
                name: "children",
                type: "options",
                default: "",
                description: "Adds options to populate the Select element.",
              },
            ]}
          />
        </div>
      </div>
    </Grid>
  );
}

export default Select;
