import React, { useContext} from "react";
import { ThemeContext } from "../../Components/ThemeProvider";

function Subheader(props) {

    const theme = useContext(ThemeContext);

    return (
        <h2 style={{color:theme["darkgray"], marginTop:"100px"}}>
            {props.children}
        </h2>
    )
}
export default Subheader;