import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import FileComponent from '../../Components/File';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function File() {

    return(
        <Grid>
            <Header>
                File
            </Header>
            <Description>

            </Description>
            <Subheader>
                Type
            </Subheader>
            <Description>
            You can specify your file types. it can take the values 'pdf', 'excel', 'word' and 'powerpoint'
            </Description>
            <Showroom>
            <div style={{display:"flex",alignItems:"center"}}>
                <FileComponent type="pdf" ></FileComponent>
                <FileComponent type="excel" ></FileComponent>
                
            </div>
            <div style={{display:"flex",alignItems:"center"}}>
                <FileComponent type="word"></FileComponent>
                <FileComponent type="powerpoint"></FileComponent>
            </div>
            </Showroom>
            
            <Coderoom>
            {
                "export default function Example(){\n"+
                "\treturn(\n"+
                "\t\t<File type='pdf' />\n"+
                "\t\t<File type='excel' />\n"+
                "\t\t<File type='word' />\n"+
                "\t\t<File type='powerpoint' />\n"+
                "\t)\n"+
                "}"
            }
            </Coderoom>
            <Subheader>
                Name
            </Subheader>
            <Description>
            You can name your files.
            </Description>
            <Showroom>
            <div style={{display:"flex",alignItems:"center"}}>
                <FileComponent type="pdf" name="EXAMPLE-PDF.pdf" ></FileComponent>
                <FileComponent type="excel" name="EXAMPLE-EXCEL.xls"></FileComponent>
                
            </div>
            <div style={{display:"flex",alignItems:"center"}}>
                <FileComponent type="word" name="EXAMPLE-WORD.word"></FileComponent>
                <FileComponent type="powerpoint" name="EXAMPLE-POWERPOINT.ppt"></FileComponent>
            </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<File type='pdf' name='EXAMPLE-PDF.pdf' />\n"+
                    "\t\t<File type='excel' name='EXAMPLE-EXCEL.xls' />\n"+
                    "\t\t<File type='word' name='EXAMPLE-WORD.word' />\n"+
                    "\t\t<File type='powerpoint' name='EXAMPLE-POWERPOINT.ppt' />\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                onClick
            </Subheader>
            <Description>
            Callback function which is called on click. Please click on the files.
            </Description>
            <Showroom>
            <div style={{display:"flex",alignItems:"center"}}>
                <FileComponent type="pdf" name="EXAMPLE-PDF.pdf" onClick={()=>{alert('Hello')}} ></FileComponent>
                <FileComponent type="excel" name="EXAMPLE-EXCEL.xls" onClick={()=>{alert('Hello')}}></FileComponent>
                
            </div>
            <div style={{display:"flex",alignItems:"center"}}>
                <FileComponent type="word" name="EXAMPLE-WORD.word" onClick={()=>{alert('Hello')}}></FileComponent>
                <FileComponent type="powerpoint" name="EXAMPLE-POWERPOINT.ppt" onClick={()=>{alert('Hello')}}></FileComponent>
            </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<File type='pdf' name='EXAMPLE-PDF.pdf' onClick={()=>{alert('Hello')}} />\n"+
                    "\t\t<File type='excel' name='EXAMPLE-EXCEL.xls' onClick=>{()={alert('Hello')}} />\n"+
                    "\t\t<File type='word' name='EXAMPLE-WORD.word' onClick=>{()={alert('Hello')}} />\n"+
                    "\t\t<File type='powerpoint' name='EXAMPLE-POWERPOINT.ppt' onClick={()=>{alert('Hello')}} />\n"+
                    "\t)\n"+
                    "}"
                }    
            </Coderoom>
            <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                          name: "type",
                          type: "pdf\nexcel\nword\npowerpoint", 
                          default: "undefined", 
                          description: "You can specify the type of your component" 
                        } ,
                        {
                          name:"name",
                          type:"string",
                          default:"undefined",
                          description:"You can give your component names that describe it."
                        },
                        {
                          name:"onClick",
                          type:"function",
                          default:"undefined",
                          description:"With the 'onClick' option, you can determine how it will react after the click."
                        }
                      ]}  
                        
                    />
                </div>
            </div>
            

        </Grid>
    )



}
export default File;