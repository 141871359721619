import React, { useEffect, useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import ButtonComponent from '../../Components/Button';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";



function Button(){

    const [checked, setChecked]=useState('')

    return(
        <Grid>
            <Header>
                Button
            </Header>
            <Description>
            Buttons allow users to click actions or make selections. Reports the actions that users can perform.
            </Description> 
            <Subheader>
                Type
            </Subheader>
            <Description>
                Buttons are used in three types. "filled", "lined" and "text" are buttons.
                <h5>Text Button</h5>
                Often used to express less specific actions in dialog boxes. It helps to emphasize the card content.
                <h5>Lined Button</h5>
                The middle remaining part of the text are buttons with a hover frame. It is used for situations that are important in practice, but are not the primary action. The lined button has lower priority than the filled button. It has higher priority than the text button.
                <h5>Filled Button</h5>
                They are high-hover buttons.It is used for situations that are of primary priority in applications.           
            </Description>
            <Showroom>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <ButtonComponent type="lined" color="secondary" size="sm" style={{marginRight:"50px"}}>Lined</ButtonComponent>
                <ButtonComponent type="text" color="secondary" size="sm" style={{marginRight:"50px"}} >Text</ButtonComponent>
                <ButtonComponent type="filled" color="secondary" size="sm" >Filled</ButtonComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Button color='secondary' type='text' > Text </Button>\n"+
                    "\t\t<Button color='secondary' type='lined'> Lined </Button>\n"+
                    "\t\t<Button color='secondary' type='filled'> Filled </Button>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                Color
            </Subheader>
            <Description>
            You can use the color prop to change the color of the button component. It can take "primary", "secondary", "success", "warning", "info", "alert", "dark", "light", "gray" and "darkgray" values.
            </Description>
            <Showroom>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"10px"}}> 
                <ButtonComponent type="lined" color="dark" size="sm" style={{marginRight:"50px"}}>Lined</ButtonComponent>
                <ButtonComponent type="text" color="primary" size="sm" style={{marginRight:"50px"}}>Text</ButtonComponent>
                <ButtonComponent type="filled" color="success" size="sm">Filled</ButtonComponent>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}> 
                <ButtonComponent type="lined" color="secondary" size="sm" style={{marginRight:"50px"}}>Lined</ButtonComponent>
                <ButtonComponent type="text" color="info" size="sm" style={{marginRight:"50px"}}>Text</ButtonComponent>
                <ButtonComponent type="filled" color="alert" size="sm" >Filled</ButtonComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Button color='dark' type='text'> Text </Button>\n"+
                    "\t\t<Button color='primary' type='lined'> Lined </Button>\n"+
                    "\t\t<Button color='success' type='filled'> Filled </Button>\n"+
                    "\t\t<Button color='secondary' type='text'> Text </Button>\n"+
                    "\t\t<Button color='info' type='lined'> Lined </Button>\n"+
                    "\t\t<Button color='alert' type='filled'> Filled </Button>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                Size
            </Subheader>
            <Description>
            If you want to give size to the buttons, you can use the props. It can take the values "sm", "md" and "lg".
            </Description>
            <Showroom>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"10px"}}>
                <ButtonComponent type="lined" color="secondary" size="sm" style={{marginRight:"50px"}} >Lined</ButtonComponent>
                <ButtonComponent type="text" color="secondary" size="sm" style={{marginRight:"50px"}} >Text</ButtonComponent>
                <ButtonComponent type="filled" color="secondary" size="sm" >Filled</ButtonComponent>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"10px"}}>
                <ButtonComponent type="lined" color="secondary" size="md" style={{marginRight:"50px"}} >Lined</ButtonComponent>
                <ButtonComponent type="text" color="secondary" size="md" style={{marginRight:"50px"}} >Text</ButtonComponent>
                <ButtonComponent type="filled" color="secondary" size="md" >Filled</ButtonComponent>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"10px"}}>
                <ButtonComponent type="lined" color="secondary" size="lg" style={{marginRight:"50px"}} >Lined</ButtonComponent>
                <ButtonComponent type="text" color="secondary" size="lg" style={{marginRight:"50px"}} >Text</ButtonComponent>
                <ButtonComponent type="filled" color="secondary" size="lg" >Filled</ButtonComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Button color='secondary' type='text' size='sm'> Text </Button>\n"+
                    "\t\t<Button color='secondary' type='lined' size='sm'> Lined </Button>\n"+
                    "\t\t<Button color='secondary' type='filled' size='sm'> Filled </Button>\n"+
                    "\t\t<Button color='secondary' type='text' size='md'> Text </Button>\n"+
                    "\t\t<Button color='secondary' type='lined' size='md'> Lined </Button>\n"+
                    "\t\t<Button color='secondary' type='filled' size='md'> Filled </Button>\n"+
                    "\t\t<Button color='secondary' type='text' size='lg'> Text </Button>\n"+
                    "\t\t<Button color='secondary' type='lined' size='lg'> Lined </Button>\n"+
                    "\t\t<Button color='secondary' type='filled' size='lg'> Filled </Button>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                onClick
            </Subheader>
            <Description>
            You can determine how the button will react during the click process with onClick props.
            </Description>
            <Showroom>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <ButtonComponent type="filled" color="secondary" size="sm" onClick={()=>{alert('Hello')}} style={{marginRight:"50px"}} >Filled</ButtonComponent>
                <ButtonComponent type="text" color="secondary" size="sm" onClick={()=>{alert('Hello')}} style={{marginRight:"50px"}} >Text</ButtonComponent>
                <ButtonComponent type="lined" color="secondary" size="sm" onClick={()=>{alert('Hello')}} >Lined</ButtonComponent>
                
            </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Button color='secondary' type='filled' onClick={()=>{alert('Hello')}}> Filled </Button>\n"+
                    "\t\t<Button color='secondary' type='text' onClick={()=>{alert('Hello')}}> Text </Button>\n"+
                    "\t\t<Button color='secondary' type='lined'onClick={()=>{alert('Hello')}}> Lined </Button>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                Disabled
            </Subheader>
            <Description>
                If disabled is true, it disables the click action.
            </Description>
            <Showroom>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <ButtonComponent type="filled" color="secondary" size="sm" onClick={()=>{alert('Hello')}} disabled={false} style={{marginRight:"50px"}}>Filled</ButtonComponent>
                <ButtonComponent type="filled" color="secondary" size="sm" onClick={()=>{alert('Hello')}} disabled={true}>Filled</ButtonComponent>
            </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Button color='secondary' type='filled' onClick={()=>{alert('Hello')}} disabled={false}> Filled </Button>\n"+
                    "\t\t<Button color='secondary' type='filled' onClick={()=>{alert('Hello')}} disabled={true}> Filled </Button>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>Props</Subheader>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                          name: "color",
                          type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                          default: "gray", 
                          description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                        },
                        {
                          name:"disabled",
                          type:"true\nfalse",
                          default:"false",
                          description:"You can turn off the button click feature."
                        },
                        {
                          name:"onClick",
                          type:"Function",
                          default:"undefined",
                          description:"With the 'onClick' option, you can determine how it will react after the click."
                        },
                        {
                            name:"size",
                            type:"sm \n md \n lg",
                            default:"sm",
                            description:"You can specify the size of the buttons with the 'size' props"
                        },
                        {
                          name:"type",
                          type:"filled\nlined\ntext",
                          default:"undefined",
                          description:"You should use it to determine the types of buttons."
                        }
                      ]}  
                        
                    />
                </div>
            </div>


        </Grid>
    );


}
export default Button;