import React, { useEffect, useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import BadgeComponent from '../../Components/Badge'
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Badge() {
    const [content,setContent] = useState(0)
    useEffect(()=>{
        setTimeout(()=>{
            setContent("100+")
        },3000)
    },[])

    return (
        <Grid>
            <Header>
                Badge
            </Header>
            <Description>
               It is used to attract attention in informational messages. It can take the "color", "content", "showContent", "open" and "position" props.
            </Description>
            <Showroom>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "100%" }}>
                    <BadgeComponent content={"100+"} position="TOP_LEFT" color="secondary"  >
                        Message
                    </BadgeComponent>
                    <BadgeComponent content={"100+"} position="TOP_LEFT" color="secondary" >
                        Mail
                    </BadgeComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='secondary'> Message </Badge>\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='secondary'> Mail </Badge>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                Color
            </Subheader>
            <Description>
                You should use props to add the colors in the theme palette to the component.
            </Description>
            <Showroom>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "100%" }}>
                <BadgeComponent content={"100+"} position="TOP_LEFT" color="info" >
                        Mail
                </BadgeComponent>
                <BadgeComponent content={"100+"} position="TOP_LEFT" color="secondary" >
                        Mail
                </BadgeComponent>
                <BadgeComponent content={"100+"} position="TOP_LEFT" color="alert" >
                        Mail
                </BadgeComponent>
                <BadgeComponent content={"100+"} position="TOP_LEFT" color="success" >
                        Mail
                </BadgeComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='info'> Mail </Badge>\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='secondary'> Mail </Badge>\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='alert'> Mail </Badge>\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='success'> Mail </Badge>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                Positions
            </Subheader>
            <Description>
                You can decide which direction which direction your badge should stand in the message. It can take the value "TOP_LEFT", "TOP_RIGHT", "BOTTOM_LEFT" and "BOTTOM_RIGHT".
            </Description>
            <Showroom>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "100%" }}>
                    <BadgeComponent content={"100+"} position="TOP_LEFT" color="secondary" >
                        Mail
                    </BadgeComponent>

                    <BadgeComponent content={"100+"} position="TOP_RIGHT" color="secondary" >
                        Mail
                    </BadgeComponent>

                    <BadgeComponent content={"100+"} position="BOTTOM_LEFT" color="secondary" >
                        Mail
                    </BadgeComponent>

                    <BadgeComponent content={content} position="BOTTOM_RIGHT" color="secondary" >
                        Mail
                    </BadgeComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='secondary'> Mail </Badge>\n"+
                    "\t\t<Badge content={'100+'} position='TOP_RIGHT' color='secondary'> Mail </Badge>\n"+
                    "\t\t<Badge content={'100+'} position='BOTTOM_LEFT' color='secondary'> Mail </Badge>\n"+
                    "\t\t<Badge content={'100+'} position='BOTTOM_RIGHT' color='secondary'> Mail </Badge>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                Content
            </Subheader>
            <Description>
                Shows the value you typed in it as a badge.
            </Description>
            <Showroom>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "100%" }}>
                    <BadgeComponent content={"100+"} position="TOP_LEFT" color="secondary" >
                        Mail
                    </BadgeComponent>
                    <BadgeComponent content={"1+"} position="TOP_LEFT" color="secondary" >
                        Mail
                    </BadgeComponent>
                    <BadgeComponent content={"99+"} position="TOP_LEFT" color="secondary" >
                        Mail
                    </BadgeComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='secondary'> Mail </Badge>\n"+
                    "\t\t<Badge content={'1+'} position='TOP_LEFT' color='secondary'> Mail </Badge>\n"+
                    "\t\t<Badge content={'99+'} position='TOP_LEFT' color='secondary'> Mail </Badge>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                showContent
            </Subheader>
            <Description>
                When "false", it turns, into a dotted warning without specifying a number. "true" in default state.
            </Description>
            <Showroom>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "100%" }}>
                    <BadgeComponent content={"100+"} position="TOP_LEFT" color="secondary" showContent={true}>
                        Mail
                    </BadgeComponent>
                    <BadgeComponent content={"99+"} position="TOP_LEFT" color="secondary" showContent={false}>
                        Mail
                    </BadgeComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='secondary' showContent={true}> Mail </Badge>\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='secondary' showContent={false}> Mail </Badge>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                Open
            </Subheader>
            <Description>
                When "true" is set, the badge is available. "true" in default state. 
            </Description>
            <Showroom>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "100%" }}>
                    <BadgeComponent content={"100+"} position="TOP_LEFT" color="secondary" open={true}>
                        Mail
                    </BadgeComponent>
                    <BadgeComponent content={"100+"} position="TOP_LEFT" color="secondary" open={false}>
                        Mail
                    </BadgeComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='secondary' open={true}> Mail </Badge>\n"+
                    "\t\t<Badge content={'100+'} position='TOP_LEFT' color='secondary' open={false}> Mail </Badge>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>Props</Subheader>


            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                            default: "gray", 
                            description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                          },
                          {
                            name: "content",
                            type: "'string'", 
                            default: "undefined", 
                            description: "Shows the value you typed in it as a badge" 
                          },
                          {
                            name: "showContent",
                            type: "{true}\n{false}", 
                            default: "{true}", 
                            description: "When {false}, it turns, into a dotted warning without specifying a number." 
                          },
                          {
                            name: "open",
                            type: "{true}\n{false}", 
                            default: "{true}", 
                            description: "When {true} is set, the badge is available." 
                          },
                          {
                            name: "position",
                            type: "TOP_LEFT\nTOP_RIGHT\nBOTTOM_LEFT\nBOTTOM_RIGHT", 
                            default: "TOP_RIGHT ", 
                            description: "You can decide which direction your badge should stand in the message." 
                          }

                      ]}  
                        
                    />
                </div>
            </div>


        </Grid>
        

    )
}
export default Badge;