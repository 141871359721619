
import { activeStatements, childrenStatements, colorStatements, iconTypeStatements, statistic2CurrencyStatements, fontSizeStatements, fileTypeStatements, optionHeightStatements, totalStatements, xsStatements, smStatements, mdStatements, lgStatements, xlStatements, rowspanStatements, colspanStatements, paginationSizeStatements, paginationPositionStatements, paginationTypesStatements, pageStatements, shapeStatements, sizeStatements, srcStatements, styleStatements, functionStatements, openStatements, disabledStatements, shadowStatements, contentStatements, checkboxTooltipPositionStatements, badgePositionStatements, drawerPositionStatements, dateValueStatements, stringValueStatements, integerValueStatements, animationStatements, showContentStatements, buttonTypeStatements, inputTypeStatements, spinnerTypeStatements, switchTypeStatements, onChangeStatements, cardHeaderStatements, otherHeaderStatements, actionsStatements, placeHolderStatements, textStatements, onCloseStatements, heightStatements, itemsStatements, checkedStatements, unitStatements, widthStatements, dataStatements, directionStatements, chartLabelsStatements, labelStatements, nameStatements, increaseStatements, subjectStatements, logoStatements, detailStatements, dateStatements, maxValueStatements, onFinishedStatements, amountStatements, increasingStatements, currencyStatements, themeProviderStatements, themeProviderWarningType, themeProviderPalletsStatements} from "./Statements";
import { warningsOn } from "./ThemeProvider";

export var colorTypes = {
    type: "String",
    data: {"primary":"1", "secondary":"1", "success":"1", "info":"1", "warning":"1", "alert":"1", "dark":"1", "darkgray":"1", "gray":"1", "light":"1"},
    check: (color) => {

        if (color === undefined) {
            if(warningsOn)
                console.warn(colorStatements.warning)
        }
        else {
            if(colorTypes.data[color]===undefined){
                throw new Error(colorStatements.error + " but given '" + color + "'")
            }
        }

    }
}

export var childrenTypes = {
    type: "React Component or Html Tag",
    check: (children) => {

        if (children === "" || children === undefined || children === null) {
            if(warningsOn)
                console.warn(childrenStatements.warning)
        }

    }
}

export var sizeTypes = {
    type: "String",
    data: {"sm":"1", "md":"1", "lg":"1"},
    check: (size) => {

        if (size === undefined) {
            if(warningsOn)
                console.warn(colorStatements.warning)
        }
        else {
            if(sizeTypes.data[size]===undefined){
                throw new Error(sizeStatements.error + " but given '" + size + "'")
            }
        }

    }
}


export var srcTypes = {
    type: "String",
    data: {"jpg":"1", "JPG":"1", "png":"1", "PNG":"1"},
    check: (src) => {

        if (src === undefined) {
            if(warningsOn)
                console.warn(srcStatements.warning)
        }
        else {
            var array = src.split(".");

            var ext = array[array.length-1];

            if(srcTypes.data[ext]===undefined){
                throw new Error(srcStatements.error + " but given '" + ext + "'")
            }
        }

    }
}



export var styleTypes = {
    type: "String",
    check: (style) => {

        if (style === undefined) {
            if(warningsOn)
                console.warn(styleStatements.warning)
        }
        else {

            if(typeof(style) !== "object"){

                throw new Error(styleStatements.error + " but given '" + typeof(style) + "'")
            }
        }

    }
}


export var shapeTypes = {
    type: "String",
    data: {"square":"1", "circle":"1"},
    check: (shape) => {

        if (shape === undefined) {
            if(warningsOn)
                console.warn(shapeStatements.warning)
        }
        else {

            if(shapeTypes.data[shape]===undefined){
                throw new Error(shapeStatements.error + " but given '" + shape + "'")
            }
        }

    }
}


export var activeTypes = {
    type: "Boolean",
    check: (active) => {

        if (active === undefined) {
            if(warningsOn)
                console.warn(activeStatements.warning)
        }
        else {

            if(typeof(active) !== "boolean"){
                throw new Error(activeStatements.error + " but given '" + active + "'")
            }
        }

    }
}


export var functionTypes = {
    type: "Function",
    check: (onClick) => {

        if (onClick === undefined) {
            if(warningsOn)
                console.warn(functionStatements.warning)
        }
        else {

            if(typeof(onClick) !== "function"){
                throw new Error(functionStatements.error + " but given '" + typeof(onClick) + "'")
            }
        }

    }
}


export var openTypes = {
    type: "Boolean",
    check: (open) => {

        if (open === undefined) {
            if(warningsOn)
                console.warn(openStatements.warning)
        }
        else {

            if(typeof(open) !== "boolean"){
                throw new Error(openStatements.error + " but given '" + typeof(open) + "'")
            }
        }

    }
}

export var disabledTypes = {
    type: "Boolean",
    check: (disabled) => {

        if (disabled === undefined) {
            if(warningsOn)
                console.warn(disabledStatements.warning)
        }
        else {
            if(typeof(disabled) !== "boolean"){
                throw new Error(disabledStatements.error + " but given '" + typeof(disabled) + "'")
            }
        }

    }
}


export var shadowTypes = {
    type: "Boolean",
    check: (shadow) => {

        if (shadow === undefined) {
            if(warningsOn)
                console.warn(shadowStatements.warning)
        }
        else {
            if(typeof(shadow) !== "boolean"){
                throw new Error(shadowStatements.error + " but given '" + typeof(shadow) + "'")
            }
        }

    }
}


export var contentTypes = {
    type: "React Component or Html Tag",
    check: (content) => {

        if (content === "" || content === undefined || content === null) {
            if(warningsOn)
                console.warn(contentStatements.warning)
        }

    }
}


export var checkboxTooltipPositionTypes = {
    type: "String",
    check: (checkboxTooltipPosition) => {

        if (checkboxTooltipPosition === undefined) {
            if(warningsOn)
                console.warn(checkboxTooltipPositionStatements.warning)
        }
        else {
            if(checkboxTooltipPosition !== "TOP" && checkboxTooltipPosition !== "LEFT" && checkboxTooltipPosition !== "RIGHT" && checkboxTooltipPosition !== "BOTTOM"){
                throw new Error(checkboxTooltipPositionStatements.error + " but given '" + checkboxTooltipPositionStatements + "'")
            }
        }

    }
}


export var badgePositionTypes = {
    type: "String",
    check: (badgePosition) => {

        if (badgePosition === undefined) {
            if(warningsOn)
                console.warn(badgePositionStatements.warning)
        }
        else {
            if(badgePosition !== "TOP_LEFT" && badgePosition !== "BOTTOM_LEFT" && badgePosition !== "TOP_RIGHT" && badgePosition !== "BOTTOM_RIGHT"){
                throw new Error(badgePositionStatements.error + " but given '" + badgePosition + "'")
            }
        }

    }
}


export var drawerPositionTypes = {
    type: "String",
    check: (drawerPosition) => {

        if (drawerPosition === undefined) {
            if(warningsOn)
                console.warn(drawerPositionStatements.warning)
        }
        else {
            if(drawerPosition !== "LEFT" && drawerPosition !== "RIGHT"){
                throw new Error(drawerPositionStatements.error + " but given '" + drawerPosition + "'")
            }
        }

    }
}




export var dateValueTypes = {
    type: "Date Object",
    check: (value) => {

        if (value === undefined) {
            if(warningsOn)
                console.warn(dateValueStatements.warning)
        }
        else {
            if( typeof(value.getMonth) !== "function"){
                throw new Error(dateValueStatements.error + " but given '" + value + "'")
            }
        }

    }
}



export var stringValueTypes = {
    type: "String",
    check: (value) => {

        if (value === undefined) {
            if(warningsOn)
                console.warn(stringValueStatements.warning)
        }
        else {
            if( typeof(value) !== "string"){
                throw new Error(stringValueStatements.error + " but given '" + value + "'")
            }
        }

    }
}


export var integerValueTypes = {
    type: "Integer",
    check: (value) => {

        if (value === undefined) {
            if(warningsOn)
                console.warn(integerValueStatements.warning)
        }
        else {
            if( typeof(value) !== "number"){
                throw new Error(integerValueStatements.error + " but given '" + value + "'")
            }
        }

    }
}


export var animationTypes = {
    type: "Boolean",
    check: (animation) => {

        if (animation === undefined) {
            if(warningsOn)
                console.warn(animationStatements.warning)
        }
        else {
            if( typeof(animation) !== "boolean"){
                throw new Error(animationStatements.error + " but given '" + animation + "'")
            }
        }

    }
}

export var showContentTypes = {
    type: "Boolean",
    check: (showContent) => {

        if (showContent === undefined) {
            if(warningsOn)
                console.warn(showContentStatements.warning)
        }
        else {
            if( typeof(showContent) !== "boolean"){
                throw new Error(showContentStatements.error + " but given '" + showContent + "'")
            }
        }

    }
}


export var buttonTypes = {
    type: "String",
    check: (type) => {

        if (type === undefined) {
            if(warningsOn)
                console.warn(buttonTypeStatements.warning)
        }
        else {
            if(type !== "filled" && type !== "lined" && type !== "text" ){
                throw new Error(buttonTypeStatements.error + " but given '" + type + "'")
            }
        }

    }
}


export var inputTypes = {
    type: "String",
    check: (type) => {

        if (type === undefined) {
            if(warningsOn)
                console.warn(inputTypeStatements.warning)
        }
        else {
            if(type !== "card" && type !== "phone" && type !== "iban" && type !== "password" && type !== "date" && type !== "text"){
                throw new Error(inputTypeStatements.error + " but given '" + type + "'")
            }
        }

    }
}


export var spinnerTypes = {
    type: "String",
    check: (type) => {

        if (type === undefined) {
            if(warningsOn)
                console.warn(spinnerTypeStatements.warning)
        }
        else {
            if(type !== "blink" && type !== "bubble" && type !== "waos" && type !== "spinner"){
                throw new Error(spinnerTypeStatements.error + " but given '" + type + "'")
            }
        }

    }
}


export var switchTypes = {
    type: "String",
    check: (type) => {

        if (type === undefined) {
            if(warningsOn)
                console.warn(switchTypeStatements.warning)
        }
        else {
            if(type !== "basic" && type !== "normal"){
                throw new Error(switchTypeStatements.error + " but given '" + type + "'")
            }
        }

    }
}


export var onChangeTypes = {
    type: "Function",
    check: (onChange) => {

        if (onChange === undefined) {
            if(warningsOn)
                console.warn(onChangeStatements.warning)
        }
        else {
            if(typeof(onChange) !== "function"){
                throw new Error(onChangeStatements.error + " but given '" + onChange + "'")
            }
        }

    }
}


export var cardHeaderTypes = {
    type: "React Component or Html Tag",
    check: (header) => {

        if (header === "" || header === undefined || header === null) {
            if(warningsOn)
                console.warn(cardHeaderStatements.warning)
        }
    }
}


export var otherHeaderTypes = {
    type: "Function",
    check: (header) => {

        if (header === undefined) {
            if(warningsOn)
                console.warn(otherHeaderStatements.warning)
        }
        else {
            if(typeof(header) !== "string"){
                throw new Error(otherHeaderStatements.error + " but given '" + header + "'")
            }
        }

    }
}


export var actionsTypes = {
    type: "React Component or Html Tag",
    check: (actions) => {

        if (actions === "" || actions === undefined || actions === null) {
            if(warningsOn)
                console.warn(actionsStatements.warning)
        }
    }
}


export var placeHolderTypes = {
    type: "String",
    check: (placeholder) => {

        if (placeholder === undefined) {
            if(warningsOn)
                console.warn(placeHolderStatements.warning)
        }
        else {
            if(typeof(placeholder) !== "string"){
                throw new Error(placeHolderStatements.error + " but given '" + placeholder + "'")
            }
        }
    }
}

export var textTypes = {
    type: "String",
    check: (text) => {

        if (text === undefined) {
            if(warningsOn)
                console.warn(textStatements.warning)
        }
        else {
            if(typeof(text) !== "string"){
                throw new Error(textStatements.error + " but given '" + text + "'")
            }
        }
    }
}


export var onCloseTypes = {
    type: "Function",
    check: (onClose) => {

        if (onClose === undefined) {
            if(warningsOn)
                console.warn(onCloseStatements.warning)
        }
        else {
            if(typeof(onClose) !== "function"){
                throw new Error(onCloseStatements.error + " but given '" + onClose + "'")
            }
        }

    }
}


export var heightTypes = {
    type: "Integer",
    check: (height) => {

        if (height === undefined) {
            if(warningsOn)
                console.warn(heightStatements.warning)
        }
        else {
            if( typeof(height) !== "number"){
                throw new Error(heightStatements.error + " but given '" + height + "'")
            }
        }

    }
}

export var optionHeightTypes = {
    type: "String",
    check: (height) => {

        if (height === undefined) {
            if(warningsOn)
                console.warn(optionHeightStatements.warning)
        }
        else {
            if( typeof(height) !== "string"){
                throw new Error(optionHeightStatements.error + " but given '" + height + "'")
            }
        }

    }
}


export var itemsTypes = {
    type: "React Component or Html Tag",
    check: (items) => {

        if (items === "" || items === undefined || items === null) {
            if(warningsOn)
                console.warn(itemsStatements.warning)
        }
    }
}


export var checkedTypes = {
    type: "Boolean",
    check: (checked) => {

        if (checked === undefined) {
            if(warningsOn)
                console.warn(checkedStatements.warning)
        }
        else {
            if( typeof(checked) !== "boolean"){
                throw new Error(checkedStatements.error + " but given '" + checked + "'")
            }
        }

    }
}


export var unitTypes = {
    type: "String",
    check: (unit) => {

        if (unit === undefined) {
            if(warningsOn)
                console.warn(unitStatements.warning)
        }
        else {
            if( typeof(unit) !== "string"){
                throw new Error(unitStatements.error + " but given '" + unit + "'")
            }
        }

    }
}



export var widthTypes = {
    type: "Integer",
    check: (width) => {

        if (width === undefined) {
            if(warningsOn)
                console.warn(widthStatements.warning)
        }
        else {
            if( typeof(width) !== "number"){
                throw new Error(widthStatements.error + " but given '" + width + "'")
            }
        }

    }
}


export var totalTypes ={
    type: "Integer",
    check: (total) => {
        if(total === undefined) {
            if(warningsOn)
            console.warn(totalStatements.warning)
        }
        else {
            if(typeof(total) !== "number"){
                throw new Error(totalStatements.error + "but given '" + total + "'")
            }
        }
    }


}

export var paginationTypes = {
    type:"String",
    check:(type) => {
        if(type === undefined) {
            if(warningsOn)
            console.warn(paginationTypesStatements.warning)
        }
        else {
            if(type !== "filled" && type !== "lined" && type !== "text" ){
                throw new Error(paginationTypesStatements.error + " but given '" + type + "'")
            }
        }
    }
}

export var paginationPositionTypes = {
    type:"String",
    check:(position) => {
        if(position === undefined) {
            if(warningsOn)
            console.warn(paginationPositionStatements.warning)
        }
        else {
            if( position !== "right" && position !== "center" && position !== "left" ){
                throw new Error(paginationPositionStatements.error + " but given '" + position + "'")
            }
        }
    }
}

export var pageTypes ={
    type: "Integer",
    check: (page) => {
        if(page === undefined) {
            if(warningsOn)
            console.warn(pageStatements.warning)
        }
        else {
            if(typeof(page) !== "number" || page<1){
                throw new Error(pageStatements.error + " but given '" + typeof(page) + "' - "+page)
            }
        }
    }


}

export var paginationSizeTypes = {
    type: "Integer",
    check: (size) => {
        if(size === undefined){
            if(warningsOn)
            console.warn(paginationSizeStatements.warning)
        }
        else{
            if(typeof(size) !== "number"){
                throw new Error(paginationSizeStatements.error + "but given '" + "'")
            }
        }
    } 

}


export var dataTypes = {
    type: "Integer Array",
    check: (data) => {

        if (data === undefined) {
            if(warningsOn)
                console.warn(dataStatements.warning)
        }
        else {
            if( data.length !== undefined){
                var i;
                for(i=0;i<data.length;i++){
                    if(typeof(data[i]) !== "number"){
                        throw new Error(dataStatements.error + " but given '" + data[i] + "'")
                    }
                    
                }
            }
            else{
                throw new Error(widthStatements.error + " but given '" + data + "'")
            }
        }

    }
}


export var directionTypes = {
    type: "String",
    check: (direction) => {

        if (direction === undefined) {
            if(warningsOn)
                console.warn(directionStatements.warning)
        }
        else {
            if(direction !== "normal" && direction !== "reverse"){
                throw new Error(directionStatements.error + " but given '" + direction + "'")
            }
        }

    }
}


export var chartLabelsTypes = {
    type: "String Array",
    check: (labels) => {

        if (labels === undefined) {
            if(warningsOn)
                console.warn(chartLabelsStatements.warning)
        }
        else {
            if( labels.length !== undefined){
                var i;
                for(i=0;i<labels.length;i++){
                    if(typeof(labels[i]) !== "string"){
                        throw new Error(chartLabelsStatements.error + " but given '" + labels[i] + "'")
                        
                    }
                    
                }
            }
            else{
                throw new Error(chartLabelsStatements.error + " but given '" + labels + "'")
            }
        }

    }
}


export var selectTooltipLabelTypes = {
    type: "String",
    check: (label) => {

        if (label === undefined) {
            if(warningsOn)
                console.warn(labelStatements.warning)
        }
        else {
            if( typeof(label) !== "string"){
                throw new Error(labelStatements.error + " but given '" + label + "'")
            }
        }

    }
}


export var increasingTypes = {
    type: "Boolean",
    check: (increasing) => {

        if (increasing === undefined) {
            if(warningsOn)
                console.warn(increasingStatements.warning)
        }
        else {
            if( typeof(increasing) !== "boolean"){
                throw new Error(increasingStatements.error + " but given '" + increasing + "'")
            }
        }

    }
}


export var nameTypes = {
    type: "String",
    check: (name) => {

        if (name === undefined) {
            if(warningsOn)
                console.warn(nameStatements.warning)
        }
        else {
            if( typeof(name) !== "string"){
                throw new Error(nameStatements.error + " but given '" + name + "'")
            }
        }

    }
}



export var subjectTypes = {
    type: "String",
    check: (subject) => {

        if (subject === undefined) {
            if(warningsOn)
                console.warn(subjectStatements.warning)
        }
        else {
            if( typeof(subject) !== "string"){
                throw new Error(subjectStatements.error + " but given '" + subject + "'")
            }
        }

    }
}


export var logoTypes = {
    type: "React Component or Html Tag",
    check: (logo) => {

        if (logo === "" || logo === undefined || logo === null) {
            if(warningsOn)
                console.warn(logoStatements.warning)
        }

    }
}


export var detailTypes = {
    type: "String",
    check: (detail) => {

        if (detail === undefined) {
            if(warningsOn)
                console.warn(detailStatements.warning)
        }
        else {
            if(typeof(detail) !== "string"){
                throw new Error(detailStatements.error + " but given '" + detail + "'")
            }
        }

    }
}

export var dateTypes = {
    type: "String",
    check: (date) => {

        if (date === undefined) {
            if(warningsOn)
                console.warn(dateStatements.warning)
        }
        else {
            if(typeof(date) !== "string"){
                throw new Error(dateStatements.error + " but given '" + date + "'")
            }
        }

    }
}

export var maxValueTypes = {
    type: "Integer",
    check: (maxValue) => {

        if (maxValue === undefined) {
            if(warningsOn)
                console.warn(maxValueStatements.warning)
        }
        else {
            if(typeof(maxValue) !== "number"){
                throw new Error(maxValueStatements.error + " but given '" + maxValue + "'")
            }
        }

    }
}


export var onFinishedTypes = {
    type: "Function",
    check: (onFinished) => {

        if (onFinished === undefined) {
            if(warningsOn)
                console.warn(onFinishedStatements.warning)
        }
        else {
            if(typeof(onFinished) !== "function"){
                throw new Error(onFinishedStatements.error + " but given '" + typeof(onFinished) + "'")
            }
        }

    }
}


export var amountTypes = {
    type: "String",
    check: (amount) => {

        if (amount === undefined) {
            if(warningsOn)
                console.warn(amountStatements.warning)
        }
        else {
            if(typeof(amount) !== "string"){
                throw new Error(amountStatements.error + " but given '" + amount + "'")
            }
        }

    }
}



export var currencyLabelTypes = {
    type: "String",
    check: (currency) => {

        if (currency === undefined) {
            if(warningsOn)
                console.warn(currencyStatements.warning)
        }
        else {
            if( typeof(currency) !== "string"){
                throw new Error(currencyStatements.error + " but given '" + currency + "'")
            }
        }

    }
}

export var statistic2CurrencyTypes = {
    type: "String",
    check: (currency) => {

        if (currency === undefined) {
            if(warningsOn)
                console.warn(statistic2CurrencyStatements.warning)
        }
        else {
            if( typeof(currency) !== "string"){
                throw new Error(statistic2CurrencyStatements.error + " but given '" + currency + "'")
            }
        }

    }
}

export var iconTypes = {
    type: "String",
    check: (type) => {

        if (type === undefined) {
            if(warningsOn)
                console.warn(iconTypeStatements.warning)
        }
        else {
            if(type !== "filled" && type !== "outlined" ){
                throw new Error(iconTypeStatements.error + " but given '" + type + "'")
            }
        }

    }
}

export var rowspanTypes = {

    type: "Integer",
    check: (rowspan) => {

        if (rowspan === undefined) {
            if(warningsOn)
                console.warn(rowspanStatements.warning)
        }
        else {
            if(typeof(rowspan) !== "number"){
                throw new Error(rowspanStatements.error + " but given '" + rowspan + "'")
            }
        }

    }
    
}

export var xsTypes = {
    type: "Integer",
    check: (xs) => {

        if (xs === undefined) {
            if(warningsOn)
            console.warn(xsStatements.warning)
        }
        else {
            if(typeof(xs) !== "number"){
                throw new Error(xsStatements.error + "but given '" + xs + "'" )
            }
        }
    }
}

export var smTypes = {
    type: "Integer",
    check: (sm) => {

        if (sm === undefined) {
            if(warningsOn)
            console.warn(smStatements.warning)
        }
        else {
            if(typeof(sm) !== "number"){
                throw new Error(smStatements.error + "but given '" + sm + "'" )
            }
        }
    }
}

export var mdTypes = {
    type: "Integer",
    check: (md) => {

        if (md === undefined) {
            if (warningsOn)
            console.warn(mdStatements.warning)
        }
        else {
            if(typeof(md) !== "number"){
                throw new Error(mdStatements.error + "but given '" + md + "'")
            }
        }
    }
}

export var lgTypes = {
    type: "Integer",
    check: (lg) => {

        if (lg === undefined) {
            if (warningsOn)
            console.warn(lgStatements.warning)
        }
        else {
            if(typeof(lg) !== "number"){
                throw new Error(lgStatements.error + "but given '" + lg + "'")
            }
        }
    }

}

export var xlTypes = {
    type: "Integer",
    check: (xl) => {
        
        if (xl === undefined) {
            if (warningsOn)
            console.warn(xlStatements.warning)
        }
        else {
            if(typeof(xl) !== "number"){
                throw new Error(xlStatements.error + "but given '" + xl + "'")
            }
        }
    }
    
}

export var colspanTypes = {

    type: "Integer",
    check: (colspan) => {

        if (colspan === undefined) {
            if(warningsOn)
                console.warn(colspanStatements.warning)
        }
        else {
            if(typeof(colspan) !== "number"){
                throw new Error(colspanStatements.error + " but given '" + colspan + "'")
            }
        }

    }
    
}

export var fontSizeTypes = {

    type: "String",
    check: (fontSize) => {

        if (fontSize === undefined) {
            if(warningsOn)
                console.warn(fontSizeStatements.warning)
        }
        else {
            if(typeof(fontSize) !== "string"){
                throw new Error(fontSizeStatements.error + " but given '" + fontSize + "'")
            }
        }

    }
    
}

export var fileTypes = {
    type: "String",
    check: (type) => {

        if (type === undefined) {
            if(warningsOn)
                console.warn(fileTypeStatements.warning)
        }
        else {
            if(type !== "pdf" && type !== "excel" && type !== "word" && type !== "powerpoint" ){
                throw new Error(fileTypeStatements.error + " but given '" + type + "'")
            }
        }

    }
}

export var increaseTypes = {
    type: "Boolean",
    check: (increase) => {

        if (increase === undefined) {
            if(warningsOn)
                console.warn(increaseStatements.warning)
        }
        else {
            if( typeof(increase) !== "boolean"){
                throw new Error(increaseStatements.error + " but given '" + increase + "'")
            }
        }

    }
}

export var themeProviderCheck = {
    type: "String",
    check: (theme) => {

        if (theme === undefined) {
            throw new Error(themeProviderStatements.error)
        }

    }
}
