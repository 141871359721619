import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { childrenTypes, colorTypes, styleTypes } from "./ErrorHandler";


const ThemeContext = createContext(undefined);
const ThemeSwitchContext = createContext(undefined)
export var warningsOn = false;

export var weekdays = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi"
]

export var months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık"
]

function ThemeProvider({ children, pallets, isWarningsOn, calendar}) {

    

    const [theme, setTheme] = useState(pallets === undefined ?
        {
            "primary": "#007aff",
            "secondary": "#5D3FD3",
            "dark": "#222",
            "success": "#26D96C",
            "warning": "#FFDB00",
            "alert": "#E20D0D",
            "info": "#68E6FB",
            "light": "#fff",
            "gray": "#ccc",
            "darkgray": "#aaa",
            "leftMenu":"#405189",
            "topMenu":"#7d8bba",
            "content":"#7d8bba",
            "text":"#000",
            "textGray":"#ccc",
        } : (pallets.length === 0 || pallets.length === undefined)? {
            "primary": "#007aff",
            "secondary": "#5D3FD3",
            "dark": "#222",
            "success": "#26D96C",
            "warning": "#FFDB00",
            "alert": "#E20D0D",
            "info": "#68E6FB",
            "light": "#fff",
            "gray": "#ccc",
            "darkgray": "#aaa",
            "leftMenu":"#405189",
            "topMenu":"#7d8bba",
            "content":"#7d8bba",
            "text":"#000",
            "textGray":"#ccc",
        } : pallets[0]
    );

    const [themeSwitch, setThemeSwitch] = useState(0)
   
    useEffect(()=>{

        if(pallets!==undefined && pallets.length !== undefined && pallets.length !== 0 && themeSwitch<pallets.length && themeSwitch>=0)
            setTheme(pallets[themeSwitch])

        
    },[themeSwitch])

    useEffect(()=>{

        if(isWarningsOn!==undefined && typeof(isWarningsOn) === "boolean")
            warningsOn = isWarningsOn;

    },[isWarningsOn])



    useEffect(()=>{
        
        if(calendar!==undefined){

            if(calendar.months.length===12 && Array.isArray(calendar.months) && calendar.weekdays.length===7 && Array.isArray(calendar.weekdays)){

                months = calendar.months;
                weekdays = calendar.weekdays;
    
            }
        }




    },[calendar])




    return (
        <ThemeContext.Provider value={theme}>
            <ThemeSwitchContext.Provider value={setThemeSwitch}>
                {children}
            </ThemeSwitchContext.Provider>
        </ThemeContext.Provider>
    );
}

export { ThemeProvider, ThemeContext, ThemeSwitchContext};