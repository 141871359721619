import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../Components/ThemeProvider';
import Icon from '../../Components/Icon';
import Slider from '../../Shared/Slider';
import Statistic from '../../Components/Statistic';
import Cell from '../../Components/Cell';
import Table from '../../Components/Table';
import Avatar from '../../Components/Avatar';
import Chip from '../../Components/Chip';
import Button from '../../Components/Button';
import Row from '../../Components/Row';
import Pagination from '../../Components/Pagination';
import Alert from '../../Components/Alert';


function ProductDetail() {


    const theme = useContext(ThemeContext);


    return (
        <div className='container-fluid'>
            <div className='row justify-content-center' style={{ marginTop: "20px" }}>
                <div className='col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10'>
                    <Alert color='alert'>Size <b>L</b> color <b>RED</b> is running out of stock !</Alert>
                </div>
            </div>
            <div className='row justify-content-center' style={{ marginTop: "20px" }}>
                <div className='col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10' style={{ textAlign: "center", marginTop: "10px" }}>
                    <h1 style={{ textAlign: "start", fontSize: "26px", marginBottom: "10px", marginTop: "10px", color: theme["text"] }}>Product Detail</h1>
                </div>
            </div>
            <div className='row justify-content-center' style={{ marginTop: "20px" }}>

                <div className='col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10' style={{ textAlign: "center", marginTop: "10px" }}>

                    <div style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "10px", backgroundColor: theme["topMenu"] }}>

                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>

                                    <Slider comps={[
                                        <img src="p5.jpg" style={{ width: "300px" }} />,
                                        <img src="p1.png" />,
                                        <img src="p2.png" />,
                                        <img src="p3.png" />,
                                        <img src="p4.png" />,
                                    ]} />
                                </div>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                                    <div style={{ width: "100%", marginTop: "10px", marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <h1 style={{ fontSize: "18px", textAlign: "start", margin: "10px",color:theme["text"] }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry</h1>
                                        <button style={{ border: "none", backgroundColor: "transparent", width: "100px", color: theme["primary"] }}><Icon name='vo-horizontal-menu' type='outlined' color='primary' />Edit</button>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                        <Statistic unit={'$'} amount={'650'} content={'Price'} />
                                        <Statistic unit={'#'} amount={'650'} content={'Stock'} increase={false} />
                                    </div>
                                    <label style={{ fontSize: "14px", color: theme["gray"], textAlign: "start", display: "block", margin: "10px", marginTop: "20px" }}>Description</label>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <p style={{ textAlign: "start", fontSize: "12px", margin: "10px",color:theme["text"] }}>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className='row justify-content-center' style={{ marginTop: "20px" }}>

                <div className='col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10' style={{ textAlign: "center", marginTop: "10px" }}>
                    <div style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "10px", backgroundColor: theme["topMenu"] }}>

                        <Table style={{ color: theme["text"] }} color={"gray"}>
                            <Row>
                                <Cell header>Product</Cell>
                                <Cell header>Size</Cell>
                                <Cell header>Color</Cell>
                                <Cell header style={{ textAlign: "center" }}>Amount</Cell>
                                <Cell header style={{ textAlign: "center" }}>Stock</Cell>
                                <Cell header style={{ textAlign: "center" }}>Detail</Cell>
                            </Row>
                            <Row>
                                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p1.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                                <Cell >L</Cell>
                                <Cell ><div style={{ height: "30px", width: "30px", borderRadius: "30px", backgroundColor: "red" }}></div></Cell>
                                <Cell ><Chip label={"600$"} color="success" /></Cell>
                                <Cell ><Chip label={"400"} color="info" /></Cell>
                                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
                            </Row>
                            <Row>
                                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p2.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                                <Cell >L</Cell>
                                <Cell ><div style={{ height: "30px", width: "30px", borderRadius: "30px", backgroundColor: "red" }}></div></Cell>
                                <Cell ><Chip label={"600$"} color="success" /></Cell>
                                <Cell ><Chip label={"400"} color="info" /></Cell>
                                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
                            </Row>
                            <Row>
                                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p3.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                                <Cell >L</Cell>
                                <Cell ><div style={{ height: "30px", width: "30px", borderRadius: "30px", backgroundColor: "red" }}></div></Cell>
                                <Cell ><Chip label={"600$"} color="success" /></Cell>
                                <Cell ><Chip label={"400"} color="info" /></Cell>
                                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
                            </Row>
                            <Row>
                                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p4.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                                <Cell >S</Cell>
                                <Cell ><div style={{ height: "30px", width: "30px", borderRadius: "30px", backgroundColor: "red" }}></div></Cell>
                                <Cell ><Chip label={"600$"} color="success" /></Cell>
                                <Cell ><Chip label={"400"} color="info" /></Cell>
                                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
                            </Row>
                            <Row>
                                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p4.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                                <Cell >M</Cell>
                                <Cell ><div style={{ height: "30px", width: "30px", borderRadius: "30px", backgroundColor: "red" }}></div></Cell>
                                <Cell ><Chip label={"600$"} color="success" /></Cell>
                                <Cell ><Chip label={"400"} color="info" /></Cell>
                                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
                            </Row>

                        </Table>
                        <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <Pagination total={100} color={'primary'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;
