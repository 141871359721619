import React, { useContext, useEffect, useState } from 'react';
import L from 'leaflet'
import { ThemeContext } from '../../Components/ThemeProvider';
import Avatar from '../../Components/Avatar';
import Button from '../../Components/Button';
import Icon from '../../Components/Icon';
import Statistic3 from '../../Components/Statistic3'


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import Table from '../../Components/Table';
import Row from '../../Components/Row';
import Cell from '../../Components/Cell';
import Chip from '../../Components/Chip';
import Alert from '../../Components/Alert';
import Switch from '../../Components/Switch';
import Checkbox from '../../Components/Checkbox';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);






function Dashboard() {



  const theme = useContext(ThemeContext);
  const [checked, setChecked] = useState(true)

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '',
      },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Earnings',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: theme["primary"],
      },
      {
        label: 'Customer',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: theme["secondary"],
      },
    ],
  };

  useEffect(() => {
    let map = L.map('map').setView([12, 28], 1);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    L.marker([51.5, -0.09]).addTo(map)
      .openPopup();
    L.marker([40.5, 50]).addTo(map)
      .openPopup();
    L.marker([12.5, -1.09]).addTo(map)
      .openPopup();
    L.marker([4.5, 20.09]).addTo(map)
      .openPopup();
  }, [])

  return (
    <div className='container-fluid'>
      <div className='row justify-content-center' style={{ marginTop: "20px" }}>
        <div className='col-10'>
          <Alert color='alert'>Hey! There is a returning which is not checked since 22.04.2020</Alert>
        </div>
      </div>
      <div className='row justify-content-center' style={{ marginTop: "20px" }}>
        <div className='col-10'>
          <div>
            <h1 style={{ textAlign: "start", fontSize: "22px", marginBottom: "10px", marginTop: "10px", color: theme["text"] }}>Hello Laurie</h1>
            <p style={{ textAlign: "start", fontSize: "16px", marginBottom: "10px", marginTop: "10px", color: theme["textGray"] }}>Here's what's happening with your store today.</p>

          </div>
        </div>
      </div>

      <div className='row justify-content-center' style={{ marginTop: "20px" }}>
        <div className='col-10'>
          <div style={{ display: "flex", justifyContent: "space-between" }}>

            <div>
              <label style={{ fontSize: "12px", color: theme["gray"], width: "90px", textAlign: "center", display: "inline-block" }}>Online</label>
              <Switch color='success' checked={true} />
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button type={'filled'} size='lg' color={'primary'}><Icon name='vo-plus' type='outlined' color='light' />Add Product</Button>
            </div>

          </div>
        </div>
      </div>
      <div className='row justify-content-center' style={{ marginTop: "20px" }}>
        <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2' style={{ textAlign: "center", marginTop: "10px" }}>

          <Statistic3 header={'Orders'} text={'10565$'} increase={false} />
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2' style={{ textAlign: "center", marginTop: "10px" }}>

          <Statistic3 header={'Earnings'} text={'10565$'} increase={true} />

        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2' style={{ textAlign: "center", marginTop: "10px" }}>

          <Statistic3 header={'Returnings'} text={'10565$'} increase={false} />

        </div>

        <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2' style={{ textAlign: "center", marginTop: "10px" }}>
          <Statistic3 header={'Cancelled'} text={'10565$'} increase={true} />
        </div>

        <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2' style={{ textAlign: "center", marginTop: "10px" }}>
          <Statistic3 header={'Balance'} text={'10565$'} increase={false} />
        </div>

      </div>
      <div className='row justify-content-center' style={{ marginTop: "20px" }}>

        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6' style={{ textAlign: "center", position: "relative", marginTop: "10px" }}>

          <div style={{ backgroundColor: "transparent", borderRadius: "10px", border: "1px solid #ccc", backgroundColor: theme["topMenu"] }}>
            <Bar options={options} data={data} />
          </div>

        </div>

        <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ textAlign: "center", position: "relative", marginTop: "10px" }}>
          <div style={{ width: "100%", height: "100%", overflow: "hidden", padding: "20px", border: "1px solid #ccc", borderRadius: "10px", backgroundColor: theme["topMenu"] }}>

            <h1 style={{ textAlign: "start", fontSize: "22px", marginBottom: "10px", marginTop: "10px", color: theme["text"] }}>Orders Map</h1>

            <div id="map" style={{ minHeight: "300px", width: "100%" }}></div>

          </div>

        </div>

      </div>
      <div className='row justify-content-center' style={{ marginTop: "20px" }}>
        <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ textAlign: "center", marginTop: "10px" }}>
          <div style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "10px", backgroundColor: theme["topMenu"] }}>

            <h1 style={{ textAlign: "start", fontSize: "22px", marginBottom: "10px", marginTop: "10px", color: theme["text"] }}>Lastest Orders</h1>

            <Table style={{ color: theme["text"] }} color={"gray"}>
              <Row>
                <Cell header>Company</Cell>
                <Cell header>Contact</Cell>
                <Cell header>Country</Cell>
                <Cell header>Detail</Cell>
              </Row>
              <Row>
                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={true} onClick={() => { alert('Avatar Clicked') }} src="avatar-1.jpg" /> Alfreds Futterkiste</Cell>
                <Cell >0 850 655 66 44</Cell>
                <Cell >TURKEY</Cell>
                <Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detail</Button></Cell>
              </Row>
              <Row>
                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={true} onClick={() => { alert('Avatar Clicked') }} src="avatar.PNG" /> Alfreds Futterkiste</Cell>
                <Cell >0 869 654 55 66</Cell>
                <Cell >GERMANY</Cell>
                <Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detail</Button></Cell>
              </Row>
              <Row>
                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={true} onClick={() => { alert('Avatar Clicked') }} src="avatar1.jpg" /> Alfreds Futterkiste</Cell>
                <Cell >0 987 456 54 66</Cell>
                <Cell >USA</Cell>
                <Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detail</Button></Cell>
              </Row>
              <Row>
                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={true} onClick={() => { alert('Avatar Clicked') }} src="avatar2.PNG" /> Alfreds Futterkiste</Cell>
                <Cell >0 421 748 12 45</Cell>
                <Cell >UKRAINE</Cell>
                <Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detail</Button></Cell>
              </Row>
              <Row>
                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={true} onClick={() => { alert('Avatar Clicked') }} src="avatar4.jpg" />
                  Alfreds Futterkiste</Cell>
                <Cell >0 789 987 45 65</Cell>
                <Cell >TURKEY</Cell>
                <Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detail</Button></Cell>
              </Row>
            </Table>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6' style={{ textAlign: "center", marginTop: "10px" }}>
          <div style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "10px", backgroundColor: theme["topMenu"] }}>

            <h1 style={{ textAlign: "start", fontSize: "22px", marginBottom: "10px", marginTop: "10px", color: theme["text"] }}>Best Selling Products</h1>

            <Table style={{ color: theme["text"] }} color={"gray"}>
              <Row>
                <Cell header>Product</Cell>
                <Cell header>Amount</Cell>
                <Cell header>Detail</Cell>
              </Row>
              <Row>
                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p1.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                <Cell ><Chip label={"600$"} color="success" /></Cell>
                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
              </Row>
              <Row>
                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p2.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                <Cell ><Chip label={"600$"} color="success" /></Cell>
                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
              </Row>
              <Row>
                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p3.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                <Cell ><Chip label={"600$"} color="success" /></Cell>
                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
              </Row>
              <Row>
                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p4.PNG" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                <Cell ><Chip label={"600$"} color="success" /></Cell>
                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
              </Row>
              <Row>
                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p5.jpg" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                <Cell ><Chip label={"600$"} color="success" /></Cell>
                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
              </Row>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
