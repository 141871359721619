import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../Components/ThemeProvider';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import Spinner from '../../Components/Spinner';
import { redirect } from "react-router-dom";



function Login() {




    const theme = useContext(ThemeContext);

    const [clicked, setClicked] = useState(0)

    useEffect(() => {
        if (clicked === 1) {
            setTimeout(() => {
                window.location.href="/"
            }, 3000)
        }
    }, [clicked]);

    if (clicked === 0) {
        return (
            <div className='container-fluid' style={{ backgroundImage: "url('commerce.jpg')", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <div className='row justify-content-center'>
                    <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-4' style={{ textAlign: "center" }}>
                        <div style={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ backgroundColor: "white", height: "600px", width: "100%", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px" }}>
                                <div style={{ margin: "40px" }}>
                                    <img src="logo.png" style={{ width: "40px" }} />
                                    <p style={{ color: theme["text"] }}>GDASH v.1.0</p>
                                </div>
                                <h1 style={{ color: theme["text"], fontSize: "20px", margin: "40px", marginBottom: "5px" }}>Welcome !</h1>
                                <p style={{ fontSize: "14px", color: theme["gray"] }}>You can login by entering your username and password!</p>
                                <p style={{ fontSize: "12px", color: theme["success"], margin: "0px" }}> <b>&#127895; In memory of my deceased friend PEANUT</b></p>
                                <div>
                                    <Input color='primary' size='md' type='text' label={"Kullanıcı Adı"} value={"peanut"} />
                                </div>
                                <div>
                                    <Input color='primary' size='md' type='password' label={"Şifre"} value={"1231qfasfe13"} />
                                </div>
                                <div>
                                    <Button color='primary' type='filled' size='lg' style={{ marginTop: "10px" }} onClick={() => setClicked(1)}> Login </Button>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <a href='#' style={{ fontSize: "12px", marginTop: "15px" }}> Forget your password ? </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (clicked === 1) {
        return (
            <div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div>
                    <span>Loading...</span>
                    <Spinner color='secondary' type='bubble' />
                </div>
            </div>
        );
    }
    else {
        return (
            <div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div>
                    <span>Loading...</span>
                    <Spinner color='secondary' type='bubble' />
                </div>
            </div>
        );
    }

}

export default Login;
