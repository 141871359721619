/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************

color ---> primary || secondary || success || info || warning || alert || dark || darkgray || gray(default)

size ---> sm(default) || md || lg 

disabled ---> true || false(default)

value ---> 'Date'(Object) - Default (Today)

onChange ---> function - returns selected date object 


*/
import React,{ useState, useEffect, useContext } from "react";
import Option from "../Option";
import Select from "../Select";
import { hexToRgbA, calendarSizes, yearRange} from "../Theme";
import { weekdays, months } from "../ThemeProvider";
import { ThemeContext } from "../ThemeProvider";
import { colorTypes, sizeTypes, disabledTypes, dateValueTypes, onChangeTypes, themeProviderCheck } from "../ErrorHandler";
function Calendar({color, size, disabled, value, onChange}) {

    const theme = useContext(ThemeContext);

    const [date, setDate] = useState(value !== undefined ? value : new Date())

    const [selectIndex,setSelectIndex] = useState(undefined)

    const [month, setMonth] = useState(date.getMonth())
    const [year, setYear] = useState(date.getFullYear())
    const [mouseIn, setMouseIn] = useState(false)



    const [localSize, setLocalSize] = useState("sm")

    const [localColor, setLocalColor] = useState(theme["darkgray"])


    useEffect(()=>{

        colorTypes.check(color)
        sizeTypes.check(size)
        disabledTypes.check(disabled)
        onChangeTypes.check(onChange)
        dateValueTypes.check(value)
        themeProviderCheck.check(theme)
        
      },[color, size, disabled, onChange, value, theme])


    useEffect(()=>{
        if(value !== undefined && new Date(value).getTime() !== date.getTime()){
           
            setDate(new Date(value))
            setMonth(new Date(value).getMonth())
            setYear(new Date(value).getFullYear())
        }
    },[value])


    useEffect(() => {

        if(onChange!==undefined)
            onChange(new Date(date))
        
    }, [date])

    useEffect(() => {

        if (color !== undefined) {
            setLocalColor(theme[color])
        }
    }, [theme])


    useEffect(() => {

        setSelectIndex(year-yearRange[0])

    }, [year])


    useEffect(() => {

        if(selectIndex!==undefined)
            setYear(Number(selectIndex) + yearRange[0])

    }, [selectIndex])


    useEffect(() => {

        if( size!==undefined && size!=="" & size !== null){
            setLocalSize(size)
        }

    }, [size])


    
    const daysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    }
    const createDays = () => {
        let dayCount = daysInMonth(month + 1, year)
        let firstDay = new Date(year, month, 1).getDay()
        let days = <div>
            <span onClick={(e) => {
                if(!disabled){
                    e.preventDefault()
                    setDate(new Date(year, month, 1))
                    e.stopPropagation()
                }
                    
                
                }} style={{ width: calendarSizes[localSize].dayWidth + "px", padding: calendarSizes[localSize].padding + "px", textAlign: "center", cursor: "pointer", height: "auto", display: "inline-block", position: "relative", marginLeft: ((firstDay) * (calendarSizes[localSize].dayWidth + (calendarSizes[localSize].padding * 2) + 2)), border: date.getDate() === 1 && date.getMonth() === month && date.getFullYear() === year ? "1px solid " + localColor : "1px solid transparent", borderRadius: "5px" }}>{1}</span>
            {
                [...Array(dayCount - 1)].map((item, index) => {
                    return <span onClick={(e) => {
                        if(!disabled){
                            e.preventDefault()
                            setDate(new Date(year, month, index + 2))
                            e.stopPropagation()
                        }
                            
                    
                    }} style={{ width: calendarSizes[localSize].dayWidth + "px", padding: calendarSizes[localSize].padding + "px", textAlign: "center", cursor: "pointer", height: "auto", display: "inline-block", position: "relative", border: date.getDate() === index + 2 && date.getMonth() === month && date.getFullYear() === year && !disabled ? "1px solid " + localColor : "1px solid transparent", borderRadius: "5px", backgroundColor: date.getDate() === index + 2 && date.getMonth() === month && date.getFullYear() === year && disabled ?theme["gray"]:"none" }}>{index + 2}</span>
                })
            }
        </div>

        return days

    }


    return (
        <div style={{zIndex:"100001", backgroundColor: theme["light"], width: calendarSizes[localSize].monthWidth, height: "auto", border: "1px solid " + theme["gray"], color: theme["dark"], fontSize: calendarSizes[localSize].fontSize, paddingBottom: "30px", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
            <div style={{ width: "auto", padding: "10px" }}>

                <span style={{ paddingTop:"17px",fontWeight: "bold", color: theme["dark"],display:"flex", alignItems:"center", justifyContent:"center" }}>
                    <Select value={selectIndex} onChange={setSelectIndex} color={color} size={localSize} disabled={disabled}>
                        {
                            [...Array((yearRange[1]-yearRange[0]) + 1)].map((yearx,index)=>{
                                return <Option key={yearRange[0] + index}>{yearRange[0] + index}</Option>
                            })
                        }
                        
                        
                    </Select>
                </span>

            </div>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                <button disabled={disabled} onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)} style={{display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"40px", width:"40px", height:"40px" ,boxShadow: mouseIn ? ("0px 0px 0px 3px " + hexToRgbA(localColor)) : "none" ,backgroundColor: "transparent", cursor: "pointer", border: "none" }} onClick={(e) => {e.preventDefault(); setMonth(month === 0 ? 11 : month - 1); e.stopPropagation()}}>
                    <svg width="20" height="20" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3345 3.79289C24.725 3.40237 24.725 2.7692 24.3345 2.37868L24.2487 2.29289C23.8582 1.90237 23.225 1.90237 22.8345 2.29289L10.1066 15.0208C9.71607 15.4113 9.71607 16.0445 10.1066 16.435L22.8345 29.163C23.225 29.5535 23.8582 29.5535 24.2487 29.163L24.3345 29.0772C24.725 28.6866 24.725 28.0535 24.3345 27.663L13.1066 16.435C12.7161 16.0445 12.7161 15.4113 13.1066 15.0208L24.3345 3.79289Z" fill={theme["dark"]} />
                    </svg>
                </button>
                <span style={{ fontWeight: "bold", color: theme["dark"], display:"flex", alignItems:"center" }}>{months[month]}</span>
                <button disabled={disabled} onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)} style={{display:"flex",alignItems:"center",justifyContent:"center", borderRadius:"40px", width:"40px", height:"40px" ,boxShadow: mouseIn ? ("0px 0px 0px 3px " + hexToRgbA(localColor)) : "none", backgroundColor: "transparent", cursor: "pointer", border: "none" }} onClick={(e) => {e.preventDefault(); setMonth(month === 11 ? 0 : month + 1); e.stopPropagation()}}>
                    <svg width="20" height="20" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.9203 28.2071C8.52978 28.5976 8.52978 29.2308 8.9203 29.6213L9.00609 29.7071C9.39661 30.0976 10.0298 30.0976 10.4203 29.7071L23.1482 16.9792C23.5387 16.5887 23.5387 15.9555 23.1482 15.565L10.4203 2.83705C10.0298 2.44652 9.39661 2.44653 9.00609 2.83705L8.9203 2.92283C8.52978 3.31336 8.52978 3.94652 8.9203 4.33705L20.1482 15.565C20.5387 15.9555 20.5387 16.5887 20.1482 16.9792L8.9203 28.2071Z" fill={theme["dark"]} />
                    </svg>
                </button>
            </div>
            <div style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
                <div style={{ width: calendarSizes[localSize].monthWidth + "px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid " + theme["gray"] }}>
                    {
                        weekdays.map((day, index) => {
                            return <span style={{ width: (calendarSizes[localSize].dayWidth + (calendarSizes[localSize].padding * 2)) + "px", color: theme["darkgray"], textAlign: "center", marginBottom: "15px", border: "1px solid transparent", height: "25px", display: "inline-block" }}>{day.charAt(0) + day.charAt(1)}</span>
                        })
                    }
                </div>
                <div style={{ width: calendarSizes[localSize].monthWidth + "px", position: "relative", display: "flex", justifyContent: "space-beetwen", textAlign: "start", paddingLeft: "1px" }}>
                    {
                        createDays(month)
                    }

                </div>
            </div>
        </div>
    );
}

export default Calendar;
