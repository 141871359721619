import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import NotificationComponent from "../../Components/Notification";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Notification() {

    

    return (
        <Grid>
            <Header>
                Notification
            </Header>
            <Description>
            It helps you to create notification areas that warn and remind the user. Takes the "header", "content", "date", "dateail" and "color" props.
            </Description>
            <Showroom>
                <NotificationComponent header={"Software Requirements Document"} content={"The document will be checked and typos will be corrected. The document will be checked and typos will be corrected. The document will be checked and typos will be corrected. The document will be checked and typos will be corrected."} detail={"The last 2 days"} color="alert" date={"11/12/2022"}></NotificationComponent>
            </Showroom>
            <Subheader>
                Header
            </Subheader>
            <Description>
            "header" the notification header you want to create for the Notification Component.
            </Description>
            <Showroom>
            <NotificationComponent header={"Software Requirements Document"} ></NotificationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Notification header={'Software Requirements Document'} />\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Content
            </Subheader>
            <Description>
            You can write the declaration descriptions in the "content" prop.
            </Description>
            <Showroom>
            <NotificationComponent header={"Software Requirements Document"} content={"The document will be checked and typos will be corrected."} ></NotificationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Notification header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Detail
            </Subheader>
            <Description>
            You can add details about the notification.
            </Description>
            <Showroom>
            <NotificationComponent header={"Software Requirements Document"} content={"The document will be checked and typos will be corrected."} detail={"The last 2 days"}></NotificationComponent>
            </Showroom>
            <Coderoom>
            {"export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Notification header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'}/>\n" +
             "\t)\n" +
             "}"}
            </Coderoom>
            <Subheader>
                Date
            </Subheader>
            <Description>
            If you want to specify the date, you can use the "date" prop.
            </Description>
            <Showroom>
            <NotificationComponent header={"Software Requirements Document"} content={"The document will be checked and typos will be corrected."} detail={"The last 2 days"} date={"11/12/2022"}></NotificationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Notification header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Color
            </Subheader>
            <Description>
            You can change the colors that indicate that my notifications are not read using the color prop. It can take "primary", "secondary", "success", "warning", "info", "alert", "dark", "light", "gray" and "darkgray" values. By default it takes the value "drakgray".

            </Description>
            <Showroom>
            <NotificationComponent header={"Software Requirements Document"} content={"The document will be checked and typos will be corrected."} detail={"The last 2 days"} color="primary" date={"11/12/2022"}></NotificationComponent>
            <NotificationComponent header={"Software Requirements Document"} content={"The document will be checked and typos will be corrected."} detail={"The last 2 days"} color="warning" date={"11/12/2022"}></NotificationComponent>
            <NotificationComponent header={"Software Requirements Document"} content={"The document will be checked and typos will be corrected."} detail={"The last 2 days"} color="success" date={"11/12/2022"}></NotificationComponent>
            <NotificationComponent header={"Software Requirements Document"} content={"The document will be checked and typos will be corrected."} detail={"The last 2 days"} color="alert" date={"11/12/2022"}></NotificationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Notification header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'primary'}/>\n" +
             "\t\t<Notification header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'warning'}/>\n" +
             "\t\t<Notification header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'success'}/>\n" +
             "\t\t<Notification header={'Software Requirements Document'} content={'The document will be checked and typos will be corrected.'} detail={'The last 2 days'} date={'11/12/2022'} color={'alert'}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>

            <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "header",
                            type: "string", 
                            default: "undefined", 
                            description: "header of the Notification"
                          },
                          {
                            name: "content",
                            type: "string", 
                            default: "undefined", 
                            description: "content of the Notification" 
                          },
                          {
                            name: "detail",
                            type: "string", 
                            default: "undefined", 
                            description: "detail of the Notification" 
                          },
                          {
                            name: "date",
                            type: "string", 
                            default: "undefined", 
                            description: "date of the Notification" 
                          }, 
                          {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray", 
                            default: "gray", 
                            description: "unread notification color" 
                          },                          
                      ]}  
                        
                    />
                </div>
            </div>

        </Grid>
    )


}
export default Notification;