import React, { useEffect, useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import TextAreaComponent from "../../Components/TextArea";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function TextArea(){
    

    return(
        <Grid>
            <Header>
                TextArea
            </Header>
            <Description>
            Provides multi-line text input control. It is often used to collect user input such as comments or reviews.
            </Description>
            <Subheader>
                Color
            </Subheader>
            <Description>
            You can apply colors suitable for your theme or content using our palette. It can take "primary", "secondary", "info", "warning", "success", "alert", "dark", "darkgray" values. Defaults to "dark".
            </Description>
            <Showroom>
                <TextAreaComponent ></TextAreaComponent>
                <TextAreaComponent color={"secondary"} ></TextAreaComponent>
                <TextAreaComponent color={"success"} ></TextAreaComponent>
                <TextAreaComponent color={"alert"} ></TextAreaComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<TextArea />\n" +
             "\t\t<TextArea color={'secondary'}/>\n" +
             "\t\t<TextArea color={'success'}/>\n" +
             "\t\t<TextArea color={'alert'}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Label
            </Subheader>
            <Description>
            You can write the message you want to give in the form in "label" props.
            </Description>
            <Showroom>
                <TextAreaComponent color={"secondary"} label={"Yorumlarınızı giriniz..."}></TextAreaComponent>
                <br></br>
                <TextAreaComponent color={"secondary"} label={"Öneri ve görüşlerinizi buraya yazınız..."}></TextAreaComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<TextArea />\n" +
             "\t\t<TextArea color={'secondary'} label={'Yorumlarınızı giriniz...'} />\n" +
             "\t\t<TextArea color={'secondary'} label={'Öneri ve görüşlerinizi buraya yazınız...'} />\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                onChange
            </Subheader>
            <Description>
            Using this function, you can determine how you want to use your component.
            </Description>
            <Showroom>
                <TextAreaComponent color={"secondary"} label={"Öneri ve görüşlerinizi bu alana giriniz..."} onChange={(value) => { console.log(value) }}></TextAreaComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<TextArea color={'secondary'} label={'Öneri ve görüşlerinizi bu alana giriniz...'} onChange={(value) => { console.log(value) }}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Disabled
            </Subheader>
            <Description>
            You can turn off clicking on the Text Field using "disabled" props. It is false by default. When you set it to true, you cannot login to the field.
            </Description>
            <Showroom>
            <TextAreaComponent color={"secondary"} label={"Öneri ve görüşlerinizi bu alana giriniz..."} onChange={(value) => { console.log(value) }} disabled={false}></TextAreaComponent>
            <TextAreaComponent color={"secondary"} label={"Öneri ve görüşlerinizi bu alana giriniz..."} onChange={(value) => { console.log(value) }} disabled={true}></TextAreaComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<TextArea color={'secondary'} label={'Öneri ve görüşlerinizi bu alana giriniz...'} disabled={false} onChange={(value) => { console.log(value) }} />\n" +
             "\t\t<TextArea color={'secondary'} label={'Öneri ve görüşlerinizi bu alana giriniz...'} disabled={true} onChange={(value) => { console.log(value) }} />\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>

            <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray", 
                            default: "gray", 
                            description: "You can give the colors that exist in the palette to the text field."
                          },
                          {
                            name: "label",
                            type: "string", 
                            default: "undefined", 
                            description: "You can enter the message you want to forward." 
                          },
                          {
                            name: "onChange",
                            type: "function", 
                            default: "undefined", 
                            description: "You can add function to component." 
                          },
                          {
                            name: "disabled",
                            type: "true\nfalse", 
                            default: "false", 
                            description: "You can turn the field entry on or off with disabled props." 
                          },
                          {
                            name: "style",
                            type: "", 
                            default: "undefined", 
                            description: " " 
                          }
                      ]}  
                        
                    />
                </div>
            </div>

            
        </Grid>
    )
}
export default TextArea;