/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************
type ---> file type which can be pdf, excel, word, powerpoint

name ---> string which is the filename

onClick ---> callback function which is called on click

*/
import React,{ useContext, useEffect, useState } from "react";
import { hexToRgbA } from "../Theme";
import { ThemeContext } from "../ThemeProvider";
import { functionTypes, nameTypes, fileTypes, themeProviderCheck } from "../ErrorHandler";

// TODO: type handler eksik
function File({ type, name, onClick}) {

    const theme = useContext(ThemeContext);


    const [fileTypeColor, setFileTypeColor] = useState(theme["alert"])
    const [fileName, setFileName] = useState("XXX")
    const [mouseIn, setMouseIn] = useState(false)

    const types = {
        "pdf": {
            name: "PDF",
            color: theme["alert"]
        },
        "excel": {name:"XLS", color:theme["success"]},
        "word": {name:"WORD", color:theme["primary"]},
        "powerpoint": {name:"PPT",color:theme["alert"]}
    }

    useEffect(() => {

        functionTypes.check(onClick)
        nameTypes.check(name)
        fileTypes.check(type)
        themeProviderCheck.check(theme)

    }, [onClick, name, type, theme])

    useEffect(() => {

        if(type !== undefined){
            setFileTypeColor(types[type].color)
            setFileName(types[type].name)
        }

    }, [theme])




    return (

        <div onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)} onClick={onClick!==undefined?()=>onClick():null} style={{ boxShadow: mouseIn ? ("0px 0px 0px 3px " + hexToRgbA(fileTypeColor)) : "none", display:"inline-block", padding: "5px", margin: '10px', width: '200px', cursor:"pointer", height: 'auto', backgroundColor: theme["topMenu"], borderRadius: '10px', border: "1px solid "+theme["gray"] }}>
            <div style={{ position: "relative", display:"flex", alignItems:"center", justifyContent:"center" }}>
                <svg width="100" height="100" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.75 11.75C33.75 12.0261 33.5261 12.25 33.25 12.25H17C16.4477 12.25 16 12.6977 16 13.25V46.75C16 47.3023 16.4477 47.75 17 47.75H43C43.5523 47.75 44 47.3023 44 46.75V23C44 22.7239 44.2239 22.5 44.5 22.5C44.7761 22.5 45 22.7239 45 23V47.75C45 48.3023 44.5523 48.75 44 48.75H16C15.4477 48.75 15 48.3023 15 47.75V12.25C15 11.6977 15.4477 11.25 16 11.25H33.25C33.5261 11.25 33.75 11.4739 33.75 11.75Z" fill={theme["text"]} />
                    <rect x="18.75" y="26.25" width="16.875" height="1.875" fill={theme["text"]} />
                    <rect x="18.75" y="39.375" width="9.375" height="1.875" fill={theme["text"]} />
                    <rect x="18.75" y="33.75" width="20.625" height="1.875" fill={theme["text"]} />
                </svg>
                <p style={{ minWidth: '40px', padding:"2px",fontSize:"13px", height: 'auto', textAlign: 'center', color: theme["text"], borderRadius: '5px', position: 'absolute', right: '55px', top: "0px",backgroundColor:fileTypeColor }}>{fileName}</p>
            </div>
            <h4 style={{ color: theme["text"], width: '100%', position: 'relative', margin: "0px", fontWeight: "300", textAlign:"center",fontSize:"14px" }}>{name}</h4>

        </div>
    )



}
export default File;