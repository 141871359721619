/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************

color ---> primary || secondary || success || info || warning || alert || dark || darkgray || gray(default)

checked ---> true || false

onClick ---> callback function called on click

style ---> It works like style attribute

[EXAMPLE]

style :{
    color:"red", backgroundColor:"black",
}

*/
import React, { useState, useEffect, useContext } from "react";
import { hexToRgbA } from "../Theme";
import { ThemeContext } from "../ThemeProvider";
import { colorTypes, functionTypes, checkedTypes, disabledTypes, themeProviderCheck} from "../ErrorHandler";


function Like({color, checked, onClick, disabled}) {
  const theme = useContext(ThemeContext);

  const [localColor, setLocalColor] = useState("#333")
  const [mouseIn, setMouseIn] = useState(false)

  const [localChecked, setLocalChecked] = useState(checked)


  useEffect(()=>{

    colorTypes.check(color)
    functionTypes.check(onClick)
    checkedTypes.check(checked)
    disabledTypes.check(disabled)
    themeProviderCheck.check(theme)

  },[color, checked, onClick, disabled, theme])


  useEffect(() => {

    if ( color !== undefined) {
      setLocalColor(theme[color])
    }


  }, [theme,color])


  useEffect(() => {
    if ( onClick !== undefined) {
       onClick(localChecked)
    }


  }, [localChecked])

  useEffect(() => {
    setLocalChecked( checked)
  }, [ checked])


  return (
    <span onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)} onClick={(e) => {
      if(!disabled){
        e.preventDefault(); setLocalChecked(!localChecked); e.stopPropagation();
      }
        

    }}
      style={{ display:"inline-block",cursor:"pointer", boxShadow: mouseIn ? ("0px 0px 0px 3px " + hexToRgbA(localColor)) : "none", transition: "0.2s", transform: localChecked ? "scale(1.1)" : "scale(0.9)", width: "25px", height: "25px", borderRadius: "25px", padding: "5px", margin: "5px"}}>

      <svg width="25" height="25" viewBox="0 0 50 50" fill="black" xmlns="http://www.w3.org/2000/svg">
        {
          !localChecked &&
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.65182 15.4959L5.63647 15.4778L5.62027 15.4604C4.39238 14.1432 3.62741 12.3277 3.62741 10.3125C3.62741 6.22229 6.75571 3 10.5024 3C12.6401 3 14.5635 4.03867 15.8338 5.69573L16.6274 6.73097L17.421 5.69573C18.6914 4.03867 20.6148 3 22.7524 3C26.4991 3 29.6274 6.22229 29.6274 10.3125C29.6274 12.0793 29.0394 13.6925 28.067 14.9511L27.8864 15.1848L16.628 28.4536L5.65182 15.4959ZM28.8583 15.585L28.8698 15.5714L28.8774 15.5625H28.8583L28.8659 15.5527L28.8774 15.5377C29.1133 15.23 29.3288 14.9042 29.5217 14.5625C30.224 13.3189 30.6274 11.8654 30.6274 10.3125C30.6274 5.72163 27.1017 2 22.7524 2C20.6341 2 18.7111 2.88288 17.2957 4.31905C17.0581 4.56008 16.8349 4.81669 16.6274 5.08733C16.4199 4.81669 16.1967 4.56008 15.9592 4.31905C14.5437 2.88288 12.6208 2 10.5024 2C6.15317 2 2.62741 5.72163 2.62741 10.3125C2.62741 12.5836 3.49025 14.642 4.88878 16.1423L15.9722 29.2265L16.6274 30L17.2832 29.2271L28.0289 16.5625L28.8583 15.585Z" fill={localColor} />
        }
        {
          localChecked && 
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6083 15.5625C16.6147 15.5542 16.6211 15.546 16.6274 15.5377C16.6338 15.546 16.6401 15.5542 16.6465 15.5625H16.6083ZM4.88878 16.1423C3.49025 14.6419 2.62741 12.5836 2.62741 10.3125C2.62741 5.72163 6.15317 2 10.5024 2C12.9763 2 15.1837 3.20408 16.6274 5.08733C18.0711 3.20408 20.2786 2 22.7524 2C27.1017 2 30.6274 5.72163 30.6274 10.3125C30.6274 12.3036 29.9642 14.1311 28.8583 15.5625H28.8774L16.6274 30L4.88878 16.1423Z" fill={localColor} />
        }
      </svg>

    </span>
  );


}

export default Like;
