import React, { useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import DrawerComponent from '../../Components/Drawer';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";
import Button from "../../Components/Button";
import Notification from "../../Components/Notification";


function Drawer() {

    const [leftDrawer, setLeftDrawer] = useState(false)
    const [rightDrawer, setRightDrawer] = useState(false)

    const [leftDrawer1, setLeftDrawer1] = useState(false)
    const [rightDrawer1, setRightDrawer1] = useState(false)

    const [leftDrawer2, setLeftDrawer2] = useState(false)
    const [rightDrawer2, setRightDrawer2] = useState(false)
    
    const [leftDrawer3, setLeftDrawer3] = useState(false)
    const [rightDrawer3, setRightDrawer3] = useState(false)
  
    return (
        <Grid>
            <Header>
                Drawer
            </Header>
            <Description>
            The application also provides access to the destinations. These are surfaces that display content that is fixed to the left or right edge of the screen. They appear as open or closed menus.
            </Description>

            <Subheader>
            Position
            </Subheader>
            <Description>
            You can decide in which direction the drawers will open. It can take "LEFT" and "RIGHT" values.
            </Description>
            <Showroom>
            <div style={{ margin: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button type='filled' color='secondary' onClick={() => setLeftDrawer(!leftDrawer)} size={"sm"} style={{marginRight:"20px"}}>Left</Button>
                <Button type='filled' color='secondary' onClick={() => setRightDrawer(!rightDrawer)} size={"sm"} >Right</Button>
            </div>
            <DrawerComponent open={rightDrawer} onClose={setRightDrawer} position="RIGHT" color="light" width={400}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <h1>Bildirimler</h1>
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="alert" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="success" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="info" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="warning" date={"11/09/2022"} />

                </div>
            </DrawerComponent >
            <DrawerComponent open={leftDrawer} onClose={setLeftDrawer} position="LEFT" color="light" width={400}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <h1>Bildirimler</h1>
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="alert" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="success" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="info" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="warning" date={"11/09/2022"} />

                </div>
            </DrawerComponent >
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Button onClick={() => setLeftDrawer(!leftDrawer)} />\n"+
                    "\t\t<Button onClick={() => setRightDrawer(!rightDrawer)} />\n"+
                    "\t\t<Drawer open={rightDrawer} onClose={setRightDrawer} position='RIGHT'}>\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t</Drawer>\n"+
                    "\t\t<Drawer open={leftDrawer} onClose={setLeftDrawer} position='LEFT' >\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t</Drawer>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                Color
            </Subheader>
            <Description>
            With the value you give to "color" props, you can specify both the color of your buttons and the background color of the drawers.
            </Description>
            <Showroom>
            <div style={{ margin: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button type='filled' color='primary' onClick={() => setLeftDrawer1(!leftDrawer1)} size={"sm"} style={{marginRight:"20px"}}>Left</Button>
                <Button type='filled' color='primary' onClick={() => setRightDrawer1(!rightDrawer1)} size={"sm"} >Right</Button>
            </div>
            <DrawerComponent open={rightDrawer1} onClose={setRightDrawer1} position="RIGHT" color="light" width={400}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <h1>Bildirimler</h1>
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="alert" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="success" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="info" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="warning" date={"11/09/2022"} />

                </div>
            </DrawerComponent >
            <DrawerComponent open={leftDrawer1} onClose={setLeftDrawer1} position="LEFT" color="dark" width={400}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <h1>Bildirimler</h1>
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="alert" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="success" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="info" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="warning" date={"11/09/2022"} />

                </div>
            </DrawerComponent>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Button onClick={() => setLeftDrawer(!leftDrawer)} color='primary'/>\n"+
                    "\t\t<Button onClick={() => setRightDrawer(!rightDrawer)} color='primary'/>\n"+
                    "\t\t<Drawer open={rightDrawer} onClose={setRightDrawer} position='RIGHT' color='light'>\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t</Drawer>\n"+
                    "\t\t<Drawer open={leftDrawer} onClose={setLeftDrawer} position='LEFT' color='dark'>\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t</Drawer>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                Width
            </Subheader>
            <Description>
            You can decide how much space the drawers will take up on the screen when it opens.
            </Description>
            <Showroom>
            <div style={{ margin: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button type='filled' color='secondary' onClick={() => setLeftDrawer2(!leftDrawer2)} size={"sm"} style={{marginRight:"20px"}}>Left</Button>
                <Button type='filled' color='secondary' onClick={() => setRightDrawer2(!rightDrawer2)} size={"sm"} >Right</Button>
            </div>
            <DrawerComponent open={rightDrawer2} onClose={setRightDrawer2} position="RIGHT" color="light" width={400}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <h1>Bildirimler</h1>
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="alert" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="success" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="info" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="warning" date={"11/09/2022"} />

                </div>
            </DrawerComponent >
            <DrawerComponent open={leftDrawer2} onClose={setLeftDrawer2} position="LEFT" color="light" width={400}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <h1>Bildirimler</h1>
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="alert" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="success" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="info" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="warning" date={"11/09/2022"} />

                </div>
            </DrawerComponent >
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Button onClick={() => setLeftDrawer(!leftDrawer)} color='secondary'/>\n"+
                    "\t\t<Button onClick={() => setRightDrawer(!rightDrawer)} color='secondary'/>\n"+
                    "\t\t<Drawer open={rightDrawer} onClose={setRightDrawer} position='RIGHT' color='light' width={400}>\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t</Drawer>\n"+
                    "\t\t<Drawer open={leftDrawer} onClose={setLeftDrawer} position='LEFT' color='light' width={400}>\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t</Drawer>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
            onClose
            </Subheader>
            <Description>
            Call back function which triggers when clicked on darkening layer.
            </Description>
            <Subheader>
                Open
            </Subheader>
            <Description>
            Determines the initial position of the drawers. It decides whether it should be in the open position or in the closed position. If it is "false", the drawer will be opened after clicking the button. It turns off at the next click. The default is off (false). 
            </Description>
            <Showroom>
            <div style={{ margin: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button type='filled' color='secondary' onClick={() => setLeftDrawer3(!leftDrawer3)} size={"sm"} style={{marginRight:"20px"}}>Left</Button>
                <Button type='filled' color='secondary' onClick={() => setRightDrawer3(!rightDrawer3)} size={"sm"} >Right</Button>
            </div>
            <DrawerComponent open={rightDrawer3} onClose={setRightDrawer3} position="RIGHT" color="light" width={400}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <h1>Bildirimler</h1>
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="alert" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="success" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="info" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="warning" date={"11/09/2022"} />

                </div>
            </DrawerComponent >
            <DrawerComponent open={leftDrawer3} onClose={setLeftDrawer3} position="LEFT" color="light" width={400}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <h1>Bildirimler</h1>
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="alert" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="success" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="info" date={"11/09/2022"} />
                    <Notification header={"Software Requirement Document"} content={"The document will be checked and typos will be corrected."} detail={"The last two days"} color="warning" date={"11/09/2022"} />

                </div>
            </DrawerComponent>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Button onClick={() => setLeftDrawer(!leftDrawer)} color='secondary'/>\n"+
                    "\t\t<Button onClick={() => setRightDrawer(!rightDrawer)} color='secondary'/>\n"+
                    "\t\t<Drawer open={rightDrawer} onClose={setRightDrawer} position='RIGHT' color='light' width={400}>\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t</Drawer>\n"+
                    "\t\t<Drawer open={leftDrawer} onClose={setLeftDrawer} position='LEFT' color='light' width={400}>\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t\t<Notification />\n"+
                    "\t\t</Drawer>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>Props</Subheader>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\nlight", 
                            default: " ", 
                            description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                        },
                        {
                            name:"onClose",
                            type:"Function",
                            default:"undefined",
                            description:"Call back function which triggers when clicked on darkening layer."
                        },
                        {
                            name:"width",
                            type:"int",
                            default:" ",
                            description:"Determines the size of the drawer."
                        },
                        {
                            name:"position",
                            type:"RIGHT\nLEFT",
                            default:"RIGHT",
                            description:"Determines which way the drawer will open."
                        },
                        {
                            name:"open",
                            type:"true\nfalse",
                            default:"false",
                            description:"In the initial position, it determines the open or closed position of the menu."
                        }
                      ]}  
                        
                    />
                </div>
            </div>       
        </Grid>
    );
}

export default Drawer;
