import React, { useContext } from "react";
import { ThemeContext } from "../../Components/ThemeProvider";

function Showroom(props) {

    const theme = useContext(ThemeContext);

    return (
        <div style={{ display:"flex", alignItems:"center",justifyContent:"center",flexDirection:"column", border:"1px solid "+theme["gray"], padding:"20px", borderRadius:"10px"}}>
            {
                props.children
            }
        </div>
    )
}
export default Showroom;