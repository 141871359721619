



export var selectSize = {
    "sm": { width: "150", height: "40", fontSize: "16" },
    "md": { width: "300", height: "50", fontSize: "18" },
    "lg": { width: "550", height: "50", fontSize: "20" },
}

export var avatarSize = {
    "sm": { width: "40", height: "40" },
    "md": { width: "60", height: "60" },
    "lg": { width: "80", height: "80" },
}

export var spinnerSize = {
    "sm": { width: "15", height: "15" },
    "md": { width: "30", height: "30" },
    "lg": { width: "60", height: "60" },
}

export var inputSize = {
    "sm": { width: "150", height: "45", fontSize: "15" },
    "md": { width: "300", height: "70", fontSize: "17" },
    "lg": { width: "550", height: "70", fontSize: "20" },
}

export var sliderSize = {
    "sm": { width: "100", height: "50", fontSize: "15" },
    "md": { width: "300", height: "50", fontSize: "17" },
    "lg": { width: "500", height: "50", fontSize: "20" },
}

export var progressSize = {
    "sm": { width: "200", height: "20", fontSize: "14" },
    "md": { width: "300", height: "30", fontSize: "16" },
    "lg": { width: "550", height: "50", fontSize: "20" },
}

export var buttonSizes = {
    "sm": { width: '100', height: '40', fontSize: "14" },
    "md": { width: '125', height: '50', fontSize: "16" },
    "lg": { width: '200', height: '60', fontSize: "16" },
}

export var chipSizes = {
    "sm": { width: '100', height: '40', fontSize: "14" },
    "md": { width: '125', height: '50', fontSize: "16" },
    "lg": { width: '150', height: '60', fontSize: "20" },
}

export var modalSizes = {
    "sm": { width: '400', height: '300'},
    "md": { width: '600', height: '400' },
    "lg": { width: '800', height: '600'},
}

export var calendarSizes = {
    "sm": {
        monthWidth: 280,
        dayWidth: 28,
        padding: 5,
        fontSize: 18
    },
    "md": {
        monthWidth: 550,
        dayWidth: 56,
        padding: 10,
        fontSize: 20
    },
    "lg": {
        monthWidth: 1000,
        dayWidth: 100,
        padding: 20,
        fontSize: 24
    }
}

export var yearRange = [1900, 2200]



export const injectStyle = (style) => {
    const styleElement = document.createElement('style');
    let styleSheet = null;

    document.head.appendChild(styleElement);

    styleSheet = styleElement.sheet;

    styleSheet.insertRule(style, styleSheet.cssRules.length);
};


export function hexToRgbA(hex) {


    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.2)';
    }
    else {
        return hex
    }



}