
import React, { useContext, useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import CardComponent from '../../Components/Card';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";
import Avatar from "../../Components/Avatar";
import Like from "../../Components/Like";
import { ThemeContext } from "../../Components/ThemeProvider";


function Card() {


  const theme = useContext(ThemeContext);

  const [checked, setChecked] = useState(true)

  return (
    <Grid>
      <Header>
        Card
      </Header>
      <Description>
        Cards contain information about a specific topic. They are inclusive content. Images can have features such as footers, headers, etc. It can take properties like "shadow", "animation", "header", "content" and "options" with props.
      </Description>
      <Subheader>
        Shadow
      </Subheader>
      <Description>
        If 'shadow' is true, it allows you to add a shadow around the card.
      </Description>
      <Showroom>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CardComponent shadow={true}
            header=
            {
              <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <Avatar shape="square" color="secondary" size="sm" src="avatar1.jpg" active={checked} /><h1 style={{ fontSize: "22px", textAlign: "start", margin: "10px", color: theme["dark"], fontWeight: "normal", marginLeft: "-5px" }}>Card Header</h1>
              </div>
            }
            content=
            {
              <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
                <img src='avatar1.jpg' width={"100%"} />
                <p style={{ textAlign: "justify", padding: "10px", lineHeight: "30px", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            }
            actions=
            {
              <div style={{ display: "flex", padding: "5px", justifyContent: "flex-end", height: "auto" }}>
                <Like checked={checked} onClick={setChecked} color="secondary" />
              </div>
            } />

          <CardComponent shadow={false} header=
            {
              <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <Avatar shape="square" color="secondary" size="sm" src="avatar1.jpg" active={checked} /><h1 style={{ fontSize: "22px", textAlign: "start", margin: "10px", color: theme["dark"], fontWeight: "normal", marginLeft: "-5px" }}>Card Header</h1>
              </div>
            }
            content=
            {
              <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
                <img src='avatar1.jpg' width={"100%"} />
                <p style={{ textAlign: "justify", padding: "10px", lineHeight: "30px", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            }
            actions=
            {
              <div style={{ display: "flex", padding: "5px", justifyContent: "flex-end", height: "auto" }}>
                <Like checked={checked} onClick={setChecked} color="secondary" />
              </div>
            } />
        </div>

      </Showroom>
      <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Card shadow={true}/>\n" +
          "\t\t<Card shadow={false}/>\n" +
          "\t)\n" +
          "}"
        }
      </Coderoom>

      <Subheader>
        Animation
      </Subheader>
      <Description>
        If the value is 'true', it adds animation to the card component.
      </Description>
      <Showroom>

        <div style={{ display: "flex", alignItems: "center" }}>
          <CardComponent shadow={true} animation={true}
            header=
            {
              <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <Avatar shape="square" color="secondary" size="sm" src="avatar1.jpg" active={checked} /><h1 style={{ fontSize: "22px", textAlign: "start", margin: "10px", color: theme["dark"], fontWeight: "normal", marginLeft: "-5px" }}>Card Header</h1>
              </div>
            }
            content=
            {
              <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
                <img src='avatar1.jpg' width={"100%"} />
                <p style={{ textAlign: "justify", padding: "10px", lineHeight: "30px", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            }
            actions=
            {
              <div style={{ display: "flex", padding: "5px", justifyContent: "flex-end", height: "auto" }}>
                <Like checked={checked} onClick={setChecked} color="secondary" />
              </div>
            } />

          <CardComponent shadow={true} animation={false}
            header=
            {
              <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <Avatar shape="square" color="secondary" size="sm" src="avatar1.jpg" active={checked} /><h1 style={{ fontSize: "22px", textAlign: "start", margin: "10px", color: theme["dark"], fontWeight: "normal", marginLeft: "-5px" }}>Card Header</h1>
              </div>
            }
            content=
            {
              <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
                <img src='avatar1.jpg' width={"100%"} />
                <p style={{ textAlign: "justify", padding: "10px", lineHeight: "30px", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            }
            actions=
            {
              <div style={{ display: "flex", padding: "5px", justifyContent: "flex-end", height: "auto" }}>
                <Like checked={checked} onClick={setChecked} color="secondary" />
              </div>
            } />
        </div>

      </Showroom>

      <Coderoom>
        {

          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Card shadow={true} animation={true}/>\n" +
          "\t\t<Card shadow={true} animation={false}/>\n" +
          "\t)\n" +
          "}"
        }
      </Coderoom>

      <Subheader>
        Header
      </Subheader>
      <Description>
        Allows you to add a component header.
      </Description>
      <Showroom>
        <CardComponent shadow={true} animation={true}
          header=
          {
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
              <Avatar shape="square" color="secondary" size="sm" src="avatar1.jpg" active={checked} /><h1 style={{ fontSize: "22px", textAlign: "start", margin: "10px", color: theme["dark"], fontWeight: "normal", marginLeft: "-5px" }}>Card Header</h1>
            </div>
          }
          content=
          {
            <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
              <img src='avatar1.jpg' width={"100%"} />
              <p style={{ textAlign: "justify", padding: "10px", lineHeight: "30px", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>
          }
          actions=
          {
            <div style={{ display: "flex", padding: "5px", justifyContent: "flex-end", height: "auto" }}>
              <Like checked={checked} onClick={setChecked} color="secondary" />
            </div>
          } />
      </Showroom>
      <Coderoom>
        {

          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Card shadow={true} animation={true} \n" +
          "\t\t\theader=\n" +
          "\t\t\t\t{\n" +
          "\t\t\t\t\t<div style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>\n" +
          "\t\t\t\t\t\t<Avatar shape='square' color='secondary' size='sm' src='avatar1.jpg' active={checked}/>\n" +
          "\t\t\t\t\t\t<h1 style={{ fontSize: '22px', textAlign: 'start', margin: '10px', color: theme['dark'], fontWeight: 'normal', marginLeft: '-5px' }}>Card Header</h1>\n" +
          "\t\t\t\t\t</div>\n" +
          "\t\t\t\t}\n" +
          "\t\t/>\n" +
          "\t)\n" +
          "}"
        }
      </Coderoom>
      <Subheader>
        Content
      </Subheader>
      <Description>
        It allows you to enrich your cards with content such as description text and photos.
      </Description>
      <Showroom>
        <CardComponent shadow={true} animation={true}
          header=
          {
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
              <Avatar shape="square" color="secondary" size="sm" src="avatar1.jpg" active={checked} /><h1 style={{ fontSize: "22px", textAlign: "start", margin: "10px", color: theme["dark"], fontWeight: "normal", marginLeft: "-5px" }}>Card Header</h1>
            </div>
          }
          content=
          {
            <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
              <img src='avatar1.jpg' width={"100%"} />
              <p style={{ textAlign: "justify", padding: "10px", lineHeight: "30px", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>
          }
          actions=
          {
            <div style={{ display: "flex", padding: "5px", justifyContent: "flex-end", height: "auto" }}>
              <Like checked={checked} onClick={setChecked} color="secondary" />
            </div>
          } />
      </Showroom>
      <Coderoom>
      { 
          "export default function Example(){\n"+
          "\treturn(\n"+
          "\t\t<Card shadow={true} animation={true} \n"+
          "\t\t\theader=\n"+
          "\t\t\t\t{\n"+
          "\t\t\t\t\t<div style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>\n" +
          "\t\t\t\t\t\t<Avatar shape='square' color='secondary' size='sm' src='avatar1.jpg' active={checked}/>\n" +
          "\t\t\t\t\t\t<h1 style={{ fontSize: '22px', textAlign: 'start', margin: '10px', color: theme['dark'], fontWeight: 'normal', marginLeft: '-5px' }}>Card Header</h1>\n" +
          "\t\t\t\t\t</div>\n" +
          "\t\t\t\t}\n"+
          "\t\t\tcontent=\n"+
          "\t\t\t\t{\n"+
          "\t\t\t\t\t<div style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>\n"+
          "\t\t\t\t\t\t<img src='Avatar.jpg'/>\n"+
          "\t\t\t\t\t\t<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<p/>\n"+
          "\t\t\t\t\t</div>\n"+
          "\t\t\t\t}\n"+
          "\t\t/>\n"+
          "\t)\n"+
          "}"
        }
      </Coderoom>

      <Subheader>
        Actions
      </Subheader>
      <Description>
        Determines how the process will proceed when you add to favorites. such as adding the online tag to the avatar.
      </Description>
      <Showroom>
        <CardComponent shadow={true} animation={true}
          header=
          {
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
              <Avatar shape="square" color="secondary" size="sm" src="avatar1.jpg" active={checked} /><h1 style={{ fontSize: "22px", textAlign: "start", margin: "10px", color: theme["dark"], fontWeight: "normal", marginLeft: "-5px" }}>Card Header</h1>
            </div>
          }
          content=
          {
            <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
              <img src='avatar1.jpg' width={"100%"} />
              <p style={{ textAlign: "justify", padding: "10px", lineHeight: "30px", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>
          }
          actions=
          {
            <div style={{ display: "flex", padding: "5px", justifyContent: "flex-end", height: "auto" }}>
              <Like checked={checked} onClick={setChecked} color="secondary" />
            </div>
          } />
      </Showroom>
      <Coderoom>
      { 
          "export default function Example(){\n"+
          "\treturn(\n"+
          "\t\t<Card shadow={true} animation={true} \n"+
          "\t\t\theader=\n"+
          "\t\t\t\t{\n"+
          "\t\t\t\t\t<div style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>\n" +
          "\t\t\t\t\t\t<Avatar shape='square' color='secondary' size='sm' src='avatar1.jpg' active={checked}/>\n" +
          "\t\t\t\t\t\t<h1 style={{ fontSize: '22px', textAlign: 'start', margin: '10px', color: theme['dark'], fontWeight: 'normal', marginLeft: '-5px' }}>Card Header</h1>\n" +
          "\t\t\t\t\t</div>\n" +
          "\t\t\t\t}\n"+
          "\t\t\tcontent=\n"+
          "\t\t\t\t{\n"+
          "\t\t\t\t\t<div style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>\n"+
          "\t\t\t\t\t\t<img src='Avatar.jpg'/>\n"+
          "\t\t\t\t\t\t<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<p/>\n"+
          "\t\t\t\t\t</div>\n"+
          "\t\t\t\t}\n"+
          "\t\t\tactions=\n"+
          "\t\t\t\t{\n"+
          "\t\t\t\t\t<div style={{ display: 'flex', padding: '5px', justifyContent: 'flex-end', height: 'auto' }}>\n"+
          "\t\t\t\t\t\t<Like  checked={checked} onClick={setChecked} color='secondary'/>\n"+
          "\t\t\t\t\t</div>\n"+
          "\t\t\t\t}\n"+
          "\t\t/>\n"+
          "\t)\n"+
          "}"
        }
      </Coderoom>
      <Subheader>Props</Subheader>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div style={{ width: "100%" }}>
          <Table
            columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
            rows=
            {[
              {
                name: "shadow",
                type: "true\nfalse",
                default: "false",
                description: "If 'shadow' is true, it allows you to add a shadow around the card."
              },
              {
                name: "animation",
                type: "true\nfalse",
                default: "false",
                description: "If the value is 'true', it adds animation to the card component."
              },
              {
                name: "content",
                type: "component",
                default: "undefined",
                description: " It allows you to enrich your cards with content such as description text and photos."
              },
              {
                name: "actions",
                type: "component",
                default: "undefined",
                description: "Determines how the process will proceed when you add to favorites."
              },
              {
                name: "header",
                type: "component",
                default: "undefined",
                description: "Allows you to add a component header."
              }
            ]}

          />
        </div>
      </div>
    </Grid>
  );
}

export default Card;
