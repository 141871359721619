import React, { useContext, useState } from "react";
import { ThemeContext } from "../../Components/ThemeProvider";

function Table(props) {

  const theme = useContext(ThemeContext);
  const [clickedItem,setClickedItem] = useState(0)

  return (
    <div style={{ width: "100%", height: "auto", marginTop:"100px", marginBottom:"100px"}}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom:"1px solid "+theme["darkgray"]  }}>
        {
          props.columns !== undefined &&
          props.columns.map((column, index) => {
            return (
              <span style={{ width:(100/props.columns.length)+"%",overflow:"hidden", height:"50px", display:"flex",alignItems:"center", fontWeight:"bold", padding:"5px", color: (theme==="dark"?theme["light"]:theme["dark"])}}>{column}</span>
            )
          })
        }
      </div>
      <div>
        {
          props.rows !== undefined &&
          props.rows.map((row, index) => {
            return (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom:"1px dashed "+theme["gray"], padding:"10px" }}>
                {
                  Object.keys(row).map((key, keyIndex) => {
                    return (
                      <span style={{width:(100/props.columns.length)+"%", overflow:"hidden", minHeight:"50px", display:"flex", alignItems:"center",whiteSpace:"pre-line" ,color:(theme==="dark"?theme["light"]:theme["dark"])}}>
                        {
                          row[key]
                        }
                      </span>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>

    </div>
  );
}

export default Table;
