import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../Components/ThemeProvider';
import Icon from '../../Components/Icon';
import Pagination from '../../Components/Pagination';
import File from '../../Components/File';




function Files() {




    const theme = useContext(ThemeContext);




    return (
        <div className='container-fluid'>
            <div className='row justify-content-center' style={{ marginTop: "20px" }}>
                <div className='col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10' style={{ textAlign: "center", marginTop: "10px" }}>
                    <h1 style={{ textAlign: "start", fontSize: "26px", marginBottom: "10px", marginTop: "10px", color: theme["text"] }}>Files</h1>
                </div>
            </div>
            <div className='row justify-content-center' style={{ marginTop: "20px" }}>
                <div className='col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10' style={{ textAlign: "center", marginTop: "10px" }}>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-lg-8' style={{marginTop:"20px"}}>
                                <div style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "10px", backgroundColor: theme["topMenu"] }}>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-lg-4'>
                                                <File type='powerpoint' name='EXAMPLE-POWERPOINT.ppt' />
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-lg-4'>
                                                <File type='excel' name='EXAMPLE-POWERPOINT.ppt' />

                                            </div>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-lg-4'>
                                                <File type='word' name='EXAMPLE-POWERPOINT.ppt' />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-lg-4'>
                                                <File type='powerpoint' name='EXAMPLE-POWERPOINT.ppt' />
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-lg-4'>
                                                <File type='excel' name='EXAMPLE-POWERPOINT.ppt' />

                                            </div>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-lg-4'>
                                                <File type='word' name='EXAMPLE-POWERPOINT.ppt' />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-lg-4'>
                                                <File type='powerpoint' name='EXAMPLE-POWERPOINT.ppt' />
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-lg-4'>
                                                <File type='excel' name='EXAMPLE-POWERPOINT.ppt' />

                                            </div>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-lg-4'>
                                                <File type='word' name='EXAMPLE-POWERPOINT.ppt' />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Pagination total={100} color={'primary'} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-lg-4' style={{marginTop:"20px"}}>
                                <div style={{ width: "100%", border: "1px solid red", backgroundColor: theme["topMenu"], height: "100%", borderRadius: "10px", border: "1px dashed #ccc", display: "flex", alignItems: "center", justifyContent: "center",height:"400px" }}>
                                    <div>
                                        <Icon name='vo-upload-file' type='outlined' color='primary' />
                                        <span style={{ color: theme["primary"], cursor: "pointer" }}>Click Here to Upload Files</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Files;
