import React, { useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import SwitchComponent from '../../Components/Switch';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Avatar from "../../Components/Avatar";
import Table from "../../Shared/Table";

function Switch() {

    const [checked, setChecked]=useState(false)

    return(
        <Grid>
        <Header>
            Switch
        </Header>
        <Description>
        
        </Description>
        <Showroom>
            <SwitchComponent></SwitchComponent>
        </Showroom>

        <Subheader>
            Color
        </Subheader>
        <Description>
            You can color the switches using the theme palette.
        </Description>
        <Showroom> 
            <div style={{display:"flex",alignItems:"center"}}>
            <SwitchComponent color="primary" checked={true}></SwitchComponent>
            <SwitchComponent color="secondary" checked={true}></SwitchComponent>
            <SwitchComponent color="success" checked={true}></SwitchComponent>
            <SwitchComponent color="info" checked={true}></SwitchComponent>
            <SwitchComponent color="alert" checked={true}></SwitchComponent>
            </div>
        </Showroom>
        <Coderoom>
            {
                 "export default function Example(){\n"+
                 "\treturn(\n"+
                 "\t\t<Switch color='primary' checked={true}/>\n"+
                 "\t\t<Switch color='secondary' checked={true}/>\n"+
                 "\t\t<Switch color='success' checked={true}/>\n"+
                 "\t\t<Switch color='info' checked={true}/>\n"+
                 "\t\t<Switch color='alert' checked={true}/>\n"+
                 "\t)\n"+
                 "}"
            }
        </Coderoom>

        <Subheader>
            Type
        </Subheader>
        <Description>
            You can specify the type of switches. Small switch is set at "basic" and large switch is set at "other". "other" in default state.
        </Description>
        <Showroom>
        <div style={{display:"flex",alignItems:"center"}}>
        <SwitchComponent color="secondary" checked={true} type="basic"></SwitchComponent>
        <SwitchComponent color="secondary" checked={true} type="normal"></SwitchComponent>
        </div>
        </Showroom>
        <Coderoom>
            {
                "export default function Example(){\n"+
                "\treturn(\n"+
                "\t\t<Switch color='secondary' checked={true} type='basic'/>\n"+
                "\t\t<Switch color='secondary' checked={true} type='normal'/>\n"+
                "\t)\n"+
                "}"
            }

        </Coderoom>

        <Subheader>
            Checked
        </Subheader>
        <Description>
            If the checked value is "true", the switch is open or if the checked value is "false", the switch is closed. "false" in default state.
        </Description>
        <Showroom>
        <div style={{display:"flex",alignItems:"center"}}>
        <SwitchComponent color="secondary" checked={false}></SwitchComponent>
        <SwitchComponent color="secondary" checked={true}></SwitchComponent>
        </div>
        </Showroom>
        <Coderoom>
            {
                "export default function Example(){\n"+
                "\treturn(\n"+
                "\t\t<Switch color='secondary' checked={false} />\n"+
                "\t\t<Switch color='secondary' checked={true} />\n"+
                "\t)\n"+
                "}"
            }
        </Coderoom>

        <Subheader>
            Disabled
        </Subheader>
        <Description>
            If the disabled value is "true", the click option will not work on the switch. "false" in default state.
        </Description>
        <Showroom>
        <div style={{display:"flex",alignItems:"center"}}>
        <SwitchComponent color="secondary" disabled={false}></SwitchComponent>
        <SwitchComponent color="secondary" disabled={true}></SwitchComponent>
        </div>
        </Showroom>
        <Coderoom>
        {
                "export default function Example(){\n"+
                "\treturn(\n"+
                "\t\t<Switch color='secondary' disabled={false} />\n"+
                "\t\t<Switch color='secondary' disabled={true} />\n"+
                "\t)\n"+
                "}"
            }
        </Coderoom>

        <Subheader>
            onChange
        </Subheader>
        <Description>
        With the onChange option, you determine how the function will react after you click the switch.
        </Description>
        <Showroom>
        <div style={{display:"flex",alignItems:"center"}}>
        <Avatar shape="square" color="secondary" size="sm" src="avatar1.jpg" active={checked} />
        <SwitchComponent color="secondary" onChange={setChecked} checked={true}></SwitchComponent>
        
        </div>
        </Showroom>
        <Coderoom>
        {
                "export default function Example(){\n"+
                "\treturn(\n"+
                "\t\t<Switch color='secondary' onChange={setChecked} />\n"+
                "\t\t<Avatar shape='square' color='secondary' active={checked} />\n"+
                "\t)\n"+
                "}"
            }
        </Coderoom>
        <Subheader>Props</Subheader>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                            {
                                name: "color",
                                type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                                default: "", 
                                description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                            },
                            {
                                name:"type",
                                type:"basic\nnormal",
                                default:"normal",
                                description:"You can specify the type of switches. Small switch is set at 'basic' and large switch is set at 'other'."
                            },
                            {
                                name: "disabled",
                                type: "{true}\n{false}", 
                                default: "{false}", 
                                description: "If the disabled value is 'true', the click option will not work on the switch." 
                            },
                            {
                                name: "checked",
                                type: "{true}\n{false}", 
                                default: "{false}", 
                                description: "It determines the on or off state of the switches." 
                            },
                            {
                                name: "onChange",
                                type: "function", 
                                default: "undefined", 
                                description: "With the onChange option, you determine how the function will react after you click the switch." 
                            },

                      ]}  
                        
                    />
                </div>
            </div>
        
        </Grid>
    )
}

export default Switch;
