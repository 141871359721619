import React, { useContext, useEffect, useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import IconComponent from "../../Components/Icon";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import Option from "../../Components/Option";
import { ThemeContext } from "../../Components/ThemeProvider";
import Modal from "../../Components/Modal";
import Button from "../../Components/Button";
import Table from "../../Shared/Table";

function Icon() {

    const [search, setSearch] = useState("")
    const [iconType, setIconType] = useState("filled")
    const [color, setColor] = useState("secondary")
    const [result, setResult] = useState([])
    const [select, setSelect] = useState(0)
    const [type, setType] = useState(0)
    const theme = useContext(ThemeContext);
    const [selected, setSelected] = useState("")
    const [modal, setModal] = useState(false)
    const [copied, setCopied] = useState(false)
    const colors = [
        "primary",
        "secondary",
        "dark",
        "success",
        "warning",
        "alert",
        "info",
        "light",
        "gray",
        "darkgray"
    ]

    const types = ["outlined", "filled"]

    const [iconset, seticonset] = useState({

        "vo-hamburger": <IconComponent color={color} name="vo-hamburger" type={iconType} />,
        "vo-user": <IconComponent color={color} name="vo-user" type={iconType} />,
        "vo-notification": <IconComponent color={color} name="vo-notification" type={iconType} />,
        "vo-left-arrow": <IconComponent color={color} name="vo-left-arrow" type={iconType} />,
        "vo-right-arrow": <IconComponent color={color} name="vo-right-arrow" type={iconType} />,
        "vo-plus": <IconComponent color={color} name="vo-plus" type={iconType} />,
        "vo-phone": <IconComponent color={color} name="vo-phone" type={iconType} />,
        "vo-like": <IconComponent color={color} name="vo-like" type={iconType} />,
        "vo-favorite": <IconComponent color={color} name="vo-favorite" type={iconType} />,
        "vo-mail": <IconComponent color={color} name="vo-mail" type={iconType} />,
        "vo-pin": <IconComponent color={color} name="vo-pin" type={iconType} />,
        "vo-home": <IconComponent color={color} name="vo-home" type={iconType} />,
        "vo-vertical-menu": <IconComponent color={color} name="vo-vertical-menu" type={iconType} />,
        "vo-horizontal-menu": <IconComponent color={color} name="vo-horizontal-menu" type={iconType} />,
        "vo-down-arrow": <IconComponent color={color} name="vo-down-arrow" type={iconType} />,
        "vo-up-arrow": <IconComponent color={color} name="vo-up-arrow" type={iconType} />,
        "vo-search": <IconComponent color={color} name="vo-search" type={iconType} />,
        "vo-add-picture": <IconComponent color={color} name="vo-add-picture" type={iconType} />,
        "vo-visibility": <IconComponent color={color} name="vo-visibility" type={iconType} />,
        "vo-web-app": <IconComponent color={color} name="vo-web-app" type={iconType} />,
        "vo-trash": <IconComponent color={color} name="vo-trash" type={iconType} />,
        "vo-cross": <IconComponent color={color} name="vo-cross" type={iconType} />,
        "vo-check": <IconComponent color={color} name="vo-check" type={iconType} />,
        "vo-users": <IconComponent color={color} name="vo-users" type={iconType} />,
        "vo-upload-file": <IconComponent color={color} name="vo-upload-file" type={iconType} />,
        "vo-aid-kit": <IconComponent color={color} name="vo-aid-kit" type={iconType} />,
        "vo-volume-off": <IconComponent color={color} name="vo-volume-off" type={iconType} />,
        "vo-volume-on": <IconComponent color={color} name="vo-volume-on" type={iconType} />,
        "vo-computers": <IconComponent color={color} name="vo-computers" type={iconType} />,
        "vo-laptop": <IconComponent color={color} name="vo-laptop" type={iconType} />,
        "vo-unlock": <IconComponent color={color} name="vo-unlock" type={iconType} />,
        "vo-screen": <IconComponent color={color} name="vo-screen" type={iconType} />,
        "vo-lock": <IconComponent color={color} name="vo-lock" type={iconType} />,
        "vo-doctor": <IconComponent color={color} name="vo-doctor" type={iconType} />,
        "vo-signal": <IconComponent color={color} name="vo-signal" type={iconType} />,
        "vo-injector": <IconComponent color={color} name="vo-injector" type={iconType} />,
        "vo-save": <IconComponent color={color} name="vo-save" type={iconType} />,
        "vo-statistic": <IconComponent color={color} name="vo-statistic" type={iconType} />,
        "vo-folder": <IconComponent color={color} name="vo-folder" type={iconType} />,
        "vo-image": <IconComponent color={color} name="vo-image" type={iconType} />,
        "vo-train": <IconComponent color={color} name="vo-train" type={iconType} />,
        "vo-traffic-lights": <IconComponent color={color} name="vo-traffic-lights" type={iconType} />,
        "vo-car": <IconComponent color={color} name="vo-car" type={iconType} />,
        "vo-truck": <IconComponent color={color} name="vo-truck" type={iconType} />,
        "vo-airplane": <IconComponent color={color} name="vo-airplane" type={iconType} />,
        "vo-vehicle": <IconComponent color={color} name="vo-vehicle" type={iconType} />,
        "vo-text": <IconComponent color={color} name="vo-text" type={iconType} />,
        "vo-chat": <IconComponent color={color} name="vo-chat" type={iconType} />,
        "vo-send": <IconComponent color={color} name="vo-send" type={iconType} />,
        "vo-refresh": <IconComponent color={color} name="vo-refresh" type={iconType} />,
        "vo-screen": <IconComponent color={color} name="vo-screen" type={iconType} />,
        "vo-credit-card-number": <IconComponent color={color} name="vo-credit-card-number" type={iconType} />,
        "vo-credit-card-code": <IconComponent color={color} name="vo-credit-card-code" type={iconType} />,
        "vo-chart": <IconComponent color={color} name="vo-chart" type={iconType} />,
        "vo-camera": <IconComponent color={color} name="vo-camera" type={iconType} />,
        "vo-hospital": <IconComponent color={color} name="vo-hospital" type={iconType} />,
        "vo-question": <IconComponent color={color} name="vo-question" type={iconType} />,
        "vo-storage": <IconComponent color={color} name="vo-storage" type={iconType} />,
        "vo-drone": <IconComponent color={color} name="vo-drone" type={iconType} />,
        "vo-calendar": <IconComponent color={color} name="vo-calendar" type={iconType} />,
        "vo-rail": <IconComponent color={color} name="vo-rail" type={iconType} />,
        "vo-digital-board": <IconComponent color={color} name="vo-digital-board" type={iconType} />,
        "vo-link": <IconComponent name={"vo-link"} color={color} type={iconType} />,
        "vo-money": <IconComponent name={"vo-money"} color={color} type={iconType} />,
        "vo-flag": <IconComponent name={"vo-flag"} color={color} type={iconType} />,
        "vo-copy": <IconComponent name={"vo-copy"} color={color} type={iconType} />,
        "vo-video": <IconComponent name={"vo-video"} color={color} type={iconType} />,
        "vo-restaurant": <IconComponent name={"vo-restaurant"} color={color} type={iconType} />

    })

    useEffect(() => {


        var arr = []
        var i;

        for (i = 0; i < Object.keys(iconset).length; i++) {

            if (Object.keys(iconset)[i].search(search) !== -1) {
                arr.push(Object.keys(iconset)[i])
            }
        }

        setResult(arr)

    }, [search, color, iconType])


    useEffect(() => {

        setIconType(types[type])
        setColor(colors[select])

    }, [type, select])

    useEffect(() => {

        if (selected !== "") {
            setModal(true)
        }

    }, [selected])

    return (
        <Grid>
            <Header>
                Icons
            </Header>
            <Description>
                You can use the Icon Component we created to use complementary icons in your applications.
            </Description>
            <div style={{ width: "100%", height: "auto", borderRadius: "10px", border:"1px solid "+theme["darkgray"], padding: "10px", textAlign: "center", overflow:"auto" }}>
                <div>
                    <Input color={"secondary"} label="Search Icon" size={"md"} type="text" value={search} onChange={setSearch} />
                </div>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <Select color={"secondary"} label={"Color"} size="sm" value={select} onChange={setSelect}>
                        <Option>Primary</Option>
                        <Option>Secondary</Option>
                        <Option>Dark</Option>
                        <Option>Success</Option>
                        <Option>Warning</Option>
                        <Option>Alert</Option>
                        <Option>Info</Option>
                        <Option>Light</Option>
                        <Option>Gray</Option>
                        <Option>Dark Gray</Option>
                    </Select>
                    <Select color={"secondary"} label={"Type"} size="sm" value={type} onChange={setType}>
                        <Option>Outlined</Option>
                        <Option>Filled</Option>
                    </Select>
                </div>

                <div style={{ marginTop: "20px" }}>
                    {result.map((name, index) => (
                        <div style={{ display: "inline-block", cursor: "pointer" }} onClick={() => setSelected(name)}>
                            <div style={{ borderRadius: "5px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", margin: "5px", border: "1px solid " + theme["darkgray"], width: "100px", height: "100px" }}>
                                <div>
                                    <IconComponent name={name} color={color} type={iconType} />
                                </div>
                                <div>
                                    <span style={{ fontSize: "13px", fontWeight: "bold", fontFamily: "inherit", color: theme["darkgray"] }}>{name}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Subheader>
                Name
            </Subheader>
            <Description>
                In order to use the icons in the icon component, we need to call the icons in the "name" prop.
            </Description>
            <Showroom>
                <div style={{ display: "flex" }}>
                    <IconComponent color={"darkgray"} name="vo-home" type={"outlined"} />
                    <IconComponent color={"darkgray"} name="vo-like" type={"outlined"} />
                    <IconComponent color={"darkgray"} name="vo-favorite" type={"outlined"} />
                    <IconComponent color={"darkgray"} name="vo-search" type={"outlined"} />
                    <IconComponent color={"darkgray"} name="vo-add-picture" type={"outlined"} />
                    <IconComponent color={"darkgray"} name="vo-restaurant" type={"outlined"} />

                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n" +
                    "\treturn(\n" +
                    "\t\t<Icon name='vo-home'/> \n" +
                    "\t\t<Icon name='vo-like'/> \n" +
                    "\t\t<Icon name='vo-favorite'/>\n" +
                    "\t\t<Icon name='vo-search'/>\n" +
                    "\t\t<Icon name='vo-add-picture'/>\n" +
                    "\t\t<Icon name='restaurant'/>\n" +
                    "\t)\n" +
                    "}"
                }
            </Coderoom>
            <Subheader>
                Type
            </Subheader>
            <Description>
                You can use the type prop to specify how the icons will appear in the Icon Component. It can take "filled" and "outlined" values.
            </Description>
            <Showroom>
                <div style={{ display: "flex" }}>
                    <IconComponent color={"secondary"} name="vo-home" type={"filled"} />
                    <IconComponent color={"secondary"} name="vo-home" type={"outlined"} />
                </div>
                <div style={{ display: "flex" }}>
                    <IconComponent color={"secondary"} name="vo-like" type={"filled"} />
                    <IconComponent color={"secondary"} name="vo-like" type={"outlined"} />
                </div>
                <div style={{ display: "flex" }}>
                    <IconComponent color={"secondary"} name="vo-favorite" type={"filled"} />
                    <IconComponent color={"secondary"} name="vo-favorite" type={"outlined"} />
                </div>
                <div style={{ display: "flex" }}>
                    <IconComponent color={"secondary"} name="vo-search" type={"filled"} />
                    <IconComponent color={"secondary"} name="vo-search" type={"outlined"} />
                </div>
                <div style={{ display: "flex" }}>
                    <IconComponent color={"secondary"} name="vo-add-picture" type={"filled"} />
                    <IconComponent color={"secondary"} name="vo-add-picture" type={"outlined"} />
                </div>
                <div style={{ display: "flex" }}>
                    <IconComponent color={"secondary"} name="vo-restaurant" type={"filled"} />
                    <IconComponent color={"secondary"} name="vo-restaurant" type={"outlined"} />
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n" +
                    "\treturn(\n" +
                    "\t\t<Icon name='vo-home' type='filled'/> \n" +
                    "\t\t<Icon name='vo-home' type='outlined'/> \n" +
                    "\t\t<Icon name='vo-like' type='filled'/> \n" +
                    "\t\t<Icon name='vo-like' type='outlined'/> \n" +
                    "\t\t<Icon name='vo-favorite' type='filled'/>\n" +
                    "\t\t<Icon name='vo-favorite' type='outlined'/>\n" +
                    "\t\t<Icon name='vo-search' type='filled'/>\n" +
                    "\t\t<Icon name='vo-search' type='outlined'/>\n" +
                    "\t\t<Icon name='vo-addPicture' type='filled'/>\n" +
                    "\t\t<Icon name='vo-addPicture' type='outlined'/>\n" +
                    "\t\t<Icon name='restaurant' type='filled'/>\n" +
                    "\t\t<Icon name='restaurant' type='outlined'/>\n" +
                    "\t)\n" +
                    "}"
                }
            </Coderoom>
            <Subheader>
                Color
            </Subheader>
            <Description>
                You can give colors to the icons you will use by using color props. It can take the values ​​"primary", "secondary", "success", "info", "warning", "alert", "dark", "darkgray", "gray".
            </Description>
            <Showroom>
                <div style={{ display: "flex" }}>
                    <IconComponent color={"primary"} name="vo-home" type={"filled"} />
                    <IconComponent color={"secondary"} name="vo-home" type={"filled"} />
                    <IconComponent color={"success"} name="vo-home" type={"filled"} />
                    <IconComponent color={"info"} name="vo-home" type={"filled"} />
                    <IconComponent color={"warning"} name="vo-home" type={"filled"} />
                    <IconComponent color={"alert"} name="vo-home" type={"filled"} />
                    <IconComponent color={"dark"} name="vo-home" type={"filled"} />
                    <IconComponent color={"darkgray"} name="vo-home" type={"filled"} />
                    <IconComponent color={"gray"} name="vo-home" type={"filled"} />
                </div>
                <div style={{ display: "flex" }}>
                    <IconComponent color={"primary"} name="vo-home" type={"outlined"} />
                    <IconComponent color={"secondary"} name="vo-home" type={"outlined"} />
                    <IconComponent color={"success"} name="vo-home" type={"outlined"} />
                    <IconComponent color={"info"} name="vo-home" type={"outlined"} />
                    <IconComponent color={"warning"} name="vo-home" type={"outlined"} />
                    <IconComponent color={"alert"} name="vo-home" type={"outlined"} />
                    <IconComponent color={"dark"} name="vo-home" type={"outlined"} />
                    <IconComponent color={"darkgray"} name="vo-home" type={"outlined"} />
                    <IconComponent color={"gray"} name="vo-home" type={"outlined"} />
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n" +
                    "\treturn(\n" +
                    "\t<div style={{display='flex'}}>\n" +
                    "\t\t<Icon name='vo-home' type='filled' color='primary'/> \n" +
                    "\t\t<Icon name='vo-home' type='filled' color='secondary'/> \n" +
                    "\t\t<Icon name='vo-home' type='filled' color='success'/>\n" +
                    "\t\t<Icon name='vo-home' type='filled' color='info'/>\n" +
                    "\t\t<Icon name='vo-home' type='filled' color='warning'/>\n" +
                    "\t\t<Icon name='vo-home' type='filled' color='alert'/>\n" +
                    "\t\t<Icon name='vo-home' type='filled' color='dark'/>\n" +
                    "\t\t<Icon name='vo-home' type='filled' color='darkgray'/>\n" +
                    "\t\t<Icon name='vo-home' type='filled' color='gray'/>\n" +
                    "\t</div>\n" +
                    "\t<div style={{display='flex'}}>\n" +
                    "\t\t<Icon name='vo-home' type='outlined' color='primary'/> \n" +
                    "\t\t<Icon name='vo-home' type='outlined' color='secondary'/> \n" +
                    "\t\t<Icon name='vo-home' type='outlined' color='success'/>\n" +
                    "\t\t<Icon name='vo-home' type='outlined' color='info'/>\n" +
                    "\t\t<Icon name='vo-home' type='outlined' color='warning'/>\n" +
                    "\t\t<Icon name='vo-home' type='outlined' color='alert'/>\n" +
                    "\t\t<Icon name='vo-home' type='outlined' color='dark'/>\n" +
                    "\t\t<Icon name='vo-home' type='outlined' color='drakgray'/>\n" +
                    "\t\t<Icon name='vo-home' type='outlined' color='gray'/>\n" +
                    "\t</div>\n" +
                    "\t)\n" +
                    "}"
                }
            </Coderoom>
            <Showroom>
            <Button onClick={()=>setModal(true)}>open</Button>
            <Modal open={modal} size="md">
                <div style={{ padding: "20px", textAlign: "center" }}>
                    <div style={{display:"flex", justifyContent:"end",alignItems:"center"}}>
                        <Button style={{width:"50px", height:"50px"}} color={"secondary"} onClick={()=> {setModal(false); setSelected("")}}><IconComponent name={"vo-cross"} color={"secondary"} type={"outlined"} /></Button>
                    </div>
                    <h2 style={{color:theme["secondary"]}}>{selected.length > 0 ? selected.split("-")[1].charAt(0).toUpperCase() : ""}{selected.length > 0 ? selected.split("-")[1].substring(1, selected.split("-")[1].length) : ""} Icon</h2>
                    <div style={{ display: "inline-block", cursor: "pointer" }}>
                        <div style={{ borderRadius: "5px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", margin: "5px", border: "1px solid " + theme["darkgray"], width: "100px", height: "100px" }}>
                            <div>
                                <IconComponent name={selected} color={color} type={iconType} />
                            </div>
                            <div>
                                <span style={{ fontSize: "13px", fontWeight: "bold", fontFamily: "inherit", color: theme["darkgray"] }}>{selected}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <Button size={"lg"} color={"secondary"} onClick={() => {
                            if (!copied) {

                                navigator.clipboard.writeText("<Icon name='" + selected + "' type='" + iconType + "' color='" + color + "'/>");
                                setCopied(true)
                                setTimeout(() => {
                                    setCopied(false)
                                }, 3000);

                            }

                        }}>{copied ? <span style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px" }}><IconComponent name={"vo-check"} color={"success"} type={"outlined"} /> Copied to Clipboard</span> : <span style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px" }}><IconComponent name={"vo-copy"} color={"secondary"} type={"outlined"} /> Copy to Clipboard</span>}</Button>
                    </div>
                    <Coderoom>
                        {
                            "\t<Icon name='" + selected + "' type='" + iconType + "' color='" + color + "'/>\n"
                        }
                    </Coderoom>
                </div>
            </Modal>
            </Showroom>

            <Subheader>Props</Subheader>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                            default: "gray", 
                            description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                        },
                        {
                            name:"name",
                            type:"string",
                            default:"undefined",
                            description:"We can write the icon names we want to use."
                        },
                        {
                            name:"type",
                            type:"filled || outlined",
                            default:"outlined",
                            description:"You can use type props to specify the view."
                        }
                      ]}  
                        
                    />
                </div>
            </div>
        </Grid>
    )

}
export default Icon;