/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************

color ---> primary || secondary || success || info || warning || alert || dark || darkgray || gray

type ---> blink || bubble || spinner || chaos

size ---> size of the spinner


*/
import React, { useState, useEffect, useContext } from "react";
import { spinnerSize, injectStyle } from "../Theme";
import { ThemeContext } from "../ThemeProvider";
import { colorTypes,sizeTypes, spinnerTypes, themeProviderCheck } from "../ErrorHandler";

function Spinner({color, type, size}) {
  const theme = useContext(ThemeContext);

  const [width, setWidth] = useState("40")
  const [height, setHeight] = useState("40")
  const [localColor, setLocalColor] = useState("#ffffff")



  useEffect(() => {

    colorTypes.check(color)
    sizeTypes.check(size)
    spinnerTypes.check(type)
    themeProviderCheck.check(theme)
  }, [color, size, type, theme])

  const keyframesStyle = `
    @-webkit-keyframes blinkAnimation {
      0% {transform:scale(0)}
      10% {transform:scale(0.2)}
      20% {transform:scale(0.4)}
      30% {transform:scale(0.6)}
      40% {transform:scale(0.8)}
      50% {transform:scale(1)}
      60% {transform:scale(0.8)}
      70% {transform:scale(0.6)}
      80% {transform:scale(0.4)}
      90% {transform:scale(0.2)}
      100% {transform:scale(0)}
    }
  `;

  injectStyle(keyframesStyle);

  const keyframesStyle1 = `
    @-webkit-keyframes rotateAnim {
      0% {transform:rotate(0deg)}
      10% {transform:rotate(36deg)}
      20% {transform:rotate(72deg)}
      30% {transform:rotate(108deg)}
      40% {transform:rotate(144deg)}
      50% {transform:rotate(180deg)}
      60% {transform:rotate(216deg)}
      70% {transform:rotate(252deg)}
      80% {transform:rotate(288deg)}
      90% {transform:rotate(324deg)}
      100% {transform:rotate(360deg)}
    }
  `;

  injectStyle(keyframesStyle1);

  const keyframesStyle2 = `
    @-webkit-keyframes leftToUpAnim {
      0% {transform:translateX(-100px) scale(0.5);}
      25% {transform:translateX(0px) scale(1);}
      50% {transform:translateX(100px) scale(0.5);}
      75% {transform:translateX(0px) scale(1);}
      100% {transform:translateX(-100px) scale(0.5);}
    }
  `;

  injectStyle(keyframesStyle2);

  const keyframesStyle3 = `
    @-webkit-keyframes upToDownAnim {
      0% {transform:translateY(-100px) scale(0.5);}
      25% {transform:translateY(0px) scale(1);}
      50% {transform:translateY(100px) scale(0.5);}
      75% {transform:translateY(0px) scale(1);}
      100% {transform:translateY(-100px) scale(0.5);}
    }
  `;

  injectStyle(keyframesStyle3);


  useEffect(() => {

    if ( color !== undefined) {
      setLocalColor(theme[ color])
    }
  }, [theme,color])


  useEffect(() => {

    if ( size !== undefined) {
      setHeight(spinnerSize[ size].height)
      setWidth(spinnerSize[ size].width)
    }

  }, [size])

  return (
    <div style={{  width: width + "px", height: height + "px", borderRadius: width + "px", margin: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      {
        ( type === undefined ||  type === "blink") &&

        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", WebkitAnimation: 'blinkAnimation 1s infinite', backgroundColor: localColor, borderRadius: (Number(width) - 4) + "px", width: (Number(width) - 4) + "px", height: (Number(width) - 4) + "px" }}>
        </div>
      }
      {
         type === "bubble" &&
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

          <div style={{ margin: "5px", WebkitAnimation: 'blinkAnimation 1s infinite', backgroundColor: localColor, borderRadius: (Number(width / 2)) + "px", width: (Number(width / 2)) + "px", height: (Number(width / 2)) + "px" }}>

          </div>
          <div style={{ margin: "5px", WebkitAnimation: 'blinkAnimation 0.5s infinite', backgroundColor: localColor, borderRadius: (Number(width / 2)) + "px", width: (Number(width / 2)) + "px", height: (Number(width / 2)) + "px" }}>

          </div>
          <div style={{ margin: "5px", WebkitAnimation: 'blinkAnimation 1s infinite', backgroundColor: localColor, borderRadius: (Number(width / 2)) + "px", width: (Number(width / 2)) + "px", height: (Number(width / 2)) + "px" }}>

          </div>
        </div>
      }
      {
         type === "spinner" &&
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={{ WebkitAnimation: 'rotateAnim 0.8s infinite', border: "5px solid " + theme["gray"], borderTop: "5px solid " + localColor, borderRadius: width + "px", width: (Number(width)) + "px", height: (Number(width)) + "px" }}>

          </div>

        </div>
      }
      {
         type === "waos" &&
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", borderRadius: width + "px" }}>
          <div style={{ WebkitAnimation: 'leftToUpAnim 1.02s infinite', backgroundColor: localColor, borderRadius: width + "px", width: (Number(width / 4)) + "px", height: (Number(width / 4)) + "px" }}>

          </div>
          <div style={{ WebkitAnimation: 'leftToUpAnim 1.01s infinite', backgroundColor: localColor, borderRadius: width + "px", width: (Number(width / 4)) + "px", height: (Number(width / 4)) + "px" }}>

          </div>
          <div style={{ WebkitAnimation: 'leftToUpAnim 1s infinite', backgroundColor: localColor, borderRadius: width + "px", width: (Number(width / 4)) + "px", height: (Number(width / 4)) + "px" }}>

          </div>
        </div>
      }
    </div>
  );
}

export default Spinner;
