import React, { useContext, useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import { ThemeContext } from "../../Components/ThemeProvider";
import ModalComponent from "../../Components/Modal";
import Button from "../../Components/Button";
import Icon from "../../Components/Icon";
import PersonCard from "../../Components/PersonCard";
import Table from "../../Shared/Table";

function Modal(){

    const theme = useContext(ThemeContext);
    const [selected, setSelected] = useState("")
    const [modal, setModal] = useState(false)
    const [isModal,setIsModal] = useState(false)
    const [isModal1,setIsModal1] = useState(false)
    const [isModal2,setIsModal2] = useState(false)
    const [isModal3,setIsModal3] = useState(false)
    const [isModalColor,setIsModalColor] = useState(false)
    const [isModalColor1,setIsModalColor1] = useState(false)
    const [isModalColor2,setIsModalColor2] = useState(false)


    return(
        <Grid>
            <Header>
                Modal
            </Header>
            <Description>

            </Description>
            <Subheader>
                Open
            </Subheader>
            <Description>
            You can use open props to set the popup location. It is of boolean type, can take a value of "true" or "false".
            </Description>
            <Showroom>
            <Button onClick={()=>setModal(true)}>open</Button>
            <ModalComponent open={modal} size="md" >
                <div style={{ padding: "20px", textAlign: "center" }}>
                    <div style={{display:"flex", justifyContent:"end",alignItems:"center"}}>
                        <Button style={{width:"50px", height:"50px"}} color={"secondary"} onClick={()=> {setModal(false); setSelected("")}}><Icon name={"vo-cross"} color={"secondary"} type={"outlined"} /></Button>
                    </div>
                    <h2>{selected.length > 0 ? selected.split("-")[1].charAt(0).toUpperCase() : ""}{selected.length > 0 ? selected.split("-")[1].substring(1, selected.split("-")[1].length) : ""} Modal Component </h2>
                    <div style={{ display: "inline-block", cursor: "pointer" }}>
                        <div style={{ borderRadius: "5px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", margin: "5px", border: "1px solid " + theme["darkgray"], width: "200px", height: "200px" }}>
                                <span style={{ fontSize: "13px", fontWeight: "bold", fontFamily: "inherit", color: theme["darkgray"] }}>You can use Pop-ups in many areas such as various explanations, details, directions.</span>
                        </div>
                    </div>
                    
                    
                </div>
            </ModalComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Button onClick={()=>setModal(true)}>open</Button>\n" +
             "\t\t<Modal open={modal} size='md'>\n" +
             "\t\t\t<div style={{ padding: '20px', textAlign: 'center' }}>\n" +
             "\t\t\t\t<div style={{display:'flex', justifyContent:'end',alignItems:'center'}}>\n" +
             "\t\t\t\t\t<Button style={{width:'50px', height:'50px'}} color={'secondary'} onClick={()=> {setModal(false); setSelected('')}}><IconComponent name={'vo-cross'} color={'secondary'} type={'outlined'} /></Button>\n" +
             "\t\t\t\t</div>\n" +
             "\t\t\t</div>\n" +
             "\t\t\t<h2>{selected.length > 0 ? selected.split('-')[1].charAt(0).toUpperCase() : ''}{selected.length > 0 ? selected.split('-')[1].substring(1, selected.split('-')[1].length) : ''} Modal Component </h2>\n" +
             "\t\t\t<div style={{ display: 'inline-block', cursor: 'pointer' }}>\n" +
             "\t\t\t\t<div style={{ borderRadius: '5px', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', margin: '5px', border: '1px solid ' + theme['darkgray'], width: '200px', height: '200px' }}>\n" +
             "\t\t\t\t\t<span style={{ fontSize: '13px', fontWeight: 'bold', fontFamily: 'inherit', color: theme['darkgray'] }}>You can use Pop-ups in many areas such as various explanations, details, directions.</span>\n" +
             "\t\t\t\t</div>\n" +
             "\t\t\t</div>\n" +
             "\t\t</Modal>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Children
            </Subheader>
            <Description>
            You can use component, html tag, string etc in children props.
            </Description>
            <Showroom>
            <Button onClick={()=>setIsModal3(true)}>Person Card</Button>
            <ModalComponent open={isModal3} size={"sm"}>
                    <div >
                    <div style={{display:"flex", justifyContent:"end",alignItems:"center"}}>
                        <Button style={{width:"50px", height:"50px"}} color={"secondary"} onClick={()=> {setIsModal3(false); setSelected("")}}>
                            <Icon name={"vo-cross"} color={"secondary"} type={"outlined"} />
                        </Button>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <PersonCard header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} style={{display:"block"}}/>
                    </div>
                    
                    </div>
                </ModalComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Button onClick={()=>setModal(true)}>open</Button>\n" +
             "\t\t\t<Modal open={modal} size='md'>\n" +
             "\t\t\t\t<div>\n" +
             "\t\t\t\t\t<div style={{display:'flex', justifyContent:'end',alignItems:'center'}}>\n" +
             "\t\t\t\t\t\t<Button style={{width:'50px', height:'50px'}} color={'secondary'} onClick={()=> {setModal(false); setSelected('')}}><IconComponent name={'vo-cross'} color={'secondary'} type={'outlined'} /></Button>\n" +
             "\t\t\t\t\t</div>\n" +
             "\t\t\t\t<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>\n" +
             "\t\t\t\t\t<PersonCard header={'Emma Smith'} content={'Project Manager'} src={'avatar1.jpg'} style={{display:'block'}}/>\n"+
             "\t\t\t\t</div>\n" +
             "\t\t\t</div>\n" +
             "\t\t</Modal>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Size
            </Subheader>
            <Description>

            </Description>
            <Showroom>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Button onClick={()=>setIsModal(true)}>Person Card</Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Button onClick={()=>setIsModal1(true)}>Person Card</Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Button onClick={()=>setIsModal2(true)}>Person Card</Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            

            <ModalComponent open={isModal} size={"sm"}>
            <div style={{display:"flex", justifyContent:"end",alignItems:"center"}}>
                <Button style={{width:"50px", height:"50px"}} color={"secondary"} onClick={()=> {setIsModal(false); setSelected("")}}>
                    <Icon name={"vo-cross"} color={"secondary"} type={"outlined"} />
                </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <PersonCard header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} style={{display:"block"}}/>
            </div>
            </ModalComponent>
            <ModalComponent open={isModal1} size={"md"}>
            <div style={{display:"flex", justifyContent:"end",alignItems:"center"}}>
                <Button style={{width:"50px", height:"50px"}} onClick={()=>setIsModal1(false)}>
                    <Icon name={"vo-cross"} color={"secondary"} type={"outlined"} />
                </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <PersonCard header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} style={{display:"block"}}/>
            </div>
            </ModalComponent>
            <ModalComponent open={isModal2} size={"lg"}>
            <div style={{display:"flex", justifyContent:"end",alignItems:"center"}}>
                <Button style={{width:"50px", height:"50px"}} onClick={()=>setIsModal2(false)}>
                    <Icon name={"vo-cross"} color={"secondary"} type={"outlined"} />
                </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <PersonCard header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} style={{display:"block"}}/>
            </div>
            </ModalComponent>

            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Button onClick={()=>setModal(true)}>open</Button>\n" +
             "\t\t<Modal open={modal} size='sm'>\n" +
             "\t\t\t<div style={{display:'flex', justifyContent:'end',alignItems:'center'}}>\n" +
             "\t\t\t\t<Button style={{width:'50px', height:'50px'}} color={'secondary'} onClick={()=> {setModal(false); setSelected('')}}><IconComponent name={'vo-cross'} color={'secondary'} type={'outlined'} /></Button>\n" +
             "\t\t\t</div>\n" +
             "\t\t\t<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>\n" +
             "\t\t\t\t<PersonCard header={'Emma Smith'} content={'Project Manager'} src={'avatar1.jpg'} style={{display:'block'}}/>\n"+
             "\t\t\t</div>\n" +
             "\t\t</Modal>\n" +
             "\t\t<Button onClick={()=>setModal1(true)}>open</Button>\n" +
             "\t\t<Modal open={modal} size='md'>\n" +
             "\t\t\t<div style={{display:'flex', justifyContent:'end',alignItems:'center'}}>\n" +
             "\t\t\t\t<Button style={{width:'50px', height:'50px'}} color={'secondary'} onClick={()=> {setModal(false); setSelected('')}}><IconComponent name={'vo-cross'} color={'secondary'} type={'outlined'} /></Button>\n" +
             "\t\t\t</div>\n" +
             "\t\t\t<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>\n" +
             "\t\t\t\t<PersonCard header={'Emma Smith'} content={'Project Manager'} src={'avatar1.jpg'} style={{display:'block'}}/>\n"+
             "\t\t\t</div>\n" +
             "\t\t</Modal>\n" +
             "\t\t<Button onClick={()=>setModal2(true)}>open</Button>\n" +
             "\t\t<Modal open={modal} size='lg'>\n" +
             "\t\t\t<div style={{display:'flex', justifyContent:'end',alignItems:'center'}}>\n" +
             "\t\t\t\t<Button style={{width:'50px', height:'50px'}} color={'secondary'} onClick={()=> {setModal(false); setSelected('')}}><IconComponent name={'vo-cross'} color={'secondary'} type={'outlined'} /></Button>\n" +
             "\t\t\t</div>\n" +
             "\t\t\t<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>\n" +
             "\t\t\t\t<PersonCard header={'Emma Smith'} content={'Project Manager'} src={'avatar1.jpg'} style={{display:'block'}}/>\n"+
             "\t\t\t</div>\n" +
             "\t\t</Modal>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            
            <Subheader>
                Color
            </Subheader>
            <Description>

            </Description>
            <Showroom>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Button onClick={()=>setIsModalColor(true)}>Person Card</Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Button onClick={()=>setIsModalColor1(true)}>Person Card</Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Button onClick={()=>setIsModalColor2(true)}>Person Card</Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ModalComponent open={isModalColor} size={"sm"} color={'primary'}>
                <div style={{display:"flex", justifyContent:"end",alignItems:"center"}}>
                    <Button style={{width:"50px", height:"50px"}} color={"secondary"} onClick={()=> {setIsModalColor(false); setSelected("")}}>
                        <Icon name={"vo-cross"} color={"secondary"} type={"outlined"} />
                    </Button>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <PersonCard header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} style={{display:"block"}}/>
                </div>
            </ModalComponent>
            <ModalComponent open={isModalColor1} size={"sm"} color={'secondary'}>
                <div style={{display:"flex", justifyContent:"end",alignItems:"center"}}>
                    <Button style={{width:"50px", height:"50px"}} onClick={()=>setIsModalColor1(false)}>
                        <Icon name={"vo-cross"} color={"secondary"} type={"outlined"} />
                    </Button>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <PersonCard header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} style={{display:"block"}}/>
                </div>
            </ModalComponent>
            <ModalComponent open={isModalColor2} size={"sm"} color={'alert'}>
                <div style={{display:"flex", justifyContent:"end",alignItems:"center"}}>
                    <Button style={{width:"50px", height:"50px"}} onClick={()=>setIsModalColor2(false)}>
                        <Icon name={"vo-cross"} color={"secondary"} type={"outlined"} />
                    </Button>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <PersonCard header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} style={{display:"block"}}/>
                </div>
            </ModalComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Button onClick={()=>setModal(true)}>open</Button>\n" +
             "\t\t<Modal open={modal} size='sm'>\n" +
             "\t\t\t<div style={{display:'flex', justifyContent:'end',alignItems:'center'}}>\n" +
             "\t\t\t\t<Button style={{width:'50px', height:'50px'}} color={'secondary'} onClick={()=> {setModal(false); setSelected('')}}><IconComponent name={'vo-cross'} color={'secondary'} type={'outlined'} /></Button>\n" +
             "\t\t\t</div>\n" +
             "\t\t\t<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>\n" +
             "\t\t\t\t<PersonCard header={'Emma Smith'} content={'Project Manager'} src={'avatar1.jpg'} style={{display:'block'}}/>\n"+
             "\t\t\t</div>\n" +
             "\t\t</Modal>\n" +
             "\t\t<Button onClick={()=>setModal(true)}>open</Button>\n" +
             "\t\t<Modal open={modal} size='sm'>\n" +
             "\t\t\t<div style={{display:'flex', justifyContent:'end',alignItems:'center'}}>\n" +
             "\t\t\t\t<Button style={{width:'50px', height:'50px'}} color={'secondary'} onClick={()=> {setModal(false); setSelected('')}}><IconComponent name={'vo-cross'} color={'secondary'} type={'outlined'} /></Button>\n" +
             "\t\t\t</div>\n" +
             "\t\t\t<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>\n" +
             "\t\t\t\t<PersonCard header={'Emma Smith'} content={'Project Manager'} src={'avatar1.jpg'} style={{display:'block'}}/>\n"+
             "\t\t\t</div>\n" +
             "\t\t</Modal>\n" +
             "\t\t<Button onClick={()=>setModal(true)}>open</Button>\n" +
             "\t\t<Modal open={modal} size='sm'>\n" +
             "\t\t\t<div style={{display:'flex', justifyContent:'end',alignItems:'center'}}>\n" +
             "\t\t\t\t<Button style={{width:'50px', height:'50px'}} color={'secondary'} onClick={()=> {setModal(false); setSelected('')}}><IconComponent name={'vo-cross'} color={'secondary'} type={'outlined'} /></Button>\n" +
             "\t\t\t</div>\n" +
             "\t\t\t<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>\n" +
             "\t\t\t\t<PersonCard header={'Emma Smith'} content={'Project Manager'} src={'avatar1.jpg'} style={{display:'block'}}/>\n"+
             "\t\t\t</div>\n" +
             "\t\t</Modal>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                            default: "gray", 
                            description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                        },
                        {
                            name:"children",
                            type:"string \n Component \n HTML Tag",
                            default:"undefined",
                            description:"You can write the 'children' items that you want to use."
                        },
                        {
                            name:"size",
                            type:"sm\nmd\nlg",
                            default:"outlined",
                            description:"You can specify the pop-up window size."
                        },
                        {
                            name:"open",
                            type:"true\nfalse",
                            default:"",
                            description:"You can set the pop-up window to active or passive position."
                        }
                      ]}  
                        
                    />
                </div>
            </div>
            

        </Grid>
    )

}
export default Modal;


