/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************

color ---> primary || secondary || success || info || warning || alert || dark || darkgray || gray(default)

size ---> sm(default) || md || lg 

disabled ---> true || false(default)

value ---> It depends on the type of the input.

label ---> 'String' - undefined(default)

onChange ---> function - undefined(default) -- returns selected value

type ---> card || phone || iban || password || date

prefix ---> countries like TR-EN-UK || You can use them when you are using iban type

placeholder --->




*/
import React, { useState, useEffect, useRef, useContext } from 'react';
import Calendar from '../Calendar';
import { inputSize, hexToRgbA } from "../Theme";
import { ThemeContext } from '../ThemeProvider';
import { colorTypes , sizeTypes , styleTypes, disabledTypes, inputTypes, onChangeTypes, placeHolderTypes, stringValueTypes, dateValueTypes, themeProviderCheck } from "../ErrorHandler";


function Input({ color, size, disabled, value, label, onChange, type, prefix, style, placeholder }) {
    const theme = useContext(ThemeContext);

    const inputvalue = useRef()
    const [localColor, setLocalColor] = useState(theme["darkgray"])
    const [width, setWidth] = useState("350")
    const [height, setHeight] = useState("40")
    const [fontSize, setFontSize] = useState("14")
    const [onFocus, setOnFocus] = useState(false)
    const [dateCopy, setDateCopy] = useState(type === "date" ? value !== undefined ? new Date(value) : new Date() : null)
    const [error, setError] = useState(false)
    const [enteredCal, setEnteredCal] = useState(false)
    const placeholders = {
        "card": "_ _ _ _ - _ _ _ _ - _ _ _ _ - _ _ _ _",
        "phone": "_ _ _ - _ _ _ - _ _ _ _",
        "iban": prefix + "_ _ - _ _ _ _ - _ _ _ _ - _ _ _ _ - _ _ _ _ - _ _ _ _ - _ _ _ _",
        "password": "***********",
        "date": "_ _ - _ _ - _ _ _ _"
    }

    const [labelAnim, setLabelAnim] = useState(false)


    useEffect(()=>{
        colorTypes.check(color)
        sizeTypes.check(size)
        styleTypes.check(style)
        disabledTypes.check(disabled)
        onChangeTypes.check(onChange)
        placeHolderTypes.check(placeholder)
        inputTypes.check(type)
        themeProviderCheck.check(theme)
        if(type === "date"){
            dateValueTypes.check(value)
        }
        else{
            stringValueTypes.check(value)
        }

      },[color, size, style, disabled, onChange, placeholder, type, theme, value])

    useEffect(() => {

        if (size !== undefined) {
            setHeight(inputSize[size].height)
            setWidth(inputSize[size].width)
            setFontSize(inputSize[size].fontSize)
        }
    }, [size])

    useEffect(() => {

        if (color !== undefined) {
            setLocalColor(theme[color])
        }
    }, [theme])

    const handleChange = (value) => {

        let inputvalueCopy = inputvalue.current.value;

        if (type === undefined && onChange !== undefined) {
            onChange(inputvalue.current.value)
        }

        if (type === "number") {

            inputvalue.current.value = inputvalueCopy.replace(/\D/g, '');
            if (onChange !== undefined) {
                onChange(inputvalue.current.value)
            }

        }

        if (type === "password") {
            if (onChange !== undefined) {
                onChange(inputvalue.current.value)
            }

        }

        if (type === "date") {

            let dateValue = inputvalue.current.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);

            inputvalue.current.value = !dateValue[2]
                ? dateValue[1]
                : `${dateValue[1]}-${dateValue[2]}${`${dateValue[3] ? `-${dateValue[3]}` : ''
                }`}${`${dateValue[4] ? `-${dateValue[4]}` : ''}`}`;

            if (Number(dateValue[1]) >= 1 && Number(dateValue[1]) <= 31 && Number(dateValue[2]) >= 1 && Number(dateValue[2]) <= 12 && Number(dateValue[3]) >= 1900 && Number(dateValue[3]) <= 2200 && new Date(value).getTime() !== new Date(dateValue[3], dateValue[2] - 1, dateValue[1]).getTime()) {

                if (onChange !== undefined) {
                    onChange(new Date(dateValue[3], dateValue[2] - 1, dateValue[1]))
                    setDateCopy(new Date(dateValue[3], dateValue[2] - 1, dateValue[1]))
                }

                setError(false)




            }
            else {
                if (new Date(value).getTime() !== new Date(dateValue[3], dateValue[2] - 1, dateValue[1]).getTime()) {
                    setError(true)

                }
                else {
                    setError(false)
                }
            }

        }

        if (type === "text") {
            inputvalue.current.value = inputvalueCopy.replace(/\d|\W/g, '')
            if (onChange !== undefined) {
                onChange(inputvalue.current.value)
            }
        }

        if (type === "card") {

            let cardValue = inputvalue.current.value.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);

            inputvalue.current.value = !cardValue[2]
                ? cardValue[1]
                : `${cardValue[1]}-${cardValue[2]}${`${cardValue[3] ? `-${cardValue[3]}` : ''
                }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;

            if (onChange !== undefined) {
                onChange(inputvalue.current.value.replaceAll("-", "").replaceAll(" ", ""))
            }

        }

        if (type === "phone") {


            let phoneValue = inputvalue.current.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

            inputvalue.current.value = !phoneValue[2]
                ? `${phoneValue[1]}`
                : `${phoneValue[1]} - ${phoneValue[2]}${`${phoneValue[3] ? ` - ${phoneValue[3]}` : ''}`}`;

            if (inputvalue.current.value === "()")
                inputvalue.current.value = ""


            if (onChange !== undefined) {
                onChange(inputvalue.current.value.replaceAll("-", "").replaceAll(" ", ""))
            }
        }


        if (type === "iban") {

            let ibanValue = inputvalue.current.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);

            inputvalue.current.value = !ibanValue[2]
                ? `${prefix}${ibanValue[1]}`
                : `${prefix}${ibanValue[1]} - ${ibanValue[2]}${`${ibanValue[3] ? ` - ${ibanValue[3]}` : ''}`}${`${ibanValue[4] ? ` - ${ibanValue[4]}` : ''}`}${`${ibanValue[5] ? ` - ${ibanValue[5]}` : ''}`}${`${ibanValue[6] ? ` - ${ibanValue[6]}` : ''}`}${`${ibanValue[7] ? ` - ${ibanValue[7]}` : ''}`}`;

            if (inputvalue.current.value === "()")
                inputvalue.current.value = ""

            if (onChange !== undefined) {
                onChange(inputvalue.current.value.replaceAll("-", "").replaceAll(" ", ""))
            }
        }
    }


    useEffect(() => {

        if (type === "date") {

            if(!error){}
                setError(false)

            onChange(dateCopy)
            inputvalue.current.value = formatDate(dateCopy)
            
        }

    }, [dateCopy])



    const formatDate = (datex) => {

        let dateCop = new Date(datex)
        return dateCop.getDate().toString().padStart(2, "0") + "-" + (dateCop.getMonth() + 1).toString().padStart(2, "0") + "-" + dateCop.getFullYear()
    }


    return (
        <div style={{ fontFamily: "inherit", zIndex: "0", position: "relative", textAlign: "start", margin: "5px", display: "inline-block", width: "auto" }}>

            <span style={{ transform: labelAnim ? "translateY(-10px) translateX(-10px)" : "translateY(25px)", paddingLeft: "12px", transition: "0.2s", width: "auto", backgroundColor: "transparent", display: "block", textAlign: "start", fontSize: (fontSize - 4) + "px", color: labelAnim ? localColor : theme["darkgray"], fontWeight: labelAnim ? "400" : "300" }}>{label === undefined ? "Label" : label}</span>
            <input disabled={disabled} defaultValue={type === "date" ? formatDate(value) : value} style={{ display: "inline-block", verticalAlign: "middle", paddingLeft: "10px", paddingTop: "10px", width: "auto", height: "auto", border: type !== "date" ? ("1px solid " + theme["gray"]) : error ? "1px solid red" : "1px solid " + theme["gray"], outline: "none", maxWidth: width + "px", maxHeight: height + "px", backgroundColor: disabled ? theme["gray"] : "transparent", display: "inline-block", minWidth: width + (size !== "block" ? "px" : ""), minHeight: height + "px", borderRadius: "5px", cursor: (onFocus ? "text" : "pointer"), textAlign: "start", color: theme["text"], fontSize: fontSize + "px", boxShadow: onFocus ? ("0px 0px 0px 3px " + hexToRgbA(localColor)) : "none", ...style }} onFocus={disabled ? null : () => { setOnFocus(true); setLabelAnim(true) }} onBlur={!enteredCal ? () => { setOnFocus(false); setLabelAnim(false) } : null} type={type === "password" ? "password" : "text"} ref={inputvalue} onChange={disabled ? null : handleChange} placeholder={type !== undefined ? placeholders[type] : placeholder} />
            {
                labelAnim && type === "date" && !error &&
                <div style={{ position: "absolute", top: (Number(height) + 40) + "px", overflow: "hidden" }} onMouseLeave={() => { setEnteredCal(false); if (onFocus && labelAnim) { setOnFocus(false); setLabelAnim(false) } }} onMouseEnter={() => { setEnteredCal(true) }}>
                    <Calendar color="secondary" value={dateCopy} onChange={setDateCopy} size={size} />
                </div>
            }
        </div>
    );
}

export default Input;
