import React, { useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import LikeComponent from '../../Components/Like';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Like() {
  const [checked, setChecked] = useState(true)

  return (
    <Grid>
      <Header>
        Like
      </Header>
      <Description>
      You can use various plug-ins that will enrich the images of your applications.
      </Description>
      <Subheader>
        Color
      </Subheader>
      <Description>
      To color the Like component, you can use the "color" prop. In the default case, it gets the value "gray". It can take the values "primary", "secondary", "success", "info", "warning", "alert", "drak", "gray", "dark gray".
      </Description>
      <Showroom>
        <div  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <LikeComponent color="primary"></LikeComponent>
        <LikeComponent color="secondary"></LikeComponent>
        <LikeComponent color="success"></LikeComponent>
        <LikeComponent color="info"></LikeComponent>
        <LikeComponent color="warning"></LikeComponent>
        <LikeComponent color="alert"></LikeComponent>
        <LikeComponent></LikeComponent>
        </div>
      </Showroom>
      <Coderoom>
      {
            "export default function Example(){\n"+
            "\treturn(\n"+
            "\t\t<Like color='primary'/> \n"+
            "\t\t<Like color='secondary'/> \n"+
            "\t\t<Like color='success'/>\n"+
            "\t\t<Like color='info'/>\n"+
            "\t\t<Like color='warning'/>\n"+
            "\t\t<Like color='alert'/>\n"+
            "\t\t<Like />\n"+
            "\t)\n"+
            "}"
        }
      </Coderoom>
      <Subheader>
        Checked
      </Subheader>
      <Description>
      Initially, you can decide how it should start with a full or empty one. Returns false in the default state. if "true" is full, "false" appears hollow.
      </Description>
      <Showroom>
      <div  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <LikeComponent color="primary" checked={true}></LikeComponent>
        <LikeComponent color="secondary" checked={false}></LikeComponent>
        <LikeComponent color="success" checked={true}></LikeComponent>
        <LikeComponent color="info" checked={false}></LikeComponent>
        <LikeComponent color="warning" checked={true}></LikeComponent>
        <LikeComponent color="alert" checked={false}></LikeComponent>
        </div>
      </Showroom>
      <Coderoom>
        {
            "export default function Example(){\n"+
            "\treturn(\n"+
            "\t\t<Like color='primary' checked={true}/>\n"+
            "\t\t<Like color='secondary'  checked={false}/>\n"+
            "\t\t<Like color='success' checked={true}/>\n"+
            "\t\t<Like color='info' checked={false}/>\n"+
            "\t\t<Like color='warning' checked={true}/>\n"+
            "\t\t<Like color='alert' checked={false}/>\n"+
            "\t)\n"+
            "}"
        }
      </Coderoom>
      <Subheader>
        onClick
      </Subheader>
      <Description>
      You can control the "checked" status during the click process.
      </Description>
      <Showroom>
      <div  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <LikeComponent color="primary" checked={checked} onClick={setChecked}></LikeComponent>
        <LikeComponent color="secondary" checked={checked} onClick={setChecked}></LikeComponent>
        <LikeComponent color="success" checked={checked} onClick={setChecked}></LikeComponent>
        <LikeComponent color="info" checked={checked} onClick={setChecked}></LikeComponent>
        <LikeComponent color="warning" checked={checked} onClick={setChecked}></LikeComponent>
        <LikeComponent color="alert" checked={checked} onClick={setChecked}></LikeComponent>
        </div>
      </Showroom>
      <Coderoom>
      {
            "export default function Example(){\n"+
            "\treturn(\n"+
            "\t\t<Like color='primary' checked={checked} onClick={setChecked}/>\n"+
            "\t\t<Like color='secondary'  checked={checked} onClick={setChecked}/>\n"+
            "\t\t<Like color='success' checked={checked} onClick={setChecked}/>\n"+
            "\t\t<Like color='info' checked={checked} onClick={setChecked}/>\n"+
            "\t\t<Like color='warning' checked={checked} onClick={setChecked}/>\n"+
            "\t\t<Like color='alert' checked={checked} onClick={setChecked}/>\n"+
            "\t)\n"+
            "}"
        }
      </Coderoom>
      <Subheader>Props</Subheader>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                          name: "color",
                          type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                          default: "gray", 
                          description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                        } ,
                        {
                          name:"checked",
                          type:"true \n false",
                          default:"false ",
                          description:"You can check the startup status."
                        },
                        {
                          name:"onClick",
                          type:"function",
                          default:"undefined",
                          description:"callback function called on click"
                        }
                      ]}  
                        
                    />
                </div>
      </div>
    </Grid>
  );
}

export default Like;
