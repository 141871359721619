import React, { useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import OptionComponent from "../../Components/Option";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";
import Select from "../../Components/Select";

function Option() {
  const [car, setCar] = useState(0);

  return (
    <Grid>
      <Header>Option</Header>
      <Description>
        We use the Option Component to add elements to the Select
        Component. Retrieves the properties used in the Select Component. Option component takes "fontSize", "height", "select",
        "style", "refs", "theme", "value", "children", "selectedColor" props in
        itself.
      </Description>
      <Showroom>
        <Select
          value={car}
          onChange={setCar}
          color="secondary"
          size="sm"
          label="Model"
        >
          <OptionComponent>Volvo</OptionComponent>
          <OptionComponent>Volkswagen</OptionComponent>
          <OptionComponent>Opel</OptionComponent>
          <OptionComponent>Mercedes Benz</OptionComponent>
          <OptionComponent>Volvo</OptionComponent>
        </Select>
      </Showroom>
      <Coderoom>
        {"export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Select color='secondary' label='Car Models' onChange={setCar} size='sm'>\n" +
          "\t\t\t<Option> Volvo </Option>\n" +
          "\t\t\t<Option> Volkswagen </Option>\n" +
          "\t\t\t<Option> Opel </Option>\n" +
          "\t\t\t<Option> Mercedes Benz </Option>\n" +
          "\t\t\t<Option> Audi </Option>\n" +
          "\t\t</Select>\n" +
          "\t)\n" +
          "}"}
      </Coderoom>
    </Grid>
  );
}

export default Option;
