import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import StatisticComponent from "../../Components/Statistic";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";
import Statistic2 from "../../Components/Statistic2";
import Statistic3 from "../../Components/Statistic3";

function Statistic() {


    return (
        <Grid>
            <Header>
                Statistic Component
            </Header>
            <Description>
            The Statistic Component is divided into three in itself. It has usage as Statistic, Static2 and Statistic3. 
            </Description> 
            <Description>
            Statistic takes props amount, unit, content, increase.Its image is as follows.
            </Description>
            <Showroom>
            <StatisticComponent unit={'$'} content={'Avarage Sale'} amount={'650'} />
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Statistic unit={'$'} content={'Avarage Sale'} amount={'650'} increase={false} />\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Description>
            Statistic2 takes color, header, text, currency props.Its image is as follows
            </Description>
            <Showroom>
                <Statistic2 color="primary" header="Milestone Reached" text="50,000" currency="₺"></Statistic2>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Statistic2 color='primary' header='Milestone Reached' text='50,000' currency='₺'/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Description>
            Statistic3 takes header, text, increase props.Its image is as follows
            </Description>
            <Showroom>
                <Statistic3 header='This Week' text='82.45%' increase={true}></Statistic3>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Statistic3 header={'This Week'} text={'82.45%'} increase={true}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>

            <Subheader>
                Statistic
            </Subheader>
            <Subheader>
                Unit
            </Subheader>
            <Description>
            You can input currency using "unit" props.
            </Description>
            <Showroom>
            <StatisticComponent unit={'$'} content={'Avarage Sale'} amount={'650'} />
            <StatisticComponent unit={'€'} content={'Avarage Sale'} amount={'650'} />
            <StatisticComponent unit={'₺'} content={'Avarage Sale'} amount={'650'} />
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Statistic unit={'$'} />\n" +
             "\t\t<Statistic unit={'€'} />\n" +
             "\t\t<Statistic unit={'₺'} />\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Amount
            </Subheader>
            <Description>
            You can give numerical values that you will use in statistics.
            </Description>
            <Showroom>
            <StatisticComponent unit={'$'} content={'Avarage Sale'} amount={'650'} />
            <StatisticComponent unit={'$'} content={'Avarage Sale'} amount={'1000'} />
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Statistic unit={'$'} amount={'650'}/>\n" +
             "\t\t<Statistic unit={'$'} amount={'1000'}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Content
            </Subheader>
            <Description>
            You can name the Statistic Component using "content" props.
            </Description>
            <Showroom>
            <StatisticComponent unit={'$'} content={'Avarage Sale'} amount={'650'} />
            <StatisticComponent unit={'€'} content={'Sale'} amount={'650'} />
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Statistic unit={'$'} amount={'650'} content={'Avarage Sale'}/>\n" +
             "\t\t<Statistic unit={'$'} amount={'650'} content={'Sale'}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            
            <Subheader>
                Increase
            </Subheader>
            <Description>
            You can use an increase or decrease indicator using "increase" props. 
            If "increase={true}", the text color will be "success". 
            If "increase={false}", the text color will be "alert".
            By default, the color used will be "dark".
            </Description>
            <Showroom>
            <StatisticComponent unit={'$'} content={'Avarage Sale'} amount={'650'} increase={false} />
            <StatisticComponent unit={'$'} content={'Avarage Sale'} amount={'650'} increase={true}/>
            <StatisticComponent unit={'$'} content={'Avarage Sale'} amount={'650'} />
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Statistic unit={'$'} content={'Avarage Sale'} amount={'650'} increase={false} />\n" +
             "\t\t<Statistic unit={'$'} content={'Avarage Sale'} amount={'650'} increase={true} />\n" +
             "\t\t<Statistic unit={'$'} content={'Avarage Sale'} amount={'650'} />\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Statistic2
            </Subheader>
            <Subheader>
                Color
            </Subheader>
            <Description>
            You can make the color of the component suitable for your theme using the color prop.It can take "primary", "secondary", "info", "warning", "success", "alert", "dark", "darkgray" values. Defaults to "dark".
            </Description>
            <Showroom>
                <Statistic2 color='primary' header='Milestone Reached' text='50,000' currency='$'></Statistic2>
                <Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='$'></Statistic2>
                <Statistic2 color='success' header='Milestone Reached' text='50,000' currency='$'></Statistic2>
                <Statistic2 color='dark' header='Milestone Reached' text='50,000' currency='$'></Statistic2>
                <Statistic2 color='alert' header='Milestone Reached' text='50,000' currency='$'></Statistic2>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Statistic2 color='primary'/>\n" +
             "\t\t<Statistic2 color='secondary'/>\n" +
             "\t\t<Statistic2 color='success'/>\n" +
             "\t\t<Statistic2 color='dark'/>\n" +
             "\t\t<Statistic2 color='alert'/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Header
            </Subheader>
            <Description>
            You can add a header to the Statistic2 Component using "header" props.
            </Description>
            <Showroom>
                <Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='$'></Statistic2>
                <Statistic2 color='secondary' header='Target Reached' text='50,000' currency='$'></Statistic2>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Statistic2 color='secondary' header='Milestone Reached'/>\n"+
                    "\t\t<Statistic2 color='secondary' header='Target Reached'/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                Text
            </Subheader>
            <Description>
            You can add a numeric value to your Statistic2 Component with "text" props.
            </Description>
            <Showroom>
                <Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='$'></Statistic2>
                <Statistic2 color='secondary' header='Milestone Reached' text='100,000' currency='$'></Statistic2>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Statistic2 color='secondary' header='Milestone Reached' text='50,000'/>\n"+
                    "\t\t<Statistic2 color='secondary' header='Milestone Reached' text='100,000'/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                Currency
            </Subheader>
            <Description>
            You can add currency values ​​to the component with "currency" props.
            </Description>
            <Showroom>
                <Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='$'></Statistic2>
                <Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='€'></Statistic2>
                <Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='₺'></Statistic2>
                <Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='£'></Statistic2>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='$'/>\n"+
                    "\t\t<Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='€'/>\n"+
                    "\t\t<Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='₺'/>\n"+
                    "\t\t<Statistic2 color='secondary' header='Milestone Reached' text='50,000' currency='£'/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                Statistic3
            </Subheader>
            <Description>
                It allows you to express the descent and ascent rates with an image, not graphically, but statistically.
            </Description>
            <Subheader>
                Header
            </Subheader>
            <Description>
            You can add a header to the Statistic3 Component using the "header" props.
            </Description>
            <Showroom>
                <Statistic3 header='This Week' text='82.45%' increase={true}></Statistic3>
                <Statistic3 header='This Month' text='82.45%' increase={true}></Statistic3>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Statistic3 header='This Week'/>\n"+
                    "\t\t<Statistic3 header='This Month'/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                Text 
            </Subheader>
            <Description>           
                Allows you to add numeric data that supports your statistic.
            </Description>
            <Showroom>
                <Statistic3 header='This Week' text='82.45%' increase={true}></Statistic3>
                <Statistic3 header='This Week' text='51.23%' increase={true}></Statistic3>
                <Statistic3 header='This Week' text='68.79%' increase={true}></Statistic3>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Statistic3 header='This Week' text='82.45%'/>\n"+
                    "\t\t<Statistic3 header='This Week' text='51.23%'/>\n"+
                    "\t\t<Statistic3 header='This Week' text='68.79%'/>\n"+
                    ""
                }
            </Coderoom>
            <Subheader>
                Increase
            </Subheader>
            <Description>
            You can express the direction of increase and decrease with the "increase" props.
            </Description>
            <Showroom>
                <Statistic3 header='This Week' text='82.45%' increase={true}></Statistic3>
                <Statistic3 header='This Week' text='82.45%' increase={false}></Statistic3>
                <Statistic3 header='This Week' text='82.45%' ></Statistic3>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Statistic3 header='This Week' text='82.45%' increase={true}/>\n"+
                    "\t\t<Statistic3 header='This Week' text='82.45%' increase={false}/>\n"+
                    "\t\t<Statistic3 header='This Week' text='82.45%' />\n"+
                    ""
                }
            </Coderoom>





            <Subheader>Statistic Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "unit",
                            type: "string", 
                            default: "undefined", 
                            description: "You can add a currency symbol."
                          },
                          {
                            name: "content",
                            type: "string", 
                            default: "undefined", 
                            description: "You can add content to the Statistic Component." 
                          },
                          {
                            name: "amount",
                            type: "integer", 
                            default: "undefined", 
                            description: "You can enter numeric values." 
                          },
                          {
                            name: "increase",
                            type: "true\nfalse", 
                            default: "undefined", 
                            description: "You can create an increase and decrease indicator." 
                          }
                      ]}  
                        
                    />
                </div>
            </div>

            <Subheader>Statistic2 Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                            default: "dark", 
                            description: "you can add color suitable for the theme"
                          },
                          {
                            name: "header",
                            type: "string", 
                            default: "undefined", 
                            description: "You can add a title." 
                          },
                          {
                            name: "text",
                            type: "integer", 
                            default: "undefined", 
                            description: "You can enter numeric values." 
                          },
                          {
                            name: "currency",
                            type: "string", 
                            default: "undefined", 
                            description: "You can add a currency symbol." 
                          }
                      ]}  
                        
                    />
                </div>
            </div>

            <Subheader>Statistic3 Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "header",
                            type: "string", 
                            default: "undefined", 
                            description: "You can add a title."
                          },
                          {
                            name: "text",
                            type: "string", 
                            default: "undefined", 
                            description: "You can add text." 
                          },
                          {
                            name: "increase",
                            type: "true\nfalse", 
                            default: "false", 
                            description: "You can add up and down arrows." 
                          }
                      ]}  
                        
                    />
                </div>
            </div>


        </Grid>     
    )
}
export default Statistic;