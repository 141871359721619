import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import TabsComponent from '../../Components/Tabs'
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";
import Tab from '../../Components/Tab'


function Tabs() {

  return(
    <Grid>
      <Header>
        Tabs
      </Header>
      <Description>
      Tabs allow switching between different views. Regulates navigation between groups of content that are related and at the same hierarchy level.
      </Description>
      <Subheader>
        Children
      </Subheader>
      <Description>
        We use the Tab Component as children. We can create the data that will meet us while navigating in the tab.
      </Description>
      <Showroom>
      <TabsComponent color="secondary">
        <Tab label="ITEM ONE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Children One
          </div>
          </Tab>

          <Tab label="ITEM TWO">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Children Two
          </div>
          </Tab>

          <Tab label="ITEM THREE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Children Three
          </div>
          </Tab>
        </TabsComponent>
      </Showroom>
      <Coderoom>
      {
        "export default function Example(){\n" +
        "\treturn(\n" +
        "\t\t<Tabs>\n" +
        "\t\t\t<Tab>\n"+
        "\t\t\t\t<div style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height: '300px', borderRadius: '15px' }}>\n"+
        "\t\t\t\t\t Children One\n"+
        "\t\t\t\t</div>\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab>\n"+
        "\t\t\t\t<div style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height: '300px', borderRadius: '15px' }}>\n"+
        "\t\t\t\t\t Children Two\n"+
        "\t\t\t\t</div>\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab>\n"+
        "\t\t\t\t<div style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height: '300px', borderRadius: '15px' }}>\n"+
        "\t\t\t\t\t Children Three\n"+
        "\t\t\t\t</div>\n"+
        "\t\t\t</Tab>\n"+
        "\t\t</Tabs>\n" +
        "\t)\n" +
        "}"
      }
      </Coderoom>
      <Subheader>
        Label
      </Subheader>
      <Description>
      We can add titles to tabs using label props in Tab Component.
      </Description>
      <Showroom>
      <TabsComponent color="secondary">
          <Tab label="Account">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Account
          </div>
          </Tab>

          <Tab label="Settings">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Account
          </div>
          </Tab>
        </TabsComponent>
      </Showroom>
      <Coderoom>
      {
        "export default function Example(){\n" +
        "\treturn(\n" +
        "\t\t<Tabs>\n" +
        "\t\t\t<Tab label='Account'>\n"+
        "\t\t\t\t<div style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height: '300px', borderRadius: '15px' }}>\n"+
        "\t\t\t\t\t Account\n"+
        "\t\t\t\t</div>\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='Setting'>\n"+
        "\t\t\t\t<div style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height: '300px', borderRadius: '15px' }}>\n"+
        "\t\t\t\t\t Account\n"+
        "\t\t\t\t</div>\n"+
        "\t\t\t</Tab>\n"+
        "\t\t</Tabs>\n" +
        "\t)\n" +
        "}"
      }
      </Coderoom>
      <Subheader>
        Color
      </Subheader>
      <Description>
      You can color the tabs according to your theme. The value you write in color props determines the color. .It can take "primary", "secondary", "success", "warning", "info", "alert", "dark", "light", "gray" and "darkgray" values.
      </Description>
      <Showroom>
        <TabsComponent color="primary" >
          <Tab label="ITEM ONE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item One
          </div>
          </Tab>

          <Tab label="ITEM TWO">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Two
          </div>
          </Tab>

          <Tab label="ITEM THREE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Three
          </div>
          </Tab>
        </TabsComponent>
        <TabsComponent color="secondary">
        <Tab label="ITEM ONE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item One
          </div>
          </Tab>

          <Tab label="ITEM TWO">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Two
          </div>
          </Tab>

          <Tab label="ITEM THREE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Three
          </div>
          </Tab>
        </TabsComponent>
        <TabsComponent color="alert">
        <Tab label="ITEM ONE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item One
          </div>
          </Tab>

          <Tab label="ITEM TWO">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Two
          </div>
          </Tab>

          <Tab label="ITEM THREE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Three
          </div>
          </Tab>
        </TabsComponent>
      </Showroom>
      <Coderoom>
      {
        "export default function Example(){\n" +
        "\treturn(\n" +
        "\t\t<Tabs color='primary'>\n" +
        "\t\t\t<Tab label='ITEM ONE'>\n"+
        "\t\t\t\t Item One\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM TWO'>\n"+
        "\t\t\t\t Item Two\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM THREE'>\n"+
        "\t\t\t\t Item Three\n"+
        "\t\t\t</Tab>\n"+
        "\t\t</Tabs>\n" +
        "\t\t<Tabs color='secondary'>\n" +
        "\t\t\t<Tab label='ITEM ONE'>\n"+
        "\t\t\t\t Item One\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM TWO'>\n"+
        "\t\t\t\t Item Two\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM THREE'>\n"+
        "\t\t\t\t Item Three\n"+
        "\t\t\t</Tab>\n"+
        "\t\t</Tabs>\n" +
        "\t\t<Tabs color='alert'>\n" +
        "\t\t\t<Tab label='ITEM ONE'>\n"+
        "\t\t\t\t Item One\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM TWO'>\n"+
        "\t\t\t\t Item Two\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM THREE'>\n"+
        "\t\t\t\t Item Three\n"+
        "\t\t\t</Tab>\n"+
        "\t\t</Tabs>\n" +
        "\t)\n" +
        "}"
      }
      </Coderoom>
      <Subheader>Index</Subheader>
      <Description>
      The value we write in the index determines which tab to start from when opening.
      </Description>
      <Showroom>
      <TabsComponent color="alert" index={1}>
        <Tab label="ITEM ONE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item One
          </div>
          </Tab>

          <Tab label="ITEM TWO">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Two
          </div>
          </Tab>

          <Tab label="ITEM THREE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Three
          </div>
          </Tab>

          <Tab label="ITEM FOUR">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Four
          </div>
          </Tab>
        </TabsComponent>
        <TabsComponent color="alert" index={0}>
        <Tab label="ITEM ONE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item One
          </div>
          </Tab>

          <Tab label="ITEM TWO">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Two
          </div>
          </Tab>

          <Tab label="ITEM THREE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Three
          </div>
          </Tab>

          <Tab label="ITEM FOUR">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Four
          </div>
          </Tab>
        </TabsComponent>

        <TabsComponent color="alert" index={3}>
        <Tab label="ITEM ONE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item One
          </div>
          </Tab>

          <Tab label="ITEM TWO">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Two
          </div>
          </Tab>

          <Tab label="ITEM THREE">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Three
          </div>
          </Tab>

          <Tab label="ITEM FOUR">
          <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "300px", borderRadius: "15px" }}>
            Item Four
          </div>
          </Tab>
        </TabsComponent>
      </Showroom>
      <Coderoom>
      {
        "export default function Example(){\n" +
        "\treturn(\n" +
        "\t\t<Tabs color='alert' index={1}>\n" +
        "\t\t\t<Tab label='ITEM ONE'>\n"+
        "\t\t\t\t Item One\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM TWO'>\n"+
        "\t\t\t\t Item Two\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM THREE'>\n"+
        "\t\t\t\t Item Three\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM FOUR'>\n"+
        "\t\t\t\t Item Four\n"+
        "\t\t\t</Tab>\n"+
        "\t\t</Tabs>\n" +
        "\t\t<Tabs color='alert' index={0}>\n" +
        "\t\t\t<Tab label='ITEM ONE'>\n"+
        "\t\t\t\t Item One\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM TWO'>\n"+
        "\t\t\t\t Item Two\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM THREE'>\n"+
        "\t\t\t\t Item Three\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM FOUR'>\n"+
        "\t\t\t\t Item Four\n"+
        "\t\t\t</Tab>\n"+
        "\t\t</Tabs>\n" +
        "\t\t<Tabs color='alert' index={3}>\n" +
        "\t\t\t<Tab label='ITEM ONE'>\n"+
        "\t\t\t\t Item One\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM TWO'>\n"+
        "\t\t\t\t Item Two\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM THREE'>\n"+
        "\t\t\t\t Item Three\n"+
        "\t\t\t</Tab>\n"+
        "\t\t\t<Tab label='ITEM FOUR'>\n"+
        "\t\t\t\t Item Four\n"+
        "\t\t\t</Tab>\n"+
        "\t\t</Tabs>\n" +
        "\t)\n" +
        "}"
      }
      </Coderoom>
      <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ width: "100%" }}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                            {
                                name: "color",
                                type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n",
                                default: " ",
                                description: "You should use 'color' props to apply the colors in your theme palette to the component."
                            },
                            {
                                name: "children",
                                type: "Tab",
                                default: " ",
                                description: "Tab Component"
                            },
                            {
                                name: "label",
                                type: "string",
                                default: " ",
                                description: "Tab--> label"
                            },
                            {
                                name: "onChange",
                                type: " ",
                                default: " ",
                                description: ""
                            },
                            {
                              name: "index",
                              type: "integer",
                              default: "{0}",
                              description: "determines which tab to start from"
                            }
                        ]}

                    />
                </div>
            </div>

    </Grid>
  )
}

export default Tabs;
