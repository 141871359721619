import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import PersonCardComponent from "../../Components/PersonCard";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function EmployedCard() {


    return (
        <Grid>
            <Header>
                Person Card
            </Header>
            <Description>
            You can easily create cards with employee information for a workplace or an organization here.
            </Description>
            <Showroom>
                <PersonCardComponent></PersonCardComponent>
            </Showroom>
            <Subheader>
            Header
            </Subheader>
            <Description>
            You can write the name and surname of the cardholder in the header.
            </Description>
            <Showroom>
            <div >
                <PersonCardComponent header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} />
            </div>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<PersonCard header={'Emma Smith'} />\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
            Content
            </Subheader>
            <Description>
            You can write the task undertaken by the card holder in the content.
            </Description>
            <Showroom>
            <div >
                <PersonCardComponent header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} />
            </div>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<PersonCard header={'Emma Smith'} content={'Project Manager'} />\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
            Src
            </Subheader>
            <Description>
            You can create the card owner's photo information by typing it into "src".
            </Description>
            <Showroom>
            <div >
                <PersonCardComponent header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} />
            </div>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<PersonCard header={'Emma Smith'} content={'Project Manager'} src={'avatar1.jpg'}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>onClick</Subheader>
            <Description>
            After the click process, you can specify which function to run. 
            </Description>
            <Showroom>
            <div >
                <PersonCardComponent header={'Emma Smith'} content={'Project Manager'} src={"avatar1.jpg"} onClick={()=>{console.log('clicked')}}/>
            </div>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<PersonCard header={'Emma Smith'} content={'Project Manager'} src={'avatar1.jpg'} onClick={()=>{console.log('clicked')}}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "header",
                            type: "string", 
                            default: "undefined", 
                            description: "It contains the name and surname of the cardholder." 
                          },
                          {
                            name: "content",
                            type: "string", 
                            default: "undefined", 
                            description: "You can write various information about the card holder." 
                          },
                          {
                            name: "src",
                            type: "string", 
                            default: "undefined", 
                            description: "You can add the image you want to use on the card." 
                          },
                          {
                            name: "onClick",
                            type: "function", 
                            default: "undefined", 
                            description: "You can specify the function you want to run." 
                          }
                      ]}  
                        
                    />
                </div>
            </div>
        </Grid>
        

    )

}
export default EmployedCard;