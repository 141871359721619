import React, { useState,useContext } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import CalendarComponent from '../../Components/Calendar';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";
import { ThemeContext } from "../../Components/ThemeProvider";

function Calendar() {
    const theme = useContext(ThemeContext);
    const [date, setDate] = useState(new Date())
    const [date1, setDate1] = useState(new Date())
    const [date2, setDate2] = useState(new Date())
    
    return (   
        <Grid>
            <Header>
                Calendar
            </Header>
            <Description>
            Can we say that adding a calendar to your applications has never been easier? With Calender Component, you can create a calendar in minutes.
            </Description>
            <Subheader>
                Color
            </Subheader>
            <Description>
            You can choose the colors suitable for your theme with the color property.It can take "primary", "secondary", "success", "warning", "info", "alert", "dark", "light", "gray" and "darkgray" values.
            </Description>
            <Showroom>
            <div  style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "auto", marginBottom: "200px", marginRight:"20px" }}>
                <h1 style={{color:(theme==="dark"?theme["light"]:theme["dark"])}}>{(date.getDate()<10 ? "0"+date.getDate(): date.getDate())}/{(date.getMonth()+1 <10? "0"+ (date.getMonth() +1) : date.getMonth() + 1 )}/{date.getFullYear()}</h1>
                <CalendarComponent color="alert" onChange={setDate} size="sm" />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "auto", marginBottom: "200px"}}>
                <h1 style={{color:(theme==="dark"?theme["light"]:theme["dark"])}}>{(date1.getDate()<10 ? "0"+date1.getDate(): date1.getDate())}/{(date1.getMonth()+1 <10? "0"+ (date1.getMonth() +1) : date1.getMonth() + 1 )}/{date1.getFullYear()}</h1>
                <CalendarComponent color="secondary" onChange={setDate1} size="sm" />
            </div>
            </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 'auto', marginBottom: '200px', marginRight:'20px' }}>\n"+
                    "\t\t\t<h1>{(date.getDate()<10 ? '0'+date.getDate(): date.getDate())}/{(date.getMonth()+1 <10? '0'+ (date.getMonth() +1) : date.getMonth() + 1 )}/{date.getFullYear()}</h1>\n"+
                    "\t\t\t<Calendar color='alert'/>\n"+
                    "\t\t</div>\n"+
                    "\t\t<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 'auto', marginBottom: '200px', marginRight:'20px' }}>\n"+
                    "\t\t\t<h1>{(date.getDate()<10 ? '0'+date.getDate(): date.getDate())}/{(date.getMonth()+1 <10? '0'+ (date.getMonth() +1) : date.getMonth() + 1 )}/{date.getFullYear()}</h1>\n"+
                    "\t\t\t<Calendar color='secondary'/>\n"+
                    "\t\t</div>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                Size
            </Subheader>
            <Description>
            You can size the calendars using the size prop.It can take the values "sm", "md" and "lg".
            </Description>
            <Showroom>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "auto", marginBottom: "200px" }}>
                <h1 style={{color:(theme==="dark"?theme["light"]:theme["dark"])}}>{(date.getDate()<10 ? "0"+date.getDate(): date.getDate())}/{(date.getMonth()+1 <10? "0"+ (date.getMonth() +1) : date.getMonth() + 1 )}/{date.getFullYear()}</h1>
                <CalendarComponent color="secondary" onChange={setDate} size="sm" />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "auto", marginBottom: "200px" }}>
                <h1 style={{color:(theme==="dark"?theme["light"]:theme["dark"])}}>{(date1.getDate()<10 ? "0"+date1.getDate(): date1.getDate())}/{(date1.getMonth()+1 <10? "0"+ (date1.getMonth() +1) : date1.getMonth() + 1 )}/{date1.getFullYear()}</h1>
                <CalendarComponent color="secondary" onChange={setDate1} size="md" />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "auto", marginBottom: "200px" }}>
                <h1 style={{color:(theme==="dark"?theme["light"]:theme["dark"])}}>{(date.getDate()<10 ? "0"+date2.getDate(): date2.getDate())}/{(date2.getMonth()+1 <10? "0"+ (date2.getMonth() +1) : date2.getMonth() + 1 )}/{date2.getFullYear()}</h1>
                <CalendarComponent color="secondary" onChange={setDate2} size="lg" />
            </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 'auto', marginBottom: '200px', marginRight:'20px' }}>\n"+
                    "\t\t\t<h1>{(date.getDate()<10 ? '0'+date.getDate(): date.getDate())}/{(date.getMonth()+1 <10? '0'+ (date.getMonth() +1) : date.getMonth() + 1 )}/{date.getFullYear()}</h1>\n"+
                    "\t\t\t<Calendar color='secondary' size='sm'/>\n"+
                    "\t\t</div>\n"+
                    "\t\t<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 'auto', marginBottom: '200px', marginRight:'20px' }}>\n"+
                    "\t\t\t<h1>{(date.getDate()<10 ? '0'+date.getDate(): date.getDate())}/{(date.getMonth()+1 <10? '0'+ (date.getMonth() +1) : date.getMonth() + 1 )}/{date.getFullYear()}</h1>\n"+
                    "\t\t\t<Calendar color='secondary' size='md'/>\n"+
                    "\t\t</div>\n"+
                    "\t\t<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 'auto', marginBottom: '200px', marginRight:'20px' }}>\n"+
                    "\t\t\t<h1>{(date.getDate()<10 ? '0'+date.getDate(): date.getDate())}/{(date.getMonth()+1 <10? '0'+ (date.getMonth() +1) : date.getMonth() + 1 )}/{date.getFullYear()}</h1>\n"+
                    "\t\t\t<Calendar color='secondary' size='lg'/>\n"+
                    "\t\t</div>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
            onChange
            </Subheader>
            <Description>

            </Description>
            <Showroom>
            <div  style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "auto", marginBottom: "200px", marginRight:"20px" }}>
                <h1 style={{color:(theme==="dark"?theme["light"]:theme["dark"])}}>{(date.getDate()<10 ? "0"+date.getDate(): date.getDate())}/{(date.getMonth()+1 <10? "0"+ (date.getMonth() +1) : date.getMonth() + 1 )}/{date.getFullYear()}</h1>
                <CalendarComponent color="secondary" onChange={setDate} size="sm" value={date} />
            </div>
            </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 'auto', marginBottom: '200px', marginRight:'20px' }}>\n"+
                    "\t\t\t<h1>{(date.getDate()<10 ? '0'+date.getDate(): date.getDate())}/{(date.getMonth()+1 <10? '0'+ (date.getMonth() +1) : date.getMonth() + 1 )}/{date.getFullYear()}</h1>\n"+
                    "\t\t<Calendar color='secondary' size='sm' onChange={setDate}/>\n"+
                    "\t\t</div>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                Value
            </Subheader>
            <Description>
            In the default state, it shows the date you are in. In case of changes, it shows the date you selected.
            </Description>
            <Showroom>
            <div  style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "auto", marginBottom: "200px", marginRight:"20px" }}>
                <h1 style={{color:(theme==="dark"?theme["light"]:theme["dark"])}}>{(date.getDate()<10 ? "0"+date.getDate(): date.getDate())}/{(date.getMonth()+1 <10? "0"+ (date.getMonth() +1) : date.getMonth() + 1 )}/{date.getFullYear()}</h1>
                <CalendarComponent color="secondary" onChange={setDate} size="sm" value={date}/>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "auto", marginBottom: "200px"}}>
                <h1 style={{color:(theme==="dark"?theme["light"]:theme["dark"])}}>{(date1.getDate()<10 ? "0"+date1.getDate(): date1.getDate())}/{(date1.getMonth()+1 <10? "0"+ (date1.getMonth() +1) : date1.getMonth() + 1 )}/{date1.getFullYear()}</h1>
                <CalendarComponent color="secondary" onChange={setDate1} size="sm" value={date1}/>
            </div>
            </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 'auto', marginBottom: '200px', marginRight:'20px'>\n"+
                    "\t\t\t<h1>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</h1>\n"+
                    "\t\t<Calendar color='secondary' size='sm' onChange={setDate} value={date}/>\n"+
                    "\t\t</div>\n"+
                    "\t\t<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 'auto', marginBottom: '200px', marginRight:'20px'>\n"+
                    "\t\t\t<h1>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</h1>\n"+
                    "\t\t<Calendar color='secondary' size='sm' onChange={setDate} value={date}/>\n"+
                    "\t\t</div>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                          name: "color",
                          type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                          default: "gray", 
                          description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                        },
                        {
                          name:"onChange",
                          type:"Function",
                          default:"undefined",
                          description:""
                        },
                        {
                          name:"size",
                          type:"sm \n md \n lg",
                          default:"sm",
                          description:"You can specify the size of the calendars with the 'size' props"
                        },
                        {
                          name:"value",
                          type:"Object\n'Date'",
                          default:"Today",
                          description:"you can see the date you have selected"
                        }
                      ]}  
                        
                    />
                </div>
            </div>

        </Grid>
    );
}

export default Calendar;
