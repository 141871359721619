import React, { useEffect, useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import ProgressComponent from "../../Components/Progress";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Progress() {

  const [progress, setProgress] = useState(0)
  const [badge, setBadge] = useState(0)
  useEffect(() => {
    setTimeout(() => {
        setBadge("5")
        setProgress(150)

    }, 500)

    setTimeout(() => {
        setBadge("7")
        setProgress(300)
    }, 2000)

    setTimeout(() => {
        setBadge("8")
        setProgress(450)
    }, 3000)

    setTimeout(() => {
        setBadge("9")
        setProgress(600)
    }, 4000)

    setTimeout(() => {
        setBadge("10")
        setProgress(700)
    }, 5000)
    setTimeout(() => {
        setBadge("11")
        setProgress(850)
    }, 6000)
    setTimeout(() => {
        setBadge("999+")
        setProgress(1000)
    }, 7000)

}, [])
    return (
      <Grid>
        <Header>
          Progress
        </Header>
        <Description>
        Progress indicators keep users informed about the status of ongoing processes such as installing an application, submitting a form, or saving updates. It uses the "color", "maxValue", "onFinished", "size", "type", and "value" props.
        </Description>
        <Showroom>
            <div style={{margin:"10px" }}>
                <ProgressComponent size="sm" maxValue={1000} value={progress} color="secondary" onFinished={() => console.log("fisihed2")} type="linear" />
            </div>
            <div style={{margin:"10px"}}>
                <ProgressComponent size="sm" maxValue={1000} value={progress} color="secondary" type="circular" onFinished={() => console.log("fisihed1")} />
            </div>
        </Showroom>
        <Subheader>
          Type
        </Subheader>
        <Description>
        With the "type" prop you can decide whether your progress will be displayed in a straight line or circular. It can take "circular" and "linear" values.
        </Description>
        <Showroom>
          
          <div style={{margin:"10px"}}>
          <ProgressComponent size="sm" maxValue={1000} value={progress} type="linear"></ProgressComponent>
          </div>
        
          <div style={{margin:"10px"}}>
          <ProgressComponent size="sm" maxValue={1000} value={progress} type="circular"></ProgressComponent>
          </div>
          
        </Showroom>
        <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Progress type='linear' />\n" +
          "\t\t<Progress type='circular' />\n" +
          "\t)\n" +
          "}"
        }
        </Coderoom>
        <Subheader>
          Color
        </Subheader>
        <Description>
        You can combine your progress indicators with colors suitable for your theme by using the color prop.It can take "primary", "secondary", "info", "warning", "success", "alert", "dark", "darkgray" values. Defaults to "dark".
        </Description>
        <Showroom>
          <div style={{display:"flex"}}>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="primary" size="sm" maxValue={1000} value={progress} type="linear"></ProgressComponent>
          </div>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="secondary" size="sm" maxValue={1000} value={progress} type="linear"></ProgressComponent>
          </div>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="success" size="sm" maxValue={1000} value={progress} type="linear"></ProgressComponent>
          </div>
          </div>
          <div style={{display:"flex"}}>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="dark" size="sm" maxValue={1000} value={progress} type="circular"></ProgressComponent>
          </div>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="alert" size="sm" maxValue={1000} value={progress} type="circular"></ProgressComponent>
          </div>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="info" size="sm" maxValue={1000} value={progress} type="circular"></ProgressComponent>
          </div>
          </div>  
        </Showroom>
        <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Progress type='linear' color='primary' />\n" +
          "\t\t<Progress type='linear' color='secondary' />\n" +
          "\t\t<Progress type='linear' color='success' />\n" +
          "\t\t<Progress type='circular' color='dark' />\n" +
          "\t\t<Progress type='circular' color='alert' />\n" +
          "\t\t<Progress type='circular' color='info' />\n" +
          "\t)\n" +
          "}"
        }
        </Coderoom>
        <Subheader>
          Size
        </Subheader>
        <Description>
        It can take "sm", "md" and "lg" values. You can easily adjust the size suitable for your theme with the size prop.
        </Description>
        <Showroom>
        
          <div style={{margin:"10px"}}>
          <ProgressComponent color="secondary" size="sm" maxValue={1000} value={progress} type="linear"></ProgressComponent>
          </div>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="secondary" size="md" maxValue={1000} value={progress} type="linear"></ProgressComponent>
          </div>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="secondary" size="lg" maxValue={1000} value={progress} type="linear"></ProgressComponent>
          </div>
          
         
          <div style={{margin:"10px"}}>
          <ProgressComponent color="secondary" size="sm" maxValue={1000} value={progress} type="circular"></ProgressComponent>
          </div>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="secondary" size="md" maxValue={1000} value={progress} type="circular"></ProgressComponent>
          </div>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="secondary" size="lg" maxValue={1000} value={progress} type="circular"></ProgressComponent>
          </div>
          
        </Showroom>
        <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Progress type='linear' color='secondary' size='sm' />\n" +
          "\t\t<Progress type='linear' color='secondary' size='md' />\n" +
          "\t\t<Progress type='linear' color='secondary' size='lg' />\n" +
          "\t\t<Progress type='circular' color='secondary' size='sm' />\n" +
          "\t\t<Progress type='circular' color='secondary' size='md' />\n" +
          "\t\t<Progress type='circular' color='secondary' size='lg' />\n" +
          "\t)\n" +
          "}"
        }
        </Coderoom>
        <Subheader>
          Value and maxValue
        </Subheader>
        <Description>
        You can specify the progress values ​with the "value" props. You can determine how much progress it will show with the "maxValue" props.
        </Description>
        <Showroom>
        <div style={{margin:"10px"}}>
          <ProgressComponent color="secondary" size="sm" maxValue={1000} value={progress} type="circular"></ProgressComponent>
          </div>
          <div style={{margin:"10px"}}>
          <ProgressComponent color="secondary" size="md" maxValue={1000} value={progress} type="linear"></ProgressComponent>
          </div>
        </Showroom>
        <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Progress maxValue={1000} value={progress} color='secondary' type='circular' size='sm' />\n" +
          "\t\t<Progress maxValue={1000} value={progress} color='secondary' type='linear' size='sm' />\n" +
          "\t)\n" +
          "}"
        }
        </Coderoom>
        <Subheader>
          onFinished
        </Subheader>
        <Description>
        We can write it in the onFinished prop how we want it to react when the progress is finished.
        </Description>
        <Showroom>
        <div style={{margin:"10px"}}>
          <ProgressComponent size="sm" maxValue={1000} value={progress} color="secondary" type="circular" onFinished={() => console.log("finished")} />
        </div>
        <div style={{margin:"10px"}}>
          <ProgressComponent size="sm" maxValue={1000} value={progress} color="secondary" type="linear" onFinished={() => console.log("finished")} />
        </div>
        
        </Showroom>
        <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Progress size='sm' maxValue={1000} value={progress} color='secondary' type='circular' onFinished={() => console.log('finished')} />\n" +
          "\t\t<Progress size='sm' maxValue={1000} value={progress} color='secondary' type='linear' onFinished={() => console.log('finished')} />\n" +
          "\t)\n" +
          "}"
        }
        </Coderoom>
        <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "type",
                            type: "circular\nlinear", 
                            default: "undefined", 
                            description: "You can set the progress display."
                          },
                          {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray", 
                            default: "dark", 
                            description: "You can color the progress indicators." 
                          },
                          {
                            name: "size",
                            type: "sm\nmd\nlg", 
                            default: "undefined", 
                            description: "You can give dimensions according to your theme." 
                          },
                          {
                            name: "value",
                            type: "object", 
                            default: "undefined", 
                            description: "You can set the progress value." 
                          },
                          {
                            name: "maxValue",
                            type: "integer", 
                            default: "undefined", 
                            description: "You must specify a maximum progress value." 
                          },
                          {
                            name: "onFinished",
                            type: "function", 
                            default: "undefined", 
                            description: "Once the progress is complete, you can decide what to do. " 
                          }
                      ]}  
                        
                    />
                </div>
            </div>

      </Grid>
    );
  }

export default Progress;
