/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************

color ---> primary || secondary || success || info || warning || alert || dark || darkgray || gray(default)

content ---> "String"

children ---> "String" 

showContent ---> true(default) || false

open ---> true(default) || false

position ---> TOP_LEFT || TOP_RIGHT || BOTTOM_LEFT || BOTTOM_RIGHT

style ---> It works like style attribute.

*/
import { injectStyle } from "../Theme";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../ThemeProvider";
import { colorTypes, childrenTypes, styleTypes, openTypes, contentTypes, badgePositionTypes, showContentTypes,themeProviderCheck } from "../ErrorHandler";

function Badge({color, content, showContent, open, position, style, children}) {
    const theme = useContext(ThemeContext);

    const [localColor, setLocalColor] = useState(theme["gray"])

    const [top, setTop] = useState(-18)
    const [left, setLeft] = useState("98%")

    const [width, setWidth] = useState(15)
    const [height, setHeight] = useState(15)

    const [onNew, setOnNew] = useState(false)

    const firstRender = useRef(0)

    const element = useRef(null)
    const toolTip = useRef(null)


    useEffect(()=>{

        colorTypes.check(color)
        childrenTypes.check(children)
        styleTypes.check(style)
        openTypes.check(open)
        contentTypes.check(content)
        showContentTypes.check(showContent)
        badgePositionTypes.check(position)
        themeProviderCheck.check(theme)

      },[color, children, style, open, content, showContent, position, theme])


    useEffect(() => {


        setOnNew(true)

        setTimeout(() => {
            setOnNew(false)
        }, 500)



    }, [content])

    const keyframesStyle = `
    @-webkit-keyframes badgeAnimation {
      from {transform:scale(0);}
      to {transform:scale(1);}
    }

  `;

    injectStyle(keyframesStyle);

    useEffect(() => {

        if (position !== undefined && open !== false) {

            let elementHeight = element.current.clientHeight;
            let elementWidth = element.current.clientWidth;

            let toolHeight = toolTip.current.clientHeight;
            let toolWidth = toolTip.current.clientWidth;


            if (position === "BOTTOM_LEFT") {
                setTop(elementHeight)
                setLeft(-toolWidth)
            }
            else if (position === "TOP_LEFT") {
                setTop(-toolHeight)
                setLeft(-toolWidth)
            }
            else if (position === "TOP_RIGHT") {
                setTop(-toolHeight)
                setLeft(elementWidth)
            }
            else if (position === "BOTTOM_RIGHT") {
                setTop(elementHeight)
                setLeft(elementWidth)
            }

        }
    }, [position,open])



    useEffect(() => {

        if (color !== undefined) {
            setLocalColor(theme[color])
        }
    }, [theme,color])

    
    return (
        <div ref={element} style={{ position: "relative", width: "auto", height: "auto", display: "inline-block", margin: "25px" }}>
            {
                open !== false &&
                <div ref={toolTip} style={{ position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", transform: (!onNew ? "scale(0.9)" : "scale(1.1)"), transition: "0.1s", minWidth: width + "px", backgroundColor: localColor, minHeight: height + "px", color: theme["light"], boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", borderRadius: "15px", left: left, top: top, fontSize: "12px", fontWeight: "bold", cursor: "pointer", ...style }}><span style={{ display: "inline-block", textAlign: "center", zIndex: "102", minWidth: width + "px", minHeight: height + "px", height: "100%", margin: "3px", textAlign: "center" }}>{showContent !== false ? content : ""}</span></div>

            }
            {
                children
            }
        </div>
    );
}

export default Badge;
