import './App.css';

import React from 'react';
import Layout from './Layout';
import { ThemeProvider } from './Components/ThemeProvider';
import { Route, Routes } from 'react-router-dom';
import Login from './Menu/Login';

//TODO: Linechart


function App() {

  return (
    <ThemeProvider pallets={[{
      "primary": "#405189",
      "secondary": "#7d8bba",
      "dark": "#222",
      "success": "#26D96C",
      "warning": "#FFDB00",
      "alert": "#E20D0D",
      "info": "#68E6FB",
      "light": "#fff",
      "gray": "#ccc",
      "darkgray": "#aaa",
      "leftMenu": "#405189",
      "topMenu": "#fff",
      "content": "#f3f3f9",
      "text": "#000",
      "textGray": "#ccc",
    },
    {
      "primary": "#405189",
      "secondary": "#7d8bba",
      "dark": "#222",
      "success": "#26D96C",
      "warning": "#FFDB00",
      "alert": "#E20D0D",
      "info": "#68E6FB",
      "light": "#fff",
      "gray": "#ccc",
      "darkgray": "#aaa",
      "leftMenu": "#212529",
      "topMenu": "#292e32",
      "content": "#313437",
      "text": "#fff",
      "textGray": "#ccc",
    },
    {
      "primary": "#007FFF",
      "secondary": "#89CFF0",
      "dark": "#222",
      "success": "#26D96C",
      "warning": "#FFDB00",
      "alert": "#E20D0D",
      "info": "#68E6FB",
      "light": "#fff",
      "gray": "#ccc",
      "darkgray": "#aaa",
      "leftMenu": "#00308F",
      "topMenu": "#fff",
      "content": "#f3f3f9",
      "text": "#000",
      "textGray": "#ccc",
    },
    {
      "primary": "#007FFF",
      "secondary": "#7CB9E8",
      "dark": "#222",
      "success": "#26D96C",
      "warning": "#FFDB00",
      "alert": "#E20D0D",
      "info": "#68E6FB",
      "light": "#fff",
      "gray": "#ccc",
      "darkgray": "#aaa",
      "leftMenu": "#1c1c1c",
      "topMenu": "#2e2e2e",
      "content": "#3c3c3c",
      "text": "#fff",
      "textGray": "#ccc",
    },


    ]}

      isWarningsOn={false}

      calendar={{
        weekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        months: ["Januray", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      }
      }

    >
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Layout />} />
      </Routes>

    </ThemeProvider>
  );
}

export default App;
