/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************

color ---> primary || secondary || success || info || warning || alert || dark || darkgray || gray(default)

shape ---> circle(default) || square 

size ---> sm(default) || md || lg 

active ---> true || false(default)

src ---> 'String' - undefined(default)

onClick ---> function - undefined(default)

style ---> It works like style attribute in addition we added new object called "active" and it has style object too. You can style active circle with this style object. - undefined(default)

        [EXAMPLE]

        style :{
            color:"red", backgroundColor:"black",
            active : {
                style: {
                    backgroundColor:"blue"
                }
            }
        }


*/

import React, { useState, useEffect, useContext } from "react";
import { avatarSize, hexToRgbA, injectStyle } from "../Theme";
import { ThemeContext } from "../ThemeProvider";
import { colorTypes, sizeTypes, srcTypes, styleTypes, shapeTypes, activeTypes, functionTypes } from "../ErrorHandler";
function Avatar({color, shape, size, active, src, onClick, style}) {

    const theme = useContext(ThemeContext);
    const [localColor, setLocalColor] = useState(theme["gray"])
    const [width, setWidth] = useState("40")
    const [height, setHeight] = useState("40")
    const [mouseIn, setMouseIn] = useState(false)

    useEffect(()=>{

        colorTypes.check(color)
        sizeTypes.check(size)
        srcTypes.check(src)
        styleTypes.check(style)
        shapeTypes.check(shape)
        activeTypes.check(active)
        functionTypes.check(onClick)
        

      },[])


    useEffect(() => {

        if (color !== undefined) {
            setLocalColor(theme[color])
        }

    }, [theme,color])


    useEffect(() => {

        if (size !== undefined) {
            setHeight(avatarSize[size].height)
            setWidth(avatarSize[size].width)
        }


    }, [size])



    const keyframesStyle = `
    @-webkit-keyframes openAnimationAvatar {
      from {transform:scale(0)}
      to {transform:scale(1)}
    }
  `;

    injectStyle(keyframesStyle);

    return (
        <div onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)} onClick={(e) => {
            if (onClick !== undefined) {
                e.preventDefault(); onClick(); e.stopPropagation();

            }

        }} style={{ position: "relative", WebkitAnimation: 'openAnimationAvatar 0.3s', border: "1px solid " + theme["gray"], margin: "10px", cursor: "pointer", boxShadow: mouseIn ? ("0px 0px 0px 3px " + hexToRgbA(localColor)) : "rgba(0, 0, 0, 0.3) 0px 3px 7px -3px", backgroundColor: localColor, width: width + "px", height: height + "px", display: "flex", borderRadius: shape === "square" ? "5px" : ((shape === "circle" || shape === undefined) ? (width) + "px" : "0px"), alignItems: "center", justifyContent: "center", ...style }}>
            {
                src === undefined &&
                <svg width={Number(width - 15)} height={Number(height - 15)} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6274 8C21.6274 10.7614 19.3888 13 16.6274 13C13.866 13 11.6274 10.7614 11.6274 8C11.6274 5.23858 13.866 3 16.6274 3C19.3888 3 21.6274 5.23858 21.6274 8ZM22.6274 8C22.6274 11.3137 19.9411 14 16.6274 14C13.3137 14 10.6274 11.3137 10.6274 8C10.6274 4.68629 13.3137 2 16.6274 2C19.9411 2 22.6274 4.68629 22.6274 8ZM26.1943 24.9615C26.3384 25.3895 26.4639 25.8262 26.5703 26.2697C26.8952 27.624 25.7518 29 24.0083 29H16.6287H9.2492C7.50565 29 6.36224 27.624 6.68718 26.2697C6.79361 25.8262 6.91907 25.3895 7.06314 24.9615C7.59955 23.3683 8.38204 21.9322 9.35818 20.7313C10.3341 19.5306 11.4815 18.5919 12.728 17.9567C13.9734 17.322 15.2981 17 16.6287 17C17.9594 17 19.2841 17.322 20.5295 17.9567C21.776 18.5919 22.9234 19.5306 23.8993 20.7312C24.8754 21.9322 25.6579 23.3683 26.1943 24.9615ZM24.0083 30C26.2174 30 28.0581 28.1846 27.5427 26.0364C27.4294 25.5643 27.2958 25.099 27.1421 24.6424C26.5702 22.9439 25.732 21.4005 24.6753 20.1005C23.6186 18.8005 22.3641 17.7693 20.9835 17.0657C19.6029 16.3621 18.1231 16 16.6287 16C15.1344 16 13.6546 16.3621 12.274 17.0657C10.8933 17.7693 9.63888 18.8005 8.58219 20.1005C7.5255 21.4005 6.68729 22.9439 6.11542 24.6424C5.96171 25.099 5.82806 25.5643 5.71478 26.0364C5.19935 28.1846 7.04006 30 9.2492 30H16.6287H24.0083Z" fill={style === undefined ? theme["light"] : (style.color !== undefined ? style.color : theme["light"])} />
                </svg>
            }
            {
                src !== undefined &&
                <div style={{ width: "100%", height: "100%", overflow: "hidden", borderRadius: "inherit" }}>
                    <img src={src} style={{ width: "auto", height: "100%", borderRadius: "inherit" }} alt={src}/>
                </div>
            }
            {
                active !== undefined && active &&
                <div style={{ position: "absolute", width: (Number(width) / 4) + "px", WebkitAnimation: 'openAnimationAvatar 0.3s', height: (Number(height) / 4) + "px", backgroundColor: theme["success"], bottom: "-4px", right: "5px", border: "2px solid white", borderRadius: (Number(height) / 2), ...(style !== undefined ? style.active.style : null) }}></div>
            }
        </div>
    );
}

export default Avatar;
