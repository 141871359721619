import React, { useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import PaginationComponent from "../../Components/Pagination";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Pagination() {

  const [selected, setSelected] = useState(1)
  const [selected1, setSelected1] = useState(1)
  const [selected2, setSelected2] = useState(1)
  const [selected3, setSelected3] = useState(1)
  const [selected4, setSelected4] = useState(1)
  const [selected5, setSelected5] = useState(1)
  const [selected6, setSelected6] = useState(1)

    return(
        <Grid>
            <Header>
                Pagination
            </Header>
            <Description>
            Pagination is the process of separating digital content, i.e. web pages, into separate pages.
            </Description>
            <Showroom>
                <PaginationComponent total={50} size={5} position={"center"} page={selected} onChange={setSelected}></PaginationComponent>
            </Showroom>

            <Subheader>
                Total
            </Subheader>
            <Description>
            Specifies the number of pages we want to add in total. It must be integer.
            </Description>
            <Showroom>
            <PaginationComponent total={500} size={1} position={"center"} page={selected1} type={"filled"} onChange={setSelected1}></PaginationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Pagination total={500}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Size
            </Subheader>
            <Description>
            You can specify how many panes you want to display the total number of pages with "size" props.
            </Description>
            <Showroom>
            <PaginationComponent total={500} size={1} position={"center"} page={selected2} type={"filled"} onChange={setSelected2}></PaginationComponent>
            <PaginationComponent total={500} size={5} position={"center"} page={selected2} type={"filled"} onChange={setSelected2}></PaginationComponent>
            <PaginationComponent total={500} size={10} position={"center"} page={selected2} type={"filled"} onChange={setSelected2}></PaginationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Pagination total={500} size={1}/>\n" +
             "\t\t<Pagination total={500} size={5}/>\n" +
             "\t\t<Pagination total={500} size={10}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Page
            </Subheader>
            <Description>
            You can specify the page number you want to show as selected with "page" props.
            </Description>
            <Showroom>
            <PaginationComponent total={50} size={1} position={"center"} page={2} type={"filled"} onChange={setSelected}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={7} type={"filled"} onChange={setSelected}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={25} type={"filled"} onChange={setSelected}></PaginationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Pagination total={50} size={1} page={2}/>\n" +
             "\t\t<Pagination total={50} size={1} page={5}/>\n" +
             "\t\t<Pagination total={50} size={1} page={15}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
                Color
            </Subheader>
            <Description>
            You can apply colors suitable for your theme using our palette.It can take "primary", "secondary", "info", "warning", "success", "alert", "dark", "darkgray" values. Defaults to "secondary".
            </Description>
            <Showroom>
            <PaginationComponent total={50} size={1} position={"center"} page={selected3} type={"filled"} color={"primary"} onChange={setSelected3}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={selected3} type={"filled"} color={"dark"} onChange={setSelected3}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={selected3} type={"filled"} color={"secondary"} onChange={setSelected3}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={selected3} type={"filled"} color={"success"} onChange={setSelected3}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={selected3} type={"filled"} color={"darkgray"} onChange={setSelected3}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={selected3} type={"filled"} color={"gray"} onChange={setSelected3}></PaginationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Pagination total={50} size={1} page={5} color={'primary'}/>\n" +
             "\t\t<Pagination total={50} size={1} page={5} color={'dark'}/>\n" +
             "\t\t<Pagination total={50} size={1} page={5} color={'secondary'}/>\n" +
             "\t\t<Pagination total={50} size={1} page={5} color={'success'}/>\n" +
             "\t\t<Pagination total={50} size={1} page={5} color={'darkgray'}/>\n" +
             "\t\t<Pagination total={50} size={1} page={5} color={'gray'}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
             Position   
            </Subheader>
            <Showroom>
            <PaginationComponent total={50} size={1} position={"right"} page={selected4} type={"filled"} color={"secondary"} onChange={setSelected4}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={selected4} type={"filled"} color={"secondary"} onChange={setSelected4}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"left"} page={selected4} type={"filled"} color={"secondary"} onChange={setSelected4}></PaginationComponent> 
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Pagination total={50} size={1} page={5} color={'secondary'} position={'right'}/>\n" +
             "\t\t<Pagination total={50} size={1} page={5} color={'secondary'} position={'center'}/>\n" +
             "\t\t<Pagination total={50} size={1} page={5} color={'secondary'} position={'left'}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
              onChange
            </Subheader>
            <Description>
            You can switch between pages using the onChange props.
            </Description>
            <Showroom>
            <PaginationComponent total={50} size={1} position={"right"} page={selected} type={"filled"} color={"secondary"} onChange={setSelected}></PaginationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Pagination total={50} size={1} page={selected} color={'secondary'} position={'center'} onChange={setSelected}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
              Type
            </Subheader>
            <Description>
            You can specify pagination types with the "type" prop. It has the same types as the buttons. It can take "filled", "lined", "text" values. It takes filled value by default.
            </Description>
            <Showroom>
            <PaginationComponent total={50} size={1} position={"center"} page={selected5} type={"filled"} color={"secondary"} onChange={setSelected5}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={selected5} type={"lined"} color={"secondary"} onChange={setSelected5}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={selected5} type={"text"} color={"secondary"} onChange={setSelected5}></PaginationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Pagination total={50} size={1} page={selected} type={'filled'} color={'secondary'} position={'center'} onChange={setSelected}/>\n" +
             "\t\t<Pagination total={50} size={1} page={selected} type={'lined'} color={'secondary'} position={'center'} onChange={setSelected}/>\n" +
             "\t\t<Pagination total={50} size={1} page={selected} type={'text'} color={'secondary'} position={'center'} onChange={setSelected}/>\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>
            <Subheader>
              Disabled
            </Subheader>
            <Description>
            We can use disabled props to turn off the click action on the pagination component. It is false by default.
            </Description>
            <Showroom>
            <PaginationComponent total={50} size={1} position={"center"} page={selected6} type={"filled"} color={"secondary"} onChange={setSelected6} disabled={true}></PaginationComponent>
            <PaginationComponent total={50} size={1} position={"center"} page={selected6} type={"filled"} color={"secondary"} onChange={setSelected6} disabled={false}></PaginationComponent>
            </Showroom>
            <Coderoom>
            {
             "export default function Example(){\n" +
             "\treturn(\n" +
             "\t\t<Pagination total={50} size={1} page={selected} type={'filled'} color={'secondary'} position={'center'} onChange={setSelected} disabled={true} />\n" +
             "\t\t<Pagination total={50} size={1} page={selected} type={'filled'} color={'secondary'} position={'center'} onChange={setSelected} disabled={false} />\n" +
             "\t)\n" +
             "}"
            }
            </Coderoom>

            <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray", 
                            default: "gray", 
                            description: "You can give the colors that exist in the palette to the text field."
                          },
                          {
                            name: "position",
                            type: "right\nleft\ncenter", 
                            default: "undefined", 
                            description: "You can specify the pagination location." 
                          },
                          {
                            name: "onChange",
                            type: "function", 
                            default: "undefined", 
                            description: "You can add function to component." 
                          },
                          {
                            name: "size",
                            type: "integer", 
                            default: "undefined", 
                            description: "" 
                          },
                          {
                            name: "page",
                            type: "integer", 
                            default: "undefined", 
                            description: "You can specify the selected page here." 
                          },
                          {
                            name: "total",
                            type: "integer", 
                            default: "undefined", 
                            description: " " 
                          },
                          {
                            name: "type",
                            type: "filled\nlined\ntext", 
                            default: "undefined", 
                            description: "You can choose pagination type." 
                          },
                          {
                            name: "disabled",
                            type: "true\nfalse", 
                            default: "false", 
                            description: "You can activate or deactivate the click process." 
                          }
                      ]}  
                        
                    />
                </div>
            </div>


            
        </Grid>
    )
}
export default Pagination;
