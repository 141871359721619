import React, { useEffect, useState } from "react";

function Grid(props) {


    return (
        <div style={{ minHeight: "99%"}}>

            <div style={{ height: "auto", maxWidth:"900px", margin:"0 auto" }}>
                {
                    props.children
                }
            </div>

        </div>
    )
}
export default Grid;