import React, { useEffect, useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import AvatarComponent from '../../Components/Avatar'
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Avatar() {



    return (
        <Grid>
            <Header>
                Avatar
            </Header>
            <Description>
                You can use the Avatar Component while designing various menus. It can take properties like "color", "shape", "size", "active" and "onClick" with props.
            </Description>
            
            <Subheader>
               Color
            </Subheader>
            <Description>
                You should use "color" props to apply the colors in your theme palette to the component.It can take "primary", "secondary", "success", "warning", "info", "alert", "dark", "light", "gray" and "darkgray" values.
            </Description>
            <Showroom>
            <div style={{display:"flex",alignItems:"center"}}>
                <AvatarComponent color="primary" />
                <AvatarComponent color="secondary" />
                <AvatarComponent color="success" />
                <AvatarComponent color="info" />
                <AvatarComponent color="alert" />
            </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Avatar color='primary'/>\n"+
                    "\t\t<Avatar color='secondary'/>\n"+
                    "\t\t<Avatar color='success'/>\n"+
                    "\t\t<Avatar color='info'/>\n"+
                    "\t\t<Avatar color='alert'/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>
            <Subheader>
                Shapes
            </Subheader>
            <Description>
                You can adjust the border image of your avatars with the "shape" prop. It can take the value of "circle" and "square".
            </Description>
            <Showroom>
                <div style={{display:"flex",alignItems:"center"}}>
                    <AvatarComponent color="secondary" shape="circle" />
                    <AvatarComponent color="secondary" shape="square" />
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Avatar color='secondary' shape='circle'/>\n"+
                    "\t\t<Avatar color='secondary' shape='square'/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                Image
            </Subheader>
            <Description>
            You can add a picture to your avatar.
            </Description>
            <Showroom>
                <div style={{display:"flex",alignItems:"center"}}>
                    <AvatarComponent color="secondary" shape="circle" src="avatar1.jpg" />
                    <AvatarComponent color="secondary" shape="circle" />
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Avatar color='secondary' shape='circle' src='avatar1.jpg'/>\n"+
                    "\t\t<Avatar color='secondary' shape='circle'/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                Sizes
            </Subheader>
            <Description>
                You can specify the size of the avatars with the "size" props. It can take "lg", "md" and "sm" values.
            </Description>
            <Showroom>
                <div style={{display:"flex",alignItems:"center"}}>
                    <AvatarComponent color="secondary" size="lg" />
                    <AvatarComponent color="secondary" size="md" />
                    <AvatarComponent color="secondary" size="sm" />
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Avatar color='secondary' size='lg'/>\n"+
                    "\t\t<Avatar color='secondary' size='md'/>\n"+
                    "\t\t<Avatar color='secondary' size='sm'/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
                Active
            </Subheader>
            <Description>
                With these props you can add active badge to your avatar. False in default state.
            </Description>
            <Showroom>
                <div style={{display:"flex",alignItems:"center"}}>
                    <AvatarComponent color="secondary" active={true}/>
                    <AvatarComponent color="secondary" active={false}/>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<div style={{display:'flex',alignItems:'center'}}>\n"+
                    "\t\t\t<Avatar color='secondary' active={true}/>\n"+
                    "\t\t\t<Avatar color='secondary' active={false}/>\n"+
                    "\t\t</div>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            
            <Subheader>
                onClick
            </Subheader>
            <Description>
                With the "onClick" option, you can determine how it will react after the click. 
            </Description>
            <Showroom>
                <div style={{display:"flex",alignItems:"center"}}>
                    <AvatarComponent color="secondary" active={true} onClick={()=>{alert('Hello!')}} src="avatar1.jpg"/>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Avatar color='secondary' active={true}  onClick={()=>{alert('Hello!')}}/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>Props</Subheader>

                
            
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                          name: "color",
                          type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                          default: "gray", 
                          description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                        } ,
                        {
                          name:"shape",
                          type:"circle\nsquare",
                          default:"circle",
                          description:"Determines the border shape of your avatar."
                        },
                        {
                          name:"size",
                          type:"sm \n md \n lg",
                          default:"sm",
                          description:"You can specify the size of the avatars with the 'size' props"
                        },
                        {
                          name:"active",
                          type:"true\nfalse",
                          default:"false",
                          description:"With these props you can add active badge to your avatar."
                        },
                        {
                          name:"onClick",
                          type:"Function",
                          default:"undefined",
                          description:"With the 'onClick' option, you can determine how it will react after the click."
                        },
                        {
                          name:"src",
                          type:"string",
                          default:"undefined",
                          description:"It adds an image to your avatar."
                        }
                      ]}  
                        
                    />
                </div>
            </div>
        </Grid>

    )
}
export default Avatar;