import React, { useContext} from 'react';
import { ThemeContext } from '../../Components/ThemeProvider';
import Avatar from '../../Components/Avatar';
import Button from '../../Components/Button';
import Table from '../../Components/Table';
import Row from '../../Components/Row';
import Cell from '../../Components/Cell';
import Chip from '../../Components/Chip';
import Pagination from '../../Components/Pagination';
import Select from '../../Components/Select';
import Option from '../../Components/Option';
import Input from '../../Components/Input';




function Products() {




    const theme = useContext(ThemeContext);




    return (
        <div className='container-fluid'>
            <div className='row justify-content-center' style={{ marginTop: "20px" }}>
                <div className='col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10' style={{ textAlign: "center", marginTop: "10px" }}>
                    <h1 style={{ textAlign: "start", fontSize: "26px", marginBottom: "10px", marginTop: "10px", color: theme["text"] }}>Products</h1>
                </div>
            </div>
            <div className='row justify-content-center' style={{ marginTop: "20px" }}>
                <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3' style={{ textAlign: "center", marginTop: "10px",display:"flex",alignItems:"center",justifyContent:"start" }}>
                    <Select color='secondary' label='Date' size={"md"}>
                        <Option> Today </Option>
                        <Option> This Week </Option>
                        <Option> Last 30 Days </Option>
                    </Select>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3' style={{ textAlign: "center", marginTop: "10px",display:"flex",alignItems:"center",justifyContent:"start" }}>
                    <Select color='secondary' label='Filter' size={"md"}>
                        <Option> Most Popular </Option>
                        <Option> Most Comments </Option>
                        <Option> Most Expensive </Option>
                        <Option> Less Expensive </Option>
                    </Select>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ display:"flex",alignItems:"center",justifyContent:"end" }}>
                    <Input color='secondary' size='md' label={"Search"} />
                </div>
            </div>
            <div className='row justify-content-center' style={{ marginTop: "20px" }}>

                <div className='col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10' style={{ textAlign: "center", marginTop: "10px" }}>
                    <div style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "10px", backgroundColor: theme["topMenu"] }}>

                        <Table style={{ color: theme["text"] }} color={"gray"}>
                            <Row>
                                <Cell header>Product</Cell>
                                <Cell header>Amount</Cell>
                                <Cell header>Stock</Cell>
                                <Cell header>Detail</Cell>
                            </Row>
                            <Row>
                                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p1.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                                <Cell ><Chip label={"600$"} color="success" /></Cell>
                                <Cell ><Chip label={"400"} color="info" /></Cell>
                                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
                            </Row>
                            <Row>
                                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p2.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                                <Cell ><Chip label={"600$"} color="success" /></Cell>
                                <Cell ><Chip label={"400"} color="info" /></Cell>
                                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
                            </Row>
                            <Row>
                                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p3.png" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                                <Cell ><Chip label={"600$"} color="success" /></Cell>
                                <Cell ><Chip label={"400"} color="info" /></Cell>
                                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
                            </Row>
                            <Row>
                                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p4.PNG" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                                <Cell ><Chip label={"600$"} color="success" /></Cell>
                                <Cell ><Chip label={"2"} color="alert" /></Cell>
                                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
                            </Row>
                            <Row>
                                <Cell style={{ display: "flex", alignItems: "center", justifyContent: "start" }}><Avatar color="secondary" active={false} shape='square' onClick={() => { alert('Avatar Clicked') }} src="p5.jpg" /> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Cell>
                                <Cell ><Chip label={"600$"} color="success" /></Cell>
                                <Cell ><Chip label={"400"} color="info" /></Cell>
                                <Cell ><Button type={'text'} size='sm' color={'secondary'}>Detail</Button></Cell>
                            </Row>
                        </Table>
                        <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <Pagination total={100} color={'primary'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
