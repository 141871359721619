/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************

color ---> primary || secondary || success || info || warning || alert || dark || darkgray || gray

maxValue ---> max value of the progress

onFinished ---> function

size ---> sm(default) || md || lg 

type ---> circular || linear 

value ---> 

*/

//TODO: propslar error handler eksik
import React, { useContext, useEffect, useState } from "react";
import { progressSize } from "../Theme";
import { ThemeContext } from "../ThemeProvider";
import { colorTypes, sizeTypes, maxValueTypes, onFinishedTypes, integerValueTypes, themeProviderCheck } from "../ErrorHandler";

function Progress({ color, maxValue, onFinished, size, type, value }) {
  const theme = useContext(ThemeContext);

  const [localColor, setLocalColor] = useState(theme["darkgray"])


  const [localSize,setLocalSize] = useState("sm")
  const [width, setWidth] = useState(progressSize[localSize].width)
  const [height, setHeight] = useState(progressSize[localSize].height)
  const [fontSize, setFontSize] = useState(progressSize[localSize].fontSize)


  const [localMaxValue, setLocalMaxValue] = useState(100)
  const [localValue, setLocalValue] = useState(0)

  const [localType,setLocalType] = useState("linear")

  const [factor, setFactor] = useState(0)

  const [percentage, setPercentage] = useState(Math.floor((100 * localValue) / localMaxValue))
  const [radius, setRadius] = useState((width - height) / 2)
  const [dasharr, setdasharr] = useState(radius * Math.PI * 2)
  const [dashoff, setDashoff] = useState(dasharr - ((dasharr * percentage) / 100))

  const [finished, setFinished] = useState(false)


  useEffect(() => {

    colorTypes.check(color)
    sizeTypes.check(size)
    onFinishedTypes.check(onFinished)
    maxValueTypes.check(maxValue)
    integerValueTypes.check(value)
    themeProviderCheck.check(theme)

  }, [color, size, onFinished, maxValue, value, theme])

  useEffect(() => {

    setDashoff(dasharr - ((dasharr * percentage) / 100))

    if (percentage === 100 && onFinished !== undefined && !finished) {

      onFinished()
      setFinished(true)
    }



  }, [percentage])


  useEffect(()=>{
    if(size!==undefined)
      setLocalSize(size)

    if(type!==undefined)
      setLocalType(type)

  },[size,type])

  useEffect(() => {

    
      setHeight(progressSize[localSize].height)
      setWidth(progressSize[localSize].width)
      setFontSize(progressSize[localSize].fontSize)
      setRadius((progressSize[localSize].width - progressSize[localSize].height) / 2)
      setdasharr((progressSize[localSize].width - progressSize[localSize].height) / 2 * Math.PI * 2)
      setDashoff(((progressSize[localSize].width - progressSize[localSize].height) / 2 * Math.PI * 2) - ((((progressSize[localSize].width - progressSize[localSize].height) / 2 * Math.PI * 2) * percentage) / 100))
   
  }, [localSize])



  useEffect(() => {

    if (color !== undefined) {
      setLocalColor(theme[color])
    }

  }, [theme,color])

  useEffect(() => {
    
    setFactor((localValue * width) / localMaxValue)
    setPercentage(Math.floor((100 * localValue) / localMaxValue))
  }, [localValue,localMaxValue])



  useEffect(()=>{

    if(value!==undefined)
      setLocalValue(value)
    
    if(maxValue!==undefined)
      setLocalMaxValue(maxValue)

  },[value,maxValue])


  return (
    <div>
      {
        localType === "circular" &&
        <div style={{ position: "relative", backgroundColor: theme["gray"], color: theme["dark"], boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", cursor: "pointer", width: width + "px", height: width + "px", borderRadius: width + "px" }}>
          <svg style={{ width: width + "px", height: width + "px", borderRadius: width + "px" }} viewBox={"0 0 " + width + " " + width}>

            <circle r={radius} cx={width / 2} cy={width / 2} fill={theme["gray"]} stroke={localColor} strokeWidth={height + "px"} transform={"rotate(-90 " + width / 2 + " " + width / 2 + ")"} style={{
              strokeDasharray: dasharr,
              strokeDashoffset: dashoff,
              transition: "0.4s"
            }} />

            <circle r={radius} cx={width / 2} cy={width / 2} fill={theme["light"]} ></circle>

          </svg>
          <span style={{ fontSize: fontSize + "px", transition: "0.2s", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "-50px", marginTop: "-25px", position: "absolute", width: "100px", height: "50px", top: "50%", left: "50%" }}>{percentage}%</span>

        </div>


      }
      {
        localType === "linear" &&
        <div style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", cursor: "pointer", fontSize: fontSize + "px", position: "relative", width: width + "px", height: height + "px", display: "flex", alignItems: "center", borderRadius: "30px", backgroundColor: theme["gray"], color: theme["light"] }}>

          <div style={{ transition: "0.4s", display: "flex", alignItems: "center", justifyContent: "end", paddingRight: percentage === 100 ? "3px" : "5px", width: factor + "px", height: height + "px", visibility: localValue === 0 ? "hidden" : "visible", borderRadius: "30px", backgroundColor: localColor }}>
            {
              percentage >= 50 &&
              <span style={{ paddingLeft: "5px", color: theme["light"] }}>{percentage} %</span>
            }
          </div>
          {
            percentage < 50 &&
            <span style={{ paddingLeft: "5px", color: theme["dark"] }}>{percentage} %</span>
          }

        </div>
      }
    </div>
  );

}

export default Progress;
