import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import CurrencyComponent from "../../Components/Currency";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Currency() {
  return (
    <Grid>
      <Header>
        Currency
      </Header>
      <Description>
      
      </Description>
      <Subheader>
        Text
      </Subheader>
      <Description>
      You can write what you want to show as text content.
      </Description>
      <Showroom>
        <div style={{display:"flex"}}>
            <CurrencyComponent text={"2,5"} unit={'€'} size={'sm'} increasing={true}></CurrencyComponent>
            <CurrencyComponent text={"28,11"} unit={'€'} size={'sm'} increasing={true}></CurrencyComponent>
        </div>
      </Showroom>
      <Coderoom>
        {
        "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Currency text={'2,5'} />\n" +
          "\t\t<Currency text={'28,11'} />\n" +
          "\t)\n" +
          "}"
        }
      </Coderoom>
      <Subheader>
        Unit
      </Subheader>
      <Description>
      You can write the currency you want to use in the 'unit'.
      </Description>
      <Showroom>
        <div style={{display:"flex"}}>
            <CurrencyComponent text={"2,5"} unit={'$'} size={'sm'} increasing={true}></CurrencyComponent>
            <CurrencyComponent text={"2,5"} unit={'€'} size={'sm'} increasing={true}></CurrencyComponent>
        </div>
      </Showroom>
      <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Currency text={'2,5'} unit={'$'}/>\n" +
          "\t\t<Currency text={'2,5'} unit={'€'}/>\n" +
          "\t)\n" +
          "}"
        }
      </Coderoom>
      <Subheader>
        Increasing
      </Subheader>
      <Description>
      It can take true or false values. 'true' shows an ascending (upward) arrow, 'false' shows a descending (downward) arrow.
      </Description>
      <Showroom>
        <div style={{display:"flex"}}>
            <CurrencyComponent text={"2,5"} unit={'€'} size={'sm'} increasing={true}></CurrencyComponent>
            <CurrencyComponent text={"2,5"} unit={'€'} size={'sm'} increasing={false}></CurrencyComponent>
        </div>
      </Showroom>
      <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Currency text={'2,5'} unit={'€'} increasing={true}/>\n" +
          "\t\t<Currency text={'2,5'} unit={'€'} increasing={false}/>\n" +
          "\t)\n" +
          "}"
        }
      </Coderoom>
      <Subheader>
        Size
      </Subheader>
      <Description>
      You can specify the size of the box. it can take "sm", "md" and "lg" values.
      </Description>
      <Showroom>
        <div style={{display:"flex"}}>
            <CurrencyComponent text={"2,5"} unit={'€'} size={'sm'} increasing={true}></CurrencyComponent>
            <CurrencyComponent text={"2,5"} unit={'€'} size={'md'} increasing={true}></CurrencyComponent>
            <CurrencyComponent text={"2,5"} unit={'€'} size={'lg'} increasing={true}></CurrencyComponent>
        </div>
        <div style={{display:"flex"}}>
            <CurrencyComponent text={"2,5"} unit={'€'} size={'sm'} increasing={false}></CurrencyComponent>
            <CurrencyComponent text={"2,5"} unit={'€'} size={'md'} increasing={false}></CurrencyComponent>
            <CurrencyComponent text={"2,5"} unit={'€'} size={'lg'} increasing={false}></CurrencyComponent>
        </div>
      </Showroom>
      <Coderoom>
        {"export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Currency text={'2,5'} unit={'€'} increasing={true} size={'sm'}/>\n" +
          "\t\t<Currency text={'2,5'} unit={'€'} increasing={true} size={'md'}/>\n" +
          "\t\t<Currency text={'2,5'} unit={'€'} increasing={true} size={'lg'}/>\n" +
          "\t\t<Currency text={'2,5'} unit={'€'} increasing={false} size={'sm'}/>\n" +
          "\t\t<Currency text={'2,5'} unit={'€'} increasing={false} size={'md'}/>\n" +
          "\t\t<Currency text={'2,5'} unit={'€'} increasing={false} size={'lg'}/>\n" +
          "\t)\n" +
          "}"}
      </Coderoom>
      <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "text",
                            type: "string", 
                            default: "undefined", 
                            description: "text of the currency component"
                          },
                          {
                            name: "unit",
                            type: "string", 
                            default: "undefined", 
                            description: "the currency you want to use" 
                          },
                          {
                            name: "increasing",
                            type: "true\nfalse", 
                            default: "false", 
                            description: "increase decrease indicator" 
                          },
                          {
                            name: "size",
                            type: "sm\nmd\nlg", 
                            default: "undefined", 
                            description: "size of the currency component" 
                          } 
                      ]}  
                        
                    />
                </div>
            </div>
    </Grid>

  );
}
export default Currency;
