import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import TableComponent from "../../Components/Table";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";
import Row from "../../Components/Row";
import Cell from "../../Components/Cell";
import Button from "../../Components/Button";

function Tables(){

    return(
        
        <Grid>
            <Header>
                Table
            </Header>
            <Description>
            You can easily create the tables you will use for your applications using the Table component.
            </Description>
            <Subheader>
                Children
            </Subheader>
            <Description>
            You can use Row component as Children in Table Component. Cell Component should be used as children in Row Component.
            </Description>
            <Showroom>
            <TableComponent color="primary" >
                <Row>
                    <Cell header>Company</Cell>
                    <Cell header>Contact</Cell>
                    <Cell header>Country</Cell>
                </Row>
                <Row>
                    <Cell>Alfreds Futterkiste</Cell>
                    <Cell>Maria Anders</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
                <Row>
                    <Cell>Centro comercial Moctezuma</Cell>
                    <Cell>Francisco Chang</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
            </TableComponent>
            </Showroom>
            <Coderoom>
            {
                 "export default function Example(){\n"+
                 "\treturn(\n"+
                 "\t\t<Table>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell header>Company</Cell>\n"+
                 "\t\t\t<Cell header>Contact</Cell>\n"+
                 "\t\t\t<Cell header>Country</Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell >Alfreds Futterkiste</Cell>\n"+
                 "\t\t\t<Cell >Maria Anders</Cell>\n"+
                 "\t\t\t<Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detay</Button></Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell >Centro comercial Moctezuma</Cell>\n"+
                 "\t\t\t<Cell >Francisco Chang</Cell>\n"+
                 "\t\t\t<Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detay</Button></Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t</Table>\n"+
                 "\t)\n"+
                 "}"
            }
            </Coderoom>
           
            <Subheader>
                Color
            </Subheader>
            <Description>
            You can add colors to your tables by using the values ​​in our color palette.
            </Description>
            <Showroom>
            <TableComponent color="primary">
                <Row>
                    <Cell header>Company</Cell>
                    <Cell header>Contact</Cell>
                    <Cell header>Country</Cell>
                </Row>
                <Row>
                    <Cell>Alfreds Futterkiste</Cell>
                    <Cell>Maria Anders</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
                <Row>
                    <Cell>Centro comercial Moctezuma</Cell>
                    <Cell>Francisco Chang</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
                <Row>
                    <Cell>Ernst Handel</Cell>
                    <Cell>Roland Mendel</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
                
            </TableComponent>  
            <br></br>
            <TableComponent color="alert">
                <Row>
                    <Cell header>Company</Cell>
                    <Cell header>Contact</Cell>
                    <Cell header>Country</Cell>
                </Row>
                <Row>
                    <Cell>Alfreds Futterkiste</Cell>
                    <Cell>Maria Anders</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
                <Row>
                    <Cell>Centro comercial Moctezuma</Cell>
                    <Cell>Francisco Chang</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
                <Row>
                    <Cell>Ernst Handel</Cell>
                    <Cell>Roland Mendel</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
                
            </TableComponent>
            <br></br>
            <TableComponent color="info">
                <Row>
                    <Cell header>Company</Cell>
                    <Cell header>Contact</Cell>
                    <Cell header>Country</Cell>
                </Row>
                <Row>
                    <Cell>Alfreds Futterkiste</Cell>
                    <Cell>Maria Anders</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
                <Row>
                    <Cell>Centro comercial Moctezuma</Cell>
                    <Cell>Francisco Chang</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
                <Row>
                    <Cell>Ernst Handel</Cell>
                    <Cell>Roland Mendel</Cell>
                    <Cell><Button type={"filled"} size="sm" color={"secondary"}>Detay</Button></Cell>
                </Row>
                
            </TableComponent>
            </Showroom>
            <Coderoom>
            {
                 "export default function Example(){\n"+
                 "\treturn(\n"+
                 "\t\t<Table color='primary'>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell header>Company</Cell>\n"+
                 "\t\t\t<Cell header>Contact</Cell>\n"+
                 "\t\t\t<Cell header>Country</Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell >Alfreds Futterkiste</Cell>\n"+
                 "\t\t\t<Cell >Maria Anders</Cell>\n"+
                 "\t\t\t<Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detay</Button></Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell >Centro comercial Moctezuma</Cell>\n"+
                 "\t\t\t<Cell >Francisco Chang</Cell>\n"+
                 "\t\t\t<Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detay</Button></Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t</Table>\n"+
                 "\t\t<Table color='alert'>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell header>Company</Cell>\n"+
                 "\t\t\t<Cell header>Contact</Cell>\n"+
                 "\t\t\t<Cell header>Country</Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell >Alfreds Futterkiste</Cell>\n"+
                 "\t\t\t<Cell >Maria Anders</Cell>\n"+
                 "\t\t\t<Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detay</Button></Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell >Centro comercial Moctezuma</Cell>\n"+
                 "\t\t\t<Cell >Francisco Chang</Cell>\n"+
                 "\t\t\t<Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detay</Button></Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t</Table>\n"+
                 "\t\t<Table color='info'>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell header>Company</Cell>\n"+
                 "\t\t\t<Cell header>Contact</Cell>\n"+
                 "\t\t\t<Cell header>Country</Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell >Alfreds Futterkiste</Cell>\n"+
                 "\t\t\t<Cell >Maria Anders</Cell>\n"+
                 "\t\t\t<Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detay</Button></Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t\t<Row>\n"+
                 "\t\t\t<Cell >Centro comercial Moctezuma</Cell>\n"+
                 "\t\t\t<Cell >Francisco Chang</Cell>\n"+
                 "\t\t\t<Cell ><Button type={'filled'} size='sm' color={'secondary'}>Detay</Button></Cell>\n"+
                 "\t\t\t</Row>\n"+
                 "\t\t</Table>\n"+
                 "\t)\n"+
                 "}"
            }
            </Coderoom>
            <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                            default: "", 
                            description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                          },
                          {
                            name: "children",
                            type: "Cell Component \n Row Component", 
                            default: "undefined", 
                            description: "You can create table rows using components." 
                          },
                          {
                            name: "style",
                            type: "atribute", 
                            default: "undefined", 
                            description: "You can use the features you want to give in style." 
                          }
                      ]}  
                        
                    />
                </div>
            </div>
            
            
        </Grid>
    )


}
export default Tables;