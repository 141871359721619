import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import ChipComponent from '../../Components/Chip';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Chip() {

  return(
    <Grid>
      <Header>
        Chip
      </Header>
      <Description>
        Chips are various tags that inform users.
      </Description>
      <Subheader>
        Label
      </Subheader>
      <Description>
        Allows you to annotate chips.
      </Description>
      <Showroom>
      <div style={{display:"flex",alignItems:"center"}}>
        <ChipComponent size="sm" label='New' color="secondary"></ChipComponent>
        <ChipComponent size="sm" label='Ticket' color="secondary"></ChipComponent>
        <ChipComponent size="sm" label='Avatar' color="secondary"></ChipComponent>
        <ChipComponent size="sm" label='Icon' color="secondary"></ChipComponent>
      </div>
      </Showroom>
      <Coderoom>
        {
          "export default function Example(){\n"+
          "\treturn(\n"+
          "\t\t<Chip label='New' />\n"+
          "\t\t<Chip label='Ticket' />\n"+
          "\t\t<Chip label='Avatar' />\n"+
          "\t\t<Chip label='Icon' />\n"+
          "\t)\n"+
          "}"
        }
      </Coderoom>
      <Subheader>
        Color
      </Subheader>
      <Description>
      You should use "color" props to apply the colors in your theme palette to the component.It can take "primary", "secondary", "success", "warning", "info", "alert", "dark", "light", "gray" and "darkgray" values.
      </Description>
      <Showroom>
      <div style={{display:"flex",alignItems:"center"}}>
        <ChipComponent size="sm" label='New' color="primary"></ChipComponent>
        <ChipComponent size="sm" label='New' color="secondary"></ChipComponent>
        <ChipComponent size="sm" label='New' color="alert"></ChipComponent>
        <ChipComponent size="sm" label='New' color="success"></ChipComponent>
      </div>
      </Showroom>
      <Coderoom>
        {
          "export default function Example(){\n"+
          "\treturn(\n"+
          "\t\t<Chip label='New' color='primary' />\n"+
          "\t\t<Chip label='New' color='secondary'  />\n"+
          "\t\t<Chip label='New' color='alert'  />\n"+
          "\t\t<Chip label='New' color='warning' />\n"+
          "\t)\n"+
          "}"
        }
      </Coderoom>
      <Subheader>
        Size
      </Subheader>
      <Description>
      You can use the 'size' prop to size the chips
      </Description>
      <Showroom>
      <div style={{display:"flex",alignItems:"center"}}>
        <ChipComponent size="sm" label='New' color="secondary"></ChipComponent>
        <ChipComponent size="md" label='New' color="secondary"></ChipComponent>
        <ChipComponent size="lg" label='New' color="secondary"></ChipComponent>
      </div>
      </Showroom>
      <Coderoom>
        {
          "export default function Example(){\n"+
          "\treturn(\n"+
          "\t\t<Chip size='sm' label='New' color='secondary' />\n"+
          "\t\t<Chip size='md' label='New' color='secondary' />\n"+
          "\t\t<Chip size='lg' label='New' color='secondary' />\n"+
          "\t)\n"+
          "}"
        }
      </Coderoom>
      <Subheader>Props</Subheader>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                          name: "color",
                          type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                          default: "undefined", 
                          description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                        } ,
                        {
                          name:"size",
                          type:"sm \n md \n lg",
                          default:"undefined",
                          description:"You can specify the size of the chips with the 'size' props"
                        },
                        {
                          name:"label",
                          type:"string",
                          default:"undefined",
                          description:"Allows you to annotate chips."
                        }
                      ]}  
                        
                    />
                </div>
      </div>
      

    </Grid>
  )



}
export default Chip;