/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************

color ---> primary || secondary || success || info || warning || alert || dark || darkgray || gray

children ---> 

style ---> It works like style attribute.



*/

import React,{ useEffect, useState } from "react";
import { useContext } from "react";
import {  hexToRgbA } from "../Theme";
import { ThemeContext } from "../ThemeProvider";
import { colorTypes, childrenTypes, styleTypes, themeProviderCheck} from "../ErrorHandler";


/** PROPS TYPES FOR USERS
 * ********************************************
 * @param {object} props                      *
 * @param {string} props.color                *
 * @param {object} props.style                *
 * @param {React.Component} props.children    *
 * ********************************************
 **/
//TODO: Page'de proplar eksik

function Alert({color, style, children}) {

  const [localColor, setLocalColor] = useState("#333")
  const theme = useContext(ThemeContext);


  useEffect(()=>{

    colorTypes.check(color)
    childrenTypes.check(children)
    styleTypes.check(style)
    themeProviderCheck.check(theme)
    
  },[color, children,style,theme])


  useEffect(() => {

    if (color !== undefined) {
      setLocalColor(theme[color])
    }
  }, [theme,color])


  const icons = {
    "alert":<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1H25C27.2091 1 29 2.79086 29 5V23.7476L30 22.7476V5C30 2.23858 27.7614 0 25 0H5C2.23858 0 0 2.23858 0 5V25C0 27.7614 2.23858 30 5 30H22.7476L23.7476 29H5C2.79086 29 1 27.2091 1 25V5C1 2.79086 2.79086 1 5 1ZM25.1651 28.9967L24.1618 30H25C27.7614 30 30 27.7614 30 25V24.1618L28.9967 25.1651C28.9123 27.2429 27.2429 28.9123 25.1651 28.9967Z" fill={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)}/>
    <line x1="8.93837" y1="21.6164" x2="21.1513" y2="7.0616" stroke={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)}stroke-linecap="round"/>
    <line x1="8.70442" y1="7.06163" x2="20.9174" y2="21.6165" stroke={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)} stroke-linecap="round"/>
    </svg>
    ,    
    "warning":<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1H25C27.2091 1 29 2.79086 29 5V23.7476L30 22.7476V5C30 2.23858 27.7614 0 25 0H5C2.23858 0 0 2.23858 0 5V25C0 27.7614 2.23858 30 5 30H22.7476L23.7476 29H5C2.79086 29 1 27.2091 1 25V5C1 2.79086 2.79086 1 5 1ZM25.1651 28.9967L24.1618 30H25C27.7614 30 30 27.7614 30 25V24.1618L28.9967 25.1651C28.9123 27.2429 27.2429 28.9123 25.1651 28.9967Z" fill={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)}/>
    <path d="M15 5V19.1176" stroke={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)} stroke-linecap="round"/>
    <circle cx="15" cy="24" r="1" fill={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)}/>
    </svg>,
    "info": <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1H25C27.2091 1 29 2.79086 29 5V23.7476L30 22.7476V5C30 2.23858 27.7614 0 25 0H5C2.23858 0 0 2.23858 0 5V25C0 27.7614 2.23858 30 5 30H22.7476L23.7476 29H5C2.79086 29 1 27.2091 1 25V5C1 2.79086 2.79086 1 5 1ZM25.1651 28.9967L24.1618 30H25C27.7614 30 30 27.7614 30 25V24.1618L28.9967 25.1651C28.9123 27.2429 27.2429 28.9123 25.1651 28.9967Z" fill={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)}/>
    <path d="M9 11.4103V6C9 5.44771 9.44772 5 10 5H20C20.5523 5 21 5.44772 21 6V14.2564C21 14.8087 20.5523 15.2564 20 15.2564H16C15.4477 15.2564 15 15.7041 15 16.2564V20.3846" stroke={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)} stroke-linecap="round"/>
    <path d="M16.1818 22.4359H14.3636C13.8114 22.4359 13.3636 22.8836 13.3636 23.4359V24C13.3636 24.5523 13.8114 25 14.3636 25H16.1818C16.7341 25 17.1818 24.5523 17.1818 24V23.4359C17.1818 22.8836 16.7341 22.4359 16.1818 22.4359Z" stroke={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)} stroke-linecap="round"/>
    </svg>,
    "success":<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1H25C27.2091 1 29 2.79086 29 5V23.7476L30 22.7476V5C30 2.23858 27.7614 0 25 0H5C2.23858 0 0 2.23858 0 5V25C0 27.7614 2.23858 30 5 30H22.7476L23.7476 29H5C2.79086 29 1 27.2091 1 25V5C1 2.79086 2.79086 1 5 1ZM25.1651 28.9967L24.1618 30H25C27.7614 30 30 27.7614 30 25V24.1618L28.9967 25.1651C28.9123 27.2429 27.2429 28.9123 25.1651 28.9967Z" fill={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)}/>
    <path d="M5 14.6364L10.9649 23L25 7" stroke={style === undefined ? localColor: (style.color!==undefined?style.color:localColor)} stroke-linecap="round"/>
    </svg>,
    "undefined" : "?"

  }




  return (
    <div style={{ backgroundColor: hexToRgbA(localColor),  borderRadius: '5px', width: '100%',padding:"10px", height: 'auto', color: localColor, ...style }}>
      <div style={{ display: 'flex', alignItems: "center", margin: '5px', height: "auto" }}>
        <span style={{ display: 'flex', alignItems: "center", justifyContent:"center", minHeight:"25px", minWidth:"25px", borderRadius:"30px",   marginRight:"5px" }}>
          {
            icons[color !== undefined ? color : "undefined"]
          }
        </span>

        <p style={{fontWeight:"500",margin:"0px"}}>{children}</p>
      </div>

    </div>
  )
}
export default Alert;