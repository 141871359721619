import React, { useContext } from "react";
import { ThemeContext } from "../../Components/ThemeProvider";

function Description(props) {

    const theme = useContext(ThemeContext);

    return (
        <p style={{color:theme["darkgray"], fontSize:"18px"}}>
            {props.children}
        </p>
    )
}
export default Description;