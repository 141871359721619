import React, { useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import CheckboxComponent from '../../Components/Checkbox';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Avatar from "../../Components/Avatar";
import Table from "../../Shared/Table";
function Checkbox() {

    const [checked, setChecked] = useState(true)

    return(
        
            <Grid>
            <Header>
                Checkbox
            </Header>
            <Description>
            The checkbox allows the user to select one or more items. Used to turn an option on or off.
            </Description>
            <Showroom>
                <CheckboxComponent></CheckboxComponent>
            </Showroom>
            
            <Subheader>
               Color
            </Subheader>
            <Description>
            You should use props to add the colors in the theme palette to the component.
            </Description>
            <Showroom >
                <div style={{display:"flex",alignItems:"center"}}>
                <CheckboxComponent color="primary"></CheckboxComponent>
                <CheckboxComponent color="secondary"></CheckboxComponent>
                <CheckboxComponent color="success"></CheckboxComponent>
                <CheckboxComponent color="warning"></CheckboxComponent>
                <CheckboxComponent color="info"></CheckboxComponent>
                <CheckboxComponent color="alert"></CheckboxComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Checkbox color='primary'/>\n"+
                    "\t\t<Checkbox color='secondary'/>\n"+
                    "\t\t<Checkbox color='success'/>\n"+
                    "\t\t<Checkbox color='warning'/>\n"+
                    "\t\t<Checkbox color='info'/>\n"+
                    "\t\t<Checkbox color='alert'/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
               Label
            </Subheader>
            <Description>
            You can annotate the checkbox using the label property.
            </Description>
            <Showroom >
                <div style={{display:"flex",alignItems:"center"}}>
                
                <CheckboxComponent color="secondary" label="Checkbox" ></CheckboxComponent>
                <CheckboxComponent color="secondary" label="Label" ></CheckboxComponent>
                
                </div>
            </Showroom>
            <Coderoom>
                {
                     "export default function Example(){\n"+
                     "\treturn(\n"+
                     "\t\t<Checkbox color='secondary' label='Checkbox'/>\n"+
                     "\t\t<Checkbox color='secondary' label='Label'/>\n"+
                     "\t)\n"+
                     "}"
                }

            </Coderoom>

            <Subheader>
               Checked
            </Subheader>
            <Description>
            When 'true' it adds a label inside the checkbox.False in default state.
            </Description>
            <Showroom >
                <div style={{display:"flex",alignItems:"center"}}>
                <CheckboxComponent color="secondary" label="Checkbox" checked={false}></CheckboxComponent>
                <CheckboxComponent color="secondary" label="Checkbox" checked={true}></CheckboxComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Checkbox color='secondary' label='Checkbox' checked={false}/>\n"+
                    "\t\t<Checkbox color='secondary' label='Checkbox' checked={true}/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
               Disabled
            </Subheader>
            <Description>
            If its value is "true", it turns off the click feature of the checkbox. False in default state.
            </Description>
            <Showroom >
                <div style={{display:"flex",alignItems:"center"}}>
                <CheckboxComponent color="secondary" disabled={false} label="Checkbox"></CheckboxComponent>
                <CheckboxComponent color="secondary" disabled={true} label="Disabled"></CheckboxComponent>
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n"+
                    "\treturn(\n"+
                    "\t\t<Checkbox color='secondary' disabled={false}/>\n"+
                    "\t\t<Checkbox color='secondary' disabled={true}/>\n"+
                    "\t)\n"+
                    "}"
                }
            </Coderoom>

            <Subheader>
               Position
            </Subheader>
            <Description>
            It determines in which direction the checkbox will be positioned on the value we write on the label.
            </Description>
            <Showroom >
                <div style={{display:"flex",alignItems:"center"}}>
                <CheckboxComponent color="secondary" label="Checkbox" position="RIGHT" checked={true}></CheckboxComponent>
                <CheckboxComponent color="secondary" label="Checkbox" position="TOP" checked={true}></CheckboxComponent>                
                <CheckboxComponent color="secondary" label="Checkbox" position="BOTTOM" checked={true}></CheckboxComponent>
                <CheckboxComponent color="secondary" label="Checkbox" position="LEFT" checked={true}></CheckboxComponent>
                
                </div>
            </Showroom>
            <Coderoom>
                {
                     "export default function Example(){\n"+
                     "\treturn(\n"+
                     "\t\t<Checkbox color='secondary' label='Checkbox' position='RIGHT' checked={true} />\n"+
                     "\t\t<Checkbox color='secondary' label='Checkbox' position='TOP' checked={true} />\n"+
                     "\t\t<Checkbox color='secondary' label='Checkbox' position='BOTTOM' checked={true} />\n"+
                     "\t\t<Checkbox color='secondary' label='Checkbox' position='LEFT' checked={true} />\n"+
                     "\t)\n"+
                     "}"
                }
            </Coderoom>

            <Subheader>
               onChange
            </Subheader>
            <Description>
                With the "onChange" option, you can specify how the function reacts after clicking the checkbox.
            </Description>
            <Showroom >
                <div style={{display:"flex",alignItems:"center"}}>
                <CheckboxComponent color="secondary" label={
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                        <Avatar shape="square" color="secondary" size="sm" src="avatar1.jpg" active={checked} />
                    </div>
                } position="RIGHT" checked={true} onChange={setChecked}></CheckboxComponent>
                </div>
            </Showroom>
            <Coderoom>
            {
                     "export default function Example(){\n"+
                     "\treturn(\n"+
                     "\t\t<Checkbox color='secondary'\n"+ 
                     "\t\tlabel=\n"+
                     "\t\t\t{\n"+ 
                     "\t\t\t\t<Avatar active={checked} />\n"+ 
                     "\t\t\t}\n"+ 
                     "\t\tposition='RIGHT' checked={true} onChange={setChecked}/>\n"+
                     "\t)\n"+
                     "}"
                }
            </Coderoom>
            <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[

                            {
                                name: "color",
                                type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                                default: "gray", 
                                description: "You can add colors from the theme palette to the checkbox." 
                            },
                            {
                                name: "checked",
                                type: "{true}\n{false}", 
                                default: "{false}", 
                                description: "When 'true' it adds a label inside the checkbox." 
                            },
                            {
                                name: "label",
                                type: "'string'", 
                                default: "undefined", 
                                description: "You can annotate the checkbox using the label property." 
                            },
                            {
                                name: "disabled",
                                type: "{true}\n{false}", 
                                default: "{false}", 
                                description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                            },
                            {
                                name: "position",
                                type: "TOP\nRIGHT\nLEFT\nBOTTOM", 
                                default: "LEFT", 
                                description: "It determines in which direction the checkbox will be positioned on the value we write on the label." 
                            },
                            {
                                name: "onChange",
                                type: "Function", 
                                default: "undefined", 
                                description: "With the 'onChange' option, you can specify how the function reacts after clicking the checkbox." 
                            }
                            
                      ]}  
                        
                    />
                </div>
            </div>
            </Grid>

        
    )
}

export default Checkbox;
