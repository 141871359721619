import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import ReportsComponent from "../../Components/Reports";
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";
function Reports() {

    return (
       <Grid>
        <Header>Reports</Header>
        <Description>

        </Description>
        <Showroom>
        <ReportsComponent color='secondary' header='Reports' onClick={() => { alert("clicked") }} />
        </Showroom>
        <Subheader>
            Header
        </Subheader>
        <Description>
        You can name the Reports Component using the "header" props.
        </Description>
        <Showroom>
        <ReportsComponent header='Reports' />
        </Showroom>
        <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Reports header='Reports' />\n" +
          "\t)\n" +
          "}"
        }
        </Coderoom>
        <Subheader>
            Color
        </Subheader>
        <Description>
          You can change the color using the Reports Component's color props.
        </Description>
        <Showroom>
        <ReportsComponent header='Reports' color='primary' />
        <ReportsComponent header='Reports' color='secondary' />
        <ReportsComponent header='Reports' color='success' />
        <ReportsComponent header='Reports' color='alert' />
        </Showroom>
        <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Reports header='Reports' color='primary'/>\n" +
          "\t\t<Reports header='Reports' color='secondary'/>\n" +
          "\t\t<Reports header='Reports' color='success'/>\n" +
          "\t\t<Reports header='Reports' color='alert'/>\n" +
          "\t)\n" +
          "}"
        }
        </Coderoom>
        <Subheader>
          onClick
        </Subheader>
        <Description>
          You can specify the action to be performed after the click by typing it into the onClick props.
        </Description>
        <Showroom>
        <ReportsComponent header='Reports' onClick={() => {alert('clicked')}} color='secondary'/>
        </Showroom>
        <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Reports header='Reports' color='secondary' onClick={() => {alert('clicked')}}/>\n" +
          "\t)\n" +
          "}"
        }
        </Coderoom>
        <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                          {
                            name: "header",
                            type: "string", 
                            default: "undefined", 
                            description: "You can add a title."
                          },
                          {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray", 
                            default: "dark", 
                            description: "You can change color." 
                          },
                          {
                            name: "onClick",
                            type: "function", 
                            default: "undefined", 
                            description: "You can specify what to do after the click." 
                          }
                      ]}  
                        
                    />
                </div>
            </div>
       </Grid>
    )
}
export default Reports;