/*
Copyright 2022 GTECH SOFTWARE SOLUTIONS

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), 
to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, 
and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, 
WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

AUTHOR: GTECH SOFTWARE SOLUTIONS
DATE: 04.04.2022

*************************************************************************[PROPS]*****************************************************************************

color ---> primary || secondary || success || info || warning || alert || dark || darkgray || gray

header ---> header of the reports

onClick ---> function


*/
import React,{ useContext, useEffect, useState } from "react";
import { hexToRgbA } from "../Theme";
import { ThemeContext } from "../ThemeProvider";
import { colorTypes, functionTypes, otherHeaderTypes, themeProviderCheck } from "../ErrorHandler";

function Reports({ header, color, onClick}) {

    const theme = useContext(ThemeContext);

    const [localColor, setLocalColor] = useState(theme["darkgray"])
    const [mouseIn, setMouseIn] = useState(false)


    useEffect(() => {

        colorTypes.check(color)
        functionTypes.check(onClick)
        otherHeaderTypes.check(header)
        themeProviderCheck.check(theme)
    
      }, [color, onClick, header, theme])

    useEffect(() => {

        if (color !== undefined) {
            setLocalColor(theme[color])
        }
        
    }, [theme,color])


    return (
        <div onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)} onClick={(e)=>{
            if(onClick !== undefined){
                e.preventDefault()
                onClick()
                e.stopPropagation()
            }
                
            }} style={{ boxShadow: mouseIn ? ("0px 0px 0px 3px " + hexToRgbA(localColor)) : "none", width: '150px', height: 'auto', cursor:"pointer", padding:"10px", margin: "10px", color: theme["light"], backgroundColor: localColor, borderRadius: '10px', display: "inline-block" }}>

            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.2234 17.7028C16.8695 17.9654 16.3854 17.9654 16.0315 17.7028L4.98402 9.50413C4.21024 8.92989 4.61639 7.7011 5.57996 7.7011H27.6749C28.6384 7.7011 29.0446 8.92988 28.2708 9.50413L17.2234 17.7028Z" fill={theme["light"] } />
                <rect x="4.12741" y="7.81429" width="25" height="19.1143" rx="0.5" stroke={theme["light"] }  />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6274 0H10.6274V10.9714H22.6274V0ZM12.6274 2.74286H19.6274V1.74286H12.6274V2.74286ZM20.6274 4.57143H12.6274V3.57143H20.6274V4.57143ZM12.6274 6.4H17.6274V5.4H12.6274V6.4Z" fill={theme["light"] }  />
            </svg>

            <p style={{ position: 'relative', margin: "0px", color:theme["light"] }}>{header}</p>

        </div>
    )
}
export default Reports;