import React from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import TooltipComponent from '../../Components/Tooltip';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Tooltip() {

    return (
        <Grid>
            <Header>
                Tooltip
            </Header>
            <Description>
                Tooltips show informative text when you focus or tap on an element. Adds a text tag that identifies an element when enabled.
            </Description>
            <Subheader>
                Children
            </Subheader>
            <Description>
                Lets you add children to tooltips.
            </Description>
            <Showroom>
                <TooltipComponent label="RIGHT" position="RIGHT">TOOLTIP</TooltipComponent>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n" +
                    "\treturn(\n" +
                    "\t\t<Tooltip> TOOLTIP </Tooltip>\n" +
                    "\t)\n" +
                    "}"
                }
            </Coderoom>
            <Subheader>
                Position
            </Subheader>
            <Description>
                You can decide in which direction you want to open the text label. It can take the values of TOP, RIGHT, LEFT, BOTTOM.
            </Description>
            <Showroom>
                <div style={{ margin: "150px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TooltipComponent position="TOP" color="secondary" label="TOP">
                        Tooltip TOP
                    </TooltipComponent>
                    <TooltipComponent position="RIGHT" color="secondary" label="RIGHT">
                        Tooltip RIGHT
                    </TooltipComponent>
                    <TooltipComponent position="LEFT" color="secondary" label="LEFT">
                        Tooltip LEFT
                    </TooltipComponent>
                    <TooltipComponent position="BOTTOM" color="secondary" label="BOTTOM">
                        Tooltip BOTTOM
                    </TooltipComponent>
                    <br />
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n" +
                    "\treturn(\n" +
                    "\t\t<Tooltip position='TOP'> Tooltip TOP </Tooltip>\n" +
                    "\t\t<Tooltip position='RIGHT'> Tooltip RIGHT </Tooltip>\n" +
                    "\t\t<Tooltip position='LEFT'> Tooltip LEFT </Tooltip>\n" +
                    "\t\t<Tooltip position='BOTTOM'> Tooltip BOTTOM </Tooltip>\n" +
                    "\t)\n" +
                    "}"
                }
            </Coderoom>

            <Subheader>
                Label
            </Subheader>
            <Description>
                Allows you to create the content of the text tag.
            </Description>
            <Showroom>
                <div style={{ margin: "150px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TooltipComponent label="TOP" position="TOP" color="primary">
                        Tooltip TOP
                    </TooltipComponent>
                    <TooltipComponent label="RIGHT" position="RIGHT" color="secondary">
                        Tooltip RIGHT
                    </TooltipComponent>
                    <TooltipComponent label="LEFT" position="LEFT" color="secondary">
                        Tooltip LEFT
                    </TooltipComponent>
                    <TooltipComponent label="BOTTOM" position="BOTTOM" color="secondary">
                        Tooltip BOTTOM
                    </TooltipComponent>

                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n" +
                    "\treturn(\n" +
                    "\t\t<Tooltip position='TOP' label='TOP'> Tooltip TOP </Tooltip>\n" +
                    "\t\t<Tooltip position='RIGHT' label='RIGHT'> Tooltip RIGHT </Tooltip>\n" +
                    "\t\t<Tooltip position='LEFT' label='LEFT'> Tooltip LEFT </Tooltip>\n" +
                    "\t\t<Tooltip position='BOTTOM' label='BOTTOM'> Tooltip BOTTOM </Tooltip>\n" +
                    "\t)\n" +
                    "}"
                }
            </Coderoom>
            <Subheader>
                Color
            </Subheader>
            <Description>
                You should use "color" props to apply the colors in your theme palette to the component.It can take "primary", "secondary", "success", "warning", "info", "alert", "dark", "light", "gray" and "darkgray" values.
            </Description>
            <Showroom>
                <div style={{ margin: "150px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TooltipComponent label="TOP" position="TOP" color="info">
                        Tooltip TOP
                    </TooltipComponent>
                    <TooltipComponent label="RIGHT" position="RIGHT" color="success">
                        Tooltip RIGHT
                    </TooltipComponent>
                    <TooltipComponent label="LEFT" position="LEFT" color="primary">
                        Tooltip LEFT
                    </TooltipComponent>
                    <TooltipComponent label="BOTTOM" position="BOTTOM" color="alert">
                        Tooltip BOTTOM
                    </TooltipComponent>
                    <br />
                </div>
            </Showroom>
            <Coderoom>
                {
                    "export default function Example(){\n" +
                    "\treturn(\n" +
                    "\t\t<Tooltip position='TOP' label='TOP' color='info'> Tooltip TOP </Tooltip>\n" +
                    "\t\t<Tooltip position='RIGHT' label='RIGHT' color='success'> Tooltip RIGHT </Tooltip>\n" +
                    "\t\t<Tooltip position='LEFT' label='LEFT' color='primary'> Tooltip LEFT </Tooltip>\n" +
                    "\t\t<Tooltip position='BOTTOM' label='BOTTOM'color='alert'> Tooltip BOTTOM </Tooltip>\n" +
                    "\t)\n" +
                    "}"
                }
            </Coderoom>
            <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ width: "100%" }}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                            {
                                name: "color",
                                type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n",
                                default: " ",
                                description: "You should use 'color' props to apply the colors in your theme palette to the component."
                            },
                            {
                                name: "position",
                                type: "TOP\nRIGHT\nLEFT\nBOTTOM",
                                default: " ",
                                description: "You can decide in which direction you want to open the text label."
                            },
                            {
                                name: "label",
                                type: "string",
                                default: " ",
                                description: "Allows you to create the content of the text tag"
                            },
                            {
                                name: "children",
                                type: " ",
                                default: " ",
                                description: "Tooltip is wrapper of the children."
                            }
                        ]}

                    />
                </div>
            </div>




        </Grid>
    );
}

export default Tooltip;
