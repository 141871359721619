import React, { useEffect, useState } from "react";
import { theme } from "../../Components/Theme";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
function Coderoom(props) {


    return (
        <div style={{ marginTop: "10px", zIndex: "0" }}>
            <SyntaxHighlighter language="javascript" style={{
                ...materialDark,
                "pre[class*=\"language-\"]": {
                    "textAlign": "left",
                    "whiteSpace": "pre",
                    "wordSpacing": "normal",
                    "wordBreak": "normal",
                    "wordWrap": "normal",
                    "color": "#eee",
                    "background": "#2f2f2f",
                    "fontFamily": "Roboto Mono, monospace",
                    "fontSize": "1em",
                    "lineHeight": "1.5em",
                    "MozTabSize": "4",
                    "OTabSize": "4",
                    "tabSize": "4",
                    "WebkitHyphens": "none",
                    "MozHyphens": "none",
                    "msHyphens": "none",
                    "hyphens": "none",
                    "overflow": "auto",
                    "margin": "0.5em 0",
                    "padding": "1.25em 1em"
                }
            }} >
                {
                    props.children
                }
            </SyntaxHighlighter>
        </div>
    )
}
export default Coderoom;