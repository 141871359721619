export var colorStatements = {
    warning:"Warning! Prop 'color' is not defined.",
    error:"'color' prop has types ---> primary | secondary | success | info | warning | alert | dark | darkgray | gray"
}

export var childrenStatements = {
    warning:"Warning! Props 'children' is not defined.",
}

export var sizeStatements = {
    warning:"Warning! Prop 'size' is not defined.",
    error:"'size' prop has types ---> sm | md | lg"
}

export var srcStatements = {
    warning:"Warning! Prop 'src' is not defined.",
    error:"'src' prop has types ---> *.jpg | *.JPG | *.png | *.PNG"
}

export var styleStatements = {
    warning:"Warning! Prop 'style' is not defined.",
    error:"'style' prop should be object"
}

export var shapeStatements = {
    warning:"Warning! Prop 'shape' is not defined.",
    error:"'shape' prop has types ---> square | circle"
}

export var activeStatements = {
    warning:"Warning! Prop 'active' is not defined.",
    error:"'active' prop has types ---> true | false"
}

export var functionStatements = {
    warning:"Warning! Prop 'onClick' is not defined.",
    error:"'onClick' should be function"
}

export var openStatements = {
    warning:"Warning! Prop 'open' is not defined.",
    error:"'open' prop has types ---> true | false"
}

export var disabledStatements = {
    warning:"Warning! Prop 'disabled' is not defined.",
    error:"'disabled' prop has types ---> true | false"
}

export var shadowStatements = {
    warning:"Warning! Prop 'shadow' is not defined.",
    error:"'shadow' prop has types ---> true | false"
}

export var contentStatements = {
    warning:"Warning! Props 'content' is not defined.",
}

export var checkboxTooltipPositionStatements = {
    warning:"Warning! Prop 'position' is not defined.",
    error:"'position' prop has types ---> TOP | RIGHT | LEFT | BOTTOM"
}

export var badgePositionStatements = {
    warning:"Warning! Prop 'position' is not defined.",
    error:"'position' prop has types ---> TOP_LEFT | TOP_RIGHT | BOTTOM_LEFT | BOTTOM_RIGHT"
}


export var drawerPositionStatements = {
    warning:"Warning! Prop 'position' is not defined.",
    error:"'position' prop has types ---> LEFT | RIGHT"
}

export var dateValueStatements = {
    warning:"Warning! Prop 'value' is not defined.",
    error:"'value' prop should be 'date' object"
}

export var stringValueStatements = {
    warning:"Warning! Prop 'value' is not defined.",
    error:"'value' prop should be string"
}

export var integerValueStatements = {
    warning:"Warning! Prop 'value' is not defined.",
    error:"'value' prop should be integer"
}

export var animationStatements = {
    warning:"Warning! Prop 'animation' is not defined.",
    error:"'animation' prop has types ---> true | false"
}

export var showContentStatements = {
    warning:"Warning! Prop 'showContent' is not defined.",
    error:"'showContent' prop has types ---> true | false"
}

export var buttonTypeStatements = {
    warning:"Warning! Prop 'type' is not defined.",
    error:"'type' prop has types ---> filled | lined | text"
}

export var inputTypeStatements = { 
    warning:"Warning! Prop 'type' is not defined.",
    error:"'type' prop has types ---> card | phone | iban | password | date"
}

export var spinnerTypeStatements = { 
    warning:"Warning! Prop 'type' is not defined.",
    error:"'type' prop has types --->   blink | bubble | chaos | spinner"
}

export var switchTypeStatements = { 
    warning:"Warning! Prop 'type' is not defined.",
    error:"'type' prop has types --->   basic | complex"
}

export var onChangeStatements = { 
    warning:"Warning! Prop 'onChange' is not defined.",
    error:"'onChange' should be function"
}

export var cardHeaderStatements = {
    warning:"Warning! Prop 'header' is not defined.",
}

export var otherHeaderStatements = {
    warning:"Warning! Prop 'header' is not defined.",
    error:"'header' prop should be string"
}

export var actionsStatements = {
    warning:"Warning! Prop 'actions' is not defined.",
}

export var placeHolderStatements = {
    warning:"Warning! Prop 'placeholder' is not defined.",
    error:"'placeholder' prop should be string"
}

export var textStatements = {
    warning:"Warning! Prop 'text' is not defined.",
    error:"'text' prop should be string"
}

export var onCloseStatements = { 
    warning:"Warning! Prop 'onClose' is not defined.",
    error:"'onClose' should be function"
}

export var heightStatements = { 
    warning:"Warning! Prop 'height' is not defined.",
    error:"'height' should be integer"
}

export var optionHeightStatements = { 
    warning:"Warning! Prop 'height' is not defined.",
    error:"'height' should be string"
}

export var itemsStatements = {
    warning:"Warning! Prop 'items' is not defined.",
}


export var checkedStatements = {
    warning:"Warning! Prop 'checked' is not defined.",
    error:"'checked' prop has types ---> true | false"
}

export var unitStatements = {
    warning:"Warning! Prop 'unit' is not defined.",
    error:"'unit' prop should be string"
}

export var widthStatements = {
    warning:"Warning! Prop 'width' is not defined.",
    error:"'width' prop should be integer"
}

export var totalStatements = {
    warning:"Warning! Prop 'total' is not defined.",
    error:"'total' prop should be integer"
}

export var pageStatements = {
    warning:"Warning! Prop 'page' is not defined.",
    error:"'page' prop should be integer and more than and equal to 1"
}

export var paginationTypesStatements = {
    warning:"Warning! Prop 'type' is not defined.",
    error:"'type' prop has types ---> filled | lined | text"
}

export var paginationPositionStatements = {
    warning:"Warning! Prop 'position' is not defined.",
    error:"'position' prop should be integer"
}

export var paginationSizeStatements = {
    warning:"Warning! Prop 'size' is not defined.",
    error:"'size' prop should be integer"
}

export var dataStatements = {
    warning:"Warning! Prop 'data' is not defined.",
    error:"'data' prop should be integer array"
}

export var directionStatements = {
    warning:"Warning! Prop 'direction' is not defined.",
    error:"'direction' prop has types normal | reverse"
}

export var chartLabelsStatements = { /****/
    warning:"Warning! Prop 'label' is not defined.",
    error:"'labels' prop should be string array"
}


export var labelStatements = {
    warning:"Warning! Prop 'label' is not defined.",
    error:"'label' prop should be string"
}

export var increasingStatements = {
    warning:"Warning! Prop 'increasing' is not defined.",
    error:"'increasing' prop has types ---> true | false"
}

export var increaseStatements = {
    warning:"Warning! Prop 'increase' is not defined.",
    error:"'increase' prop has types ---> true | false"
}

export var statistic2CurrencyStatements = {
    warning:"Warning! Prop 'currency' is not defined.",
    error:"'currency' should be string"
}

export var nameStatements = {
    warning:"Warning! Prop 'name' is not defined.",
    error:"'name' should be string"
}

export var subjectStatements = {
    warning:"Warning! Prop 'subject' is not defined.",
    error:"'subject' should be string"
}

export var logoStatements = {
    warning:"Warning! Prop 'logo' is not defined.",
}

export var detailStatements = {
    warning:"Warning! Prop 'detail' is not defined.",
    error:"'detail' should be string"
}

export var dateStatements = {
    warning:"Warning! Prop 'date' is not defined.",
    error:"'date' should be string"
}

export var maxValueStatements = {
    warning:"Warning! Prop 'maxValue' is not defined.",
    error:"'maxValue' prop should be integer"
}


export var onFinishedStatements = { 
    warning:"Warning! Prop 'onFinished' is not defined.",
    error:"'onFinished' should be function"
}


export var amountStatements = {
    warning:"Warning! Prop 'amount' is not defined.",
    error:"'amount' should be string"
}

export var currencyStatements = {
    warning:"Warning! Prop 'currency' is not defined.",
    error:"'currency' should be string"
}

export var rowspanStatements = {
    warning:"Warning! Prop 'rowspan' is not defined.",
    error:"'rowspan' should be integer"
}

export var colspanStatements = {
    warning:"Warning! Prop 'colspan' is not defined.",
    error:"'colspan' should be integer"
}

export var iconTypeStatements = {
    warning:"Warning! Prop 'type' is not defined.",
    error:"'type' should be string"
}
export var fileTypeStatements = {
    warning:"Warning! Prop 'type' is not defined.",
    error:"'type' should be string"
}

export var xsStatements = {
    warning: "Warning! Prop 'xs' is not defined.",
    error: "'xs' should be integer"
}

export var smStatements = {
    warning: "Warning! Prop 'sm' is not defined.",
    error: "'sm' should be integer"
}

export var mdStatements = {
    warning: "Warning! Prop 'md' is not defined.",
    error: "'md' should be integer"
} 
export var lgStatements = {
    warning: "Warning! Prop 'lg' is not defined.",
    error: "'lg' should be integer"
} 

export var xlStatements = {
    warning: "Warning! Prop 'xl' is not defined.",
    error: "'xl' should be integer"
} 

export var fontSizeStatements = {
    warning: "Warning! Prop 'fontSize' is not defined.",
    error: "'fontSize' should be integer"
} 

export var themeProviderStatements = {
    error:"You should wrap your application with ThemeProvider  component."
}

export var themeProviderWarningType = {
    warning:"Warning! Prop 'isWarningsOn' is not defined.",
    error:"'isWarningsOn' should be boolean"
}


export var themeProviderPalletsStatements = {
    warning:"Warning! Prop 'pallets' is not defined.",
    error:"'pallets' should be Array"
}


