import React, { useEffect, useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import AlertComponent from '../../Components/Alert'
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";

function Alert() {


  return (
    <Grid>
      <Header>
        Alert
      </Header>
      <Description>
        Gives alerts to get the user's attention. It can take the "color" prop. The alert type changes with the color. There are four main levels of importance.
      </Description>
      <Subheader>
        Error Alert
      </Subheader>
      <Description>
        It takes the value of "alert" as the color. Gives the user an error message.
      </Description>
      <Showroom>
        <AlertComponent color='alert'>This is an error alert.</AlertComponent>
      </Showroom>
      <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Alert color='alert'>This is an error alert.</Alert>\n" +
          "\t)\n" +
          "}"
        }
      </Coderoom>
      <Subheader>
        Warning Alert
      </Subheader>
      <Description>
        It takes the value of "warning" as the color. Gives a warning message to the user.
      </Description>
      <Showroom>
        <AlertComponent color='warning'>This is an warning alert.</AlertComponent>
      </Showroom>
      <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Alert color='warning'> This is warning alert. </Alert>\n" +
          "\t)\n" +
          "}"
        }
      </Coderoom>

      <Subheader>
        Info Alert
      </Subheader>
      <Description>
        It takes the value of "info" as the color. Gives an informative message to the user.
      </Description>
      <Showroom>
        <AlertComponent color='info'>This is an info alert.</AlertComponent>
      </Showroom>
      <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Alert color='info'> This is an info alert. </Alert>\n" +
          "\t)\n" +
          "}"
        }
      </Coderoom>

      <Subheader>
        Success Alert
      </Subheader>
      <Description>
        It takes the value of "success" as the color. Gives a successful action message.
      </Description>
      <Showroom>
        <AlertComponent color='success'>This is an success alert.</AlertComponent>
      </Showroom>
      <Coderoom>
        {
          "export default function Example(){\n" +
          "\treturn(\n" +
          "\t\t<Alert color='success'>This is an success alert</Alert>\n" +
          "\t)\n" +
          "}"
        }
      </Coderoom>

      <Subheader>Props</Subheader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom:"20px"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                          name: "color",
                          type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                          default: "undefined", 
                          description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                        },
                        {
                          name: "style",
                          type: "", 
                          default: "undefined", 
                          description: "It works like style attribute." 
                        },
                        {
                          name: "children",
                          type: "string", 
                          default: "undefined", 
                          description: "" 
                        } 
                      ]}  
                        
                    />
                </div>
            </div>



    </Grid>


  )
}
export default Alert;