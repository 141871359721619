import React, { useState } from "react";
import Description from "../../Shared/Description";
import Grid from "../../Shared/Grid";
import Header from "../../Shared/Header";
import Showroom from "../../Shared/Showroom";
import InputComponent from '../../Components/Input';
import Coderoom from "../../Shared/Coderoom";
import Subheader from "../../Shared/Subheader";
import Table from "../../Shared/Table";


function Input() {
    const [phone, setPhone] = useState("5495500509")
    const [date, setDate] = useState(new Date())

    return (
       <Grid>
        <Header>
            Input
        </Header>
        <Description>
        Input Component, let users enter and edit text. They often appear in forms and dialog boxes.
        </Description>
        <Subheader>
            Color
        </Subheader>
        <Description>
        You can use the color prop to change the color of the Input Component. It can take "primary", "secondary", "success", "warning", "info", "alert", "dark", "light", "gray" and "darkgray" values.
        </Description>
        <Showroom>
            <InputComponent color="primary"></InputComponent>
            <InputComponent color="success"></InputComponent>
            <InputComponent color="alert"></InputComponent>

        </Showroom>
        <Coderoom>
        {
            "export default function Example(){\n"+
            "\treturn(\n"+
            "\t\t<Input color='primary' /> \n"+
            "\t\t<Input color='success' />\n"+
            "\t\t<Input color='alert' />\n"+
            "\t)\n"+
            "}"
        }
        </Coderoom>
        
        <Subheader>
            Size
        </Subheader>
        <Description>
        You can specify the dimensions of the Input Component. It takes the values "sm", "md" and "lg".
        </Description>
        <Showroom>
            <InputComponent color="secondary" size="sm"></InputComponent>
            <InputComponent color="secondary" size="md"></InputComponent>
            <InputComponent color="secondary" size="lg"></InputComponent>
        </Showroom>
        <Coderoom>
        {
            "export default function Example(){\n"+
            "\treturn(\n"+
            "\t\t<Input color='secondary' size='sm' /> \n"+
            "\t\t<Input color='secondary' size='md' />\n"+
            "\t\t<Input color='secondary' size='lg' />\n"+
            "\t)\n"+
            "}"
        }
        </Coderoom>
        <Subheader>
            Label
        </Subheader>
        <Description>
        You can give headers describing the content of the Input Component.
        </Description>
        <Showroom>
            <InputComponent color="secondary" size="sm" label="User Name"></InputComponent>
            <InputComponent color="secondary" size="sm" label="Phone Number"></InputComponent>
            <InputComponent color="secondary" size="sm" label="Password"></InputComponent>
        </Showroom>
        <Coderoom>
        {
            "export default function Example(){\n"+
            "\treturn(\n"+
            "\t\t<Input color='secondary' size='sm' label='User Name'/> \n"+
            "\t\t<Input color='secondary' size='sm' label='Phone Number'/>\n"+
            "\t\t<Input color='secondary' size='sm' label='Password'/>\n"+
            "\t)\n"+
            "}"
        }
        </Coderoom>
        <Subheader>
            Type
        </Subheader>
        <Description>
        You can simplify your work by using certain types in your text fields.Can take "card", "phone", "iban", "password" and "date". The default is text.
        </Description>
        <Showroom>
        
                <InputComponent type="card" size="md" color="secondary" onChange={(value) => console.log(value, "**")} label="Credit Card" />
                <InputComponent type="phone" value={phone} size="md" color="secondary" onChange={(value) => setPhone(value)} label="Phone Number" />
                <InputComponent type="password" label="Password" size="md" color="secondary" onChange={(value) => console.log(value, "**")} />
                <InputComponent type="iban" prefix="TR" label="IBAN Numarası" value={"TR15555555555555555555555555"} size="md" color="secondary" onChange={(value) => console.log(value, "**")} />
                <InputComponent type="text" label="User Name" size="md" color="secondary" onChange={(value) => console.log(value)}/>
                <InputComponent type="date" label="Date of Birth" size="md" color="secondary" value={date} onChange={setDate} />

        </Showroom>
        <Coderoom>
        {
            "export default function Example(){\n"+
            "\treturn(\n"+
            "\t\t<Input color='secondary' size='md' type='card' /> \n"+
            "\t\t<Input color='secondary' size='md' type='phone' />\n"+
            "\t\t<Input color='secondary' size='md' type='password' />\n"+
            "\t\t<Input color='secondary' size='md' type='iban' />\n"+
            "\t\t<Input color='secondary' size='md' type='text' />\n"+
            "\t\t<Input color='secondary' size='md' type='date' />\n"+
            "\t)\n"+
            "}"
        }
        </Coderoom>
        <Subheader>
            Prefix
        </Subheader>
        <Description>
        You can use iban types of countries such as TR-EN-UK
        </Description>
        <Showroom>
        <InputComponent type="iban" prefix="TR" label="IBAN Numarası" value={""} size="lg" color="secondary" onChange={(value) => console.log(value, "**")} />
        <InputComponent type="iban" prefix="UK" label="IBAN Numarası" value={""} size="lg" color="secondary" onChange={(value) => console.log(value, "**")} />
        <InputComponent type="iban" prefix="EN" label="IBAN Numarası" value={""} size="lg" color="secondary" onChange={(value) => console.log(value, "**")} />
        </Showroom>
        <Coderoom>
        {
            "export default function Example(){\n"+
            "\treturn(\n"+
            "\t\t<Input color='secondary' size='lg' type='iban' prefix='TR' /> \n"+
            "\t\t<Input color='secondary' size='lg' type='iban' prefix='UK' />\n"+
            "\t\t<Input color='secondary' size='lg' type='iban' prefix='EN' />\n"+
            "\t)\n"+
            "}"
        }
        </Coderoom>
        <Subheader>
            onChange
        </Subheader>
        <Description>
        Returns selected value.
        </Description>
        <Subheader>
            Value
        </Subheader>
        <Description>
        Refers to the value entered depending on the type of input.
        </Description>
        <Showroom>
            <InputComponent type="text" color="secondary" size="sm" onChange={(value) => console.log(value, "**")}></InputComponent>
        </Showroom>
        <Coderoom>
        {
            "export default function Example(){\n"+
            "\treturn(\n"+
            "\t\t<Input color='secondary' size='sm' type='text' onChange={(value) => console.log(value, '**')}/> \n"+
            "\t)\n"+
            "}"
        }
        </Coderoom>
        <Subheader>
            Disabled
        </Subheader>
        <Description>
        When disabled is "true", it overrides clicking on the input field.
        </Description>
        <Showroom>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <InputComponent type="text" label="User Name" size="sm" color="secondary" onChange={(value) => console.log(value, "**")} disabled={false} />
            <InputComponent type="text" label="User Name" size="sm" color="secondary" onChange={(value) => console.log(value, "**")} disabled={true} />
        </div>
        </Showroom>
        <Coderoom>
        {
            "export default function Example(){\n"+
            "\treturn(\n"+
            "\t\t<Input color='secondary' size='sm' type='text' onChange={(value) => console.log(value, '**')} disabled={false}/> \n"+
            "\t\t<Input color='secondary' size='sm' type='text' onChange={(value) => console.log(value, '**')} disabled={true}/> \n"+
            "\t)\n"+
            "}"
        }
        </Coderoom>
        <Subheader>Props</Subheader>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ width:"100%"}}>
                    <Table
                        columns={["NAME", "TYPE", "DEFAULT", "DESCRIPTION"]}
                        rows=
                        {[
                        {
                            name: "color",
                            type: "primary\nsecondary\ninfo\nwarning\nsuccess\nalert\ndark\ngray\ndarkgray\n", 
                            default: "gray", 
                            description: "You should use 'color' props to apply the colors in your theme palette to the component." 
                        },
                        {
                            name:"size",
                            type:"sm\nmd\nlg",
                            default:"sm",
                            description:"You can specify the size of the input with the 'size' props "
                        },
                        {
                            name:"disabled",
                            type:"true\nfalse",
                            default:"false",
                            description:"If disabled is true, it disables the click action."
                        },
                        {
                            name:"value",
                            type:" ",
                            default:"",
                            description:"Refers to the value entered depending on the type of input. "
                        },
                        {
                            name:"label",
                            type:"string",
                            default:"undefined",
                            description:" You can give headers describing the content of the Input Component. "
                        },
                        {
                            name:"type",
                            type:"card\nphone\niban\npassword\ndate",
                            default:"text",
                            description:"  You can simplify your work by using certain types in your text fields."
                        },
                        {
                            name:"prefix",
                            type:"string",
                            default:"undefined",
                            description:"You can use iban types of countries such as TR-EN-UK "
                        },
                        {
                            name:"onChange",
                            type:"Function",
                            default:"undefined",
                            description:" Returns selected value."
                        }
                      ]}  
                        
                    />
                </div>
            </div>

       </Grid>
    );
}

export default Input;
